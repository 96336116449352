/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { Children, cloneElement } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserId, getUserRole } from 'src/_store/selectors';
import { UserRole } from 'src/constants/user';
import { Table } from 'semantic-ui-react';
import { IncoType } from 'src/constants/contract';
import { useToast } from 'src/components/Toast';
import { useTranslation } from 'react-i18next';
import { ROUTES } from 'src/constants/routes';
import { OrderStatus } from 'src/constants/orderStatus';
import { useMyRowsActivity } from './useMyRowsActivity';
import { useUpdateEvery } from 'src/_helpers/useUpdateEvery';
import { useUpdateAtTime } from 'src/_helpers/useUpdateAtTime';
import { getDistanceInSeconds } from 'src/_helpers/date';
import { useUnseenActivitiesContext } from 'src/containers/Dashboard/Footer/MyTabsContext';
import { DrawerContextKeys, useDrawerContext } from '../Drawer/DrawerContext';
import { useEditOrdersContext } from 'src/containers/Dashboard/DashboardGrid/EditOrders/EditOrdersProvider';
import { useMultipleOrderActions } from 'src/containers/Dashboard/MultipleOrderActions/MultipleOrderActions';
import { useHighlightItemContext } from 'src/containers/HighlightItemProvider/HighlightItemProvider';
import { UpdatePaperOrderTimeThreshold } from 'src/constants/orderbook';
import * as Styled from './styled';

const mapChildrenWithLinkTo = (children, linkTo) => {
	return Children.map(children, child =>
		cloneElement(child, {
			item: {
				...child.props.item,
				linkTo,
			},
		})
	);
};

export const MyNegotiationsRow = ({ item: order, children, ...props }) => {
	const { shouldHighlight, handleClick } = useMyRowsActivity(order);
	const { decreaseNegotiationsCount } = useUnseenActivitiesContext();
	const hasJustExpired = useUpdateAtTime(order.validity);

	const isRowActive = order.status === OrderStatus.Active;
	const isRowExpired = moment(new Date()).isAfter(new Date(order.validity), 'milliseconds');

	const contextData = useDrawerContext();
	const {
		setCurrentOrderInfo,
		visibility: [, { open: openDrawer }],
		currentOrderInfo,
	} = contextData[DrawerContextKeys.viewDrawer];

	const isOrderOpen = currentOrderInfo ? currentOrderInfo?.orderId === order.order_id : false;

	const onMouseDown = mouseEvent => {
		if (shouldHighlight) {
			decreaseNegotiationsCount();
		}
		handleClick(mouseEvent);

		setCurrentOrderInfo({
			orderId: order.order_id,
			negotiationId: order.first_counter_id,
			environment: order.environment,
		});

		openDrawer();
	};

	return (
		<Styled.MyActivitiesRow
			{...props}
			className={clsx(
				props.className,
				isOrderOpen && 'highlight-row',
				'clickable-row',
				(!isRowActive || isRowExpired) && 'inactive-row'
			)}
			$highlight={shouldHighlight && !hasJustExpired}
			onMouseDown={onMouseDown}
		>
			{children}
		</Styled.MyActivitiesRow>
	);
};

export const OrderRow = ({ item: order, children, ...props }) => {
	const contextData = useDrawerContext();

	const {
		setCurrentOrderInfo,
		visibility: [, { open: openDrawer }],
		currentOrderInfo,
	} = contextData[DrawerContextKeys.viewDrawer];
	const isOrderOpen = currentOrderInfo ? currentOrderInfo?.orderId === order?._key : false;

	const handleRowClick = () => {
		setCurrentOrderInfo({
			orderId: order._key,
			environment: order.environment,
		});
		openDrawer();
	};

	return (
		<Table.Row
			{...props}
			onClick={handleRowClick}
			className={clsx(props.className, isOrderOpen && 'highlight-row', 'clickable-row')}
		>
			{children}
		</Table.Row>
	);
};

export const PaperChildOrderRow = ({ className, ...props }) => {
	const order = props.item;

	const lastUpdatedDistance = getDistanceInSeconds({
		startDate: new Date(order.version_created_at),
		endDate: new Date(),
	});

	const secondsToUpdate = 10;
	useUpdateEvery(secondsToUpdate * 1000);

	const isRecentlyUpdated = lastUpdatedDistance < UpdatePaperOrderTimeThreshold;

	return (
		<OrderRow
			className={clsx(className, { 'recently-updated-row': isRecentlyUpdated })}
			{...props}
		/>
	);
};

export const MyOrderRow = ({ item: order, children, ...props }) => {
	const contextData = useDrawerContext();
	const { isSelected } = useMultipleOrderActions();

	useUpdateEvery(10 * 1000);

	const userRole = useSelector(getUserRole);
	const isBroker = userRole === UserRole.Broker;

	const { highlightedItemId } = useHighlightItemContext();

	const { isEditMode, getChanges, justEditedOrderIds } = useEditOrdersContext();

	const {
		setCurrentOrderInfo,
		visibility: [, { open: openDrawer }],
		currentOrderInfo,
	} = contextData[DrawerContextKeys.viewDrawer];
	const isOrderOpen = currentOrderInfo ? currentOrderInfo?.orderId === order?._key : false;

	const handleRowClick = event => {
		const targetTag = event.target.tagName.toLowerCase();

		if (['button', 'svg'].includes(targetTag)) {
			return;
		}

		setCurrentOrderInfo({
			orderId: order._key,
			environment: order.environment,
			market: order.market,
		});

		openDrawer();
	};

	const isRowChecked = isSelected(order._key);
	const isRowHighlighted = highlightedItemId === order._key;
	const isRowActive = order.status === OrderStatus.Active;
	const isRowExpired = moment(new Date()).isAfter(new Date(order.validity), 'milliseconds');

	const hasChangesInRow = !!getChanges(order._key);

	const isJustEdited = justEditedOrderIds.includes(order._key);

	return (
		<Styled.MyActivitiesRow
			{...props}
			onMouseDown={isEditMode ? undefined : handleRowClick}
			className={clsx(
				(isOrderOpen || isRowChecked || isRowHighlighted) && 'highlight-row',
				isEditMode && 'edit-mode-row',
				(!isRowActive || isRowExpired) && 'inactive-row',
				hasChangesInRow && 'row-changed',
				isJustEdited && 'just-edited',
				isBroker && 'broker-row',
				'clickable-row'
			)}
		>
			{children}
		</Styled.MyActivitiesRow>
	);
};

export const TradeRow = ({ item: trade, children, ...props }) => {
	const userId = useSelector(getUserId);

	const { addToast } = useToast();
	const { t } = useTranslation();
	const isPKPGTrade = !!trade?.runs;
	const hasNewActivities = !!trade?.has_new_activities;

	const linkTo = `${`${ROUTES.execution}/trade`}/${trade._key}`;

	const canUserSeeTradeDetails =
		trade.seller_user_ids.includes(userId) || trade.buyer_user_ids.includes(userId);

	const isUnavailableTrade =
		isPKPGTrade || trade.inco_id === IncoType.RENDU || !canUserSeeTradeDetails;
	const childrenList = mapChildrenWithLinkTo(children, isUnavailableTrade ? undefined : linkTo);

	return (
		<Table.Row
			{...props}
			className={clsx(hasNewActivities && !isUnavailableTrade && 'highlighted')}
			onClick={() => {
				// TODO: Remove when RENDU inco post-trade process will be done
				if (trade.inco_id === IncoType.RENDU) {
					addToast({
						message: t('rendu_no_post_trade_available'),
						kind: 'warning',
					});

					return;
				}

				// TODO: Remove when PKPG post-trade process will be done
				if (isPKPGTrade) {
					addToast({
						message: t('pkpb_no_post_trade_available'),
						kind: 'warning',
					});

					return;
				}
			}}
		>
			{childrenList}
		</Table.Row>
	);
};

export const MyTradeRow = ({ item: trade, children, ...props }) => {
	const { shouldHighlight, handleClick } = useMyRowsActivity(trade);
	const { decreaseTradesCount } = useUnseenActivitiesContext();

	const contextData = useDrawerContext();
	const {
		setCurrentOrderInfo,
		visibility: [, { open: openDrawer }],
		currentOrderInfo,
	} = contextData[DrawerContextKeys.viewDrawer];

	const isOrderOpen = currentOrderInfo ? currentOrderInfo?.orderId === trade.order_id : false;

	const onMouseDown = mouseEvent => {
		if (shouldHighlight) {
			decreaseTradesCount();
		}
		handleClick(mouseEvent);

		setCurrentOrderInfo({
			orderId: trade.order_id,
			negotiationId: trade.first_counter_id,
			environment: trade.environment,
		});

		openDrawer();
	};

	return (
		<Styled.MyActivitiesRow
			$highlight={shouldHighlight}
			onMouseDown={onMouseDown}
			className={clsx(props.className, isOrderOpen && 'highlight-row', 'clickable-row')}
			{...props}
		>
			{children}
		</Styled.MyActivitiesRow>
	);
};

export const KYCActiveConversationRow = ({ item, children, ...props }) => {
	const childrenList = mapChildrenWithLinkTo(
		children,
		`${ROUTES.kyc}/${ROUTES.kycActiveConversationsList}/${item.conversationId}`
	);

	return <Styled.ClickableRow {...props}>{childrenList}</Styled.ClickableRow>;
};
