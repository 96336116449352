/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSelector } from 'react-redux';
import { Avatar, AvatarType, BorderSize } from 'src/shared/components/Avatar/Avatar';
import { isInactiveUser } from 'src/_helpers/isInactiveUser';
import { UserProfileLink } from 'src/components/UserProfileModal/UserProfileLink';
import { UserStatus } from 'src/components/UserStatus/UserStatus';
import { OverflowText } from '../Tooltip/OverflowText';
import { useTranslation } from 'react-i18next';
import { Tooltip, TooltipTrigger, TooltipContent } from '../Tooltip/Tooltip';
import { Colors } from 'src/constants/colors';
import { getUserId } from 'src/_store/selectors';
import * as Styled from './styled';

export const PrincipalCompanyAvatarSection = ({
	principal,
	broker,
	isTraded,
	size = AvatarType.Large,
	withApprovedContactBadge = false,
}) => {
	const { t } = useTranslation();
	const currentUserID = useSelector(getUserId);

	const isCurrentUserPrincipal = principal.user?._key === currentUserID;
	const isCurrentUserBroker = broker?.user._key === currentUserID;

	const shouldShowHiddenLabel =
		principal.hidden && !isCurrentUserPrincipal && !isCurrentUserBroker && !isTraded;

	const shouldShowNotProvidedTooltip = !isCurrentUserPrincipal && !isCurrentUserBroker;

	const shouldEncloseHiddenLabelForTradedCounter = principal.hidden && !isTraded;

	if (shouldShowHiddenLabel) {
		return (
			<CompanyAvatarContent
				initials="H"
				size={size}
				firstLineComponent={
					<>
						<Styled.Name data-test="order-creator-company-name">
							{t('hidden')}
						</Styled.Name>
						<Tooltip>
							<TooltipTrigger>
								<Styled.InfoIcon data-test="info-icon" />
							</TooltipTrigger>
							<TooltipContent>{t('principal_details_hidden')}</TooltipContent>
						</Tooltip>
					</>
				}
				secondLineComponent={<label>{broker ? t('principal') : '---'}</label>}
				withApprovedContactBadge={withApprovedContactBadge}
			/>
		);
	}

	if (!principal.company) {
		return (
			<CompanyAvatarContent
				initials="-"
				size={size}
				firstLineComponent={
					<>
						<Styled.Name data-test="order-creator-company-name">
							{t('not_provided')}
						</Styled.Name>
						{shouldShowNotProvidedTooltip && (
							<Tooltip>
								<TooltipTrigger>
									<Styled.InfoIcon data-test="info-icon" />
								</TooltipTrigger>
								<TooltipContent>
									{t('principal_details_not_provided')}
								</TooltipContent>
							</Tooltip>
						)}
					</>
				}
				secondLineComponent={<label>{t('principal')}</label>}
				withApprovedContactBadge={withApprovedContactBadge}
			/>
		);
	}

	const userId = principal.user._key;
	const companyName = principal.company.name;
	const companyInitials = companyName.charAt(0);
	const borderAvatarColor = principal.company.avatar_color;

	return (
		<UserProfileLink
			userId={userId}
			isInactive={isInactiveUser({ status: principal.status })}
			hasHoverEffect={false}
		>
			<CompanyAvatarContent
				initials={companyInitials}
				size={size}
				borderColor={borderAvatarColor}
				withApprovedContactBadge={withApprovedContactBadge}
				firstLineComponent={
					<>
						<OverflowText>
							<Styled.Name data-test="order-creator-company-name">
								{companyName}
							</Styled.Name>
							<UserStatus status={principal.status} />
						</OverflowText>
						{shouldEncloseHiddenLabelForTradedCounter && (
							<Styled.Name $noTopPadding>{` • ${t('hidden')}`}</Styled.Name>
						)}
					</>
				}
				secondLineComponent={
					<Styled.PrincipalUser $fullWidth={!broker}>
						{broker && (
							<>
								<div>
									<label>{t('principal')}</label>
								</div>
								<div className="separator">•</div>
							</>
						)}
						<div className="content">
							<OverflowText>{principal.user.name}</OverflowText>
						</div>
					</Styled.PrincipalUser>
				}
			/>
		</UserProfileLink>
	);
};

const CompanyAvatarContent = ({
	backgroundColor = Colors.Neutral850,
	borderColor = Colors.Neutral400,
	size,
	initials = '-',
	firstLineComponent,
	secondLineComponent,
	withApprovedContactBadge,
}) => {
	return (
		<Styled.UserDetailsContainer data-test="user-company-details">
			<Avatar
				initials={initials}
				size={size}
				backgroundColor={backgroundColor}
				borderColor={borderColor}
				data-test="order-creator-company-avatar"
				className="order-creator-company-avatar"
				borderSize={BorderSize.large}
				withApprovedContactBadge={withApprovedContactBadge}
			/>
			<Styled.UserDetails className="user-details">
				<Styled.CompanyContainer
					className="user-company-container"
					data-test="user-company-container"
				>
					{firstLineComponent}
				</Styled.CompanyContainer>
				<Styled.UserInfo data-test="order-creator-company-info">
					{secondLineComponent}
				</Styled.UserInfo>
			</Styled.UserDetails>
		</Styled.UserDetailsContainer>
	);
};
