/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { useCurrentUser } from 'src/_helpers/useCurrentUser';
import { OrderType, Role } from 'src/constants/contract';

// Order
// user, user_id, user_company -> order creator (principal) or broker if it's a broker order
// order_owner_principal -> principal set by order owner in broker order or order creator if it's a principal order

// Counter
// counter_order_type -> sell|buy type for a given counter
// order_user_id -> order owner user id - id of user who created an order (constant)
// counter_order_user_id -> returns counterparty always. it's ID of user who initiatied negotiation (constant)
// user_id -> who created given counter

export const useBrokerPrincipal = order => {
	const { isBrokerOrder, orderOwnerPrincipal, user } = useOrderDetails(order);
	const isBrokerCounter = order.counter_order_role === Role.Broker;
	const counteringUser = useCurrentUser();

	const isOrder = order.is_order === true;
	const isOrderPreview = order.is_order_preview;
	const isCounter = order.is_order === false;
	const isCounterPreview = order.is_counter_preview;
	const isTraded = !!order.trade_id;

	let principal = {};
	let broker = null;

	// order and order preview
	if (isBrokerOrder && (isOrder || isOrderPreview)) {
		broker = user ? { user, company: { ...user.company } } : null;

		if (orderOwnerPrincipal) {
			principal = {
				...orderOwnerPrincipal,
			};
		}
	} else {
		principal = {
			...orderOwnerPrincipal,
		};
	}

	// counter preview
	if (isCounterPreview) {
		principal = {
			...counteringUser,
		};

		if (isBrokerOrder) {
			if (order.is_my_order) {
				if (order.order_owner_principal) {
					principal = {
						...order.order_owner_principal,
					};

					broker = {
						...counteringUser,
					};
				} else {
					broker = {
						...counteringUser,
					};

					principal = {};
				}
			}
		}
	}

	// counter view
	if (isCounter && !isTraded) {
		const counterCreatorUser = {
			user: { ...order.user },
			company: { ...order.company },
		};

		// was given counter created by owner
		if (order.user_id === order.order_user_id) {
			principal = { ...order.order_owner_principal };

			if (isBrokerOrder) {
				broker = { ...counterCreatorUser };
			}
		} else {
			principal = { ...counterCreatorUser };
		}
	}

	let tradedCounterCounterpartySide;

	if (isTraded) {
		if (order.counter_order_user_id !== counteringUser.user._key) {
			// view as owner
			principal = {
				user: { ...order.counter_order_owner_principal.user },
				company: { ...order.counter_order_owner_principal.company },
			};

			broker = isBrokerCounter
				? {
						user: { ...order.counter_order_user },
						company: { ...order.counter_order_user_company },
				  }
				: null;
		} else {
			// view as counterparty
			principal = {
				...orderOwnerPrincipal,
			};

			broker = isBrokerOrder
				? {
						user: { ...order.order_user },
						company: { ...order.order_user_company },
				  }
				: null;
		}

		// check whether displayed broker or principal is a counterparty
		const isDisplayedPrincipalOrBrokerACounterparty =
			principal.user?._key === order.counter_order_user_id ||
			broker?.user?._key === order.counter_order_user_id;

		const orderType = order.order_type;

		if (isDisplayedPrincipalOrBrokerACounterparty) {
			if (orderType === OrderType.Sell) {
				tradedCounterCounterpartySide = OrderType.Buy;
			} else {
				tradedCounterCounterpartySide = OrderType.Sell;
			}
		} else {
			tradedCounterCounterpartySide = orderType;
		}
	}

	return {
		principal,
		broker,
		tradedCounterCounterpartySide,
	};
};
