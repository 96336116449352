/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { InformativeField } from './InformativeField';
import { useWatchPaperFields } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';
import { CalendarSpreadPayment } from 'src/constants/contract';
import { usePaperOrderContext } from 'src/components/CreateOrderDrawer/paper/CreatePaperOrderDrawerContent';
import { formatFloatNumberToPriceFormat } from 'src/_helpers/price';
import { getPresetCurrencyAndUnitOrDefault } from 'src/_helpers/currency';

const NetBalanceField = ({ path, t }) => {
	const { priceValue, spreadTypeValue } = useWatchPaperFields(path);
	const { selectedPreset } = usePaperOrderContext();

	const { label } = getPresetCurrencyAndUnitOrDefault(selectedPreset, t);

	const getPayReceiveText = () => {
		if (!priceValue || spreadTypeValue === CalendarSpreadPayment.Even) {
			return '---';
		}

		const key = spreadTypeValue === CalendarSpreadPayment.Pay ? 'you_pay' : 'you_receive';

		return `${t(key, {
			value: formatFloatNumberToPriceFormat(priceValue),
		})} ${label}`;
	};

	return (
		<InformativeField
			title={t('net_balance')}
			subtitle={getPayReceiveText()}
			dataTest="net-balance-field"
		/>
	);
};

export const netBalanceField = (path, t) => <NetBalanceField path={path} t={t} />;
