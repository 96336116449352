/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Type, Info, InfoTitle, TradeInfoWrapper } from '../../../sections/common';
import { UrlValue } from '../ContractSection/ContractSection';
import { InfoItemColumn } from '../styledTradeInfoComponents';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import * as Styled from './styled';

export const AdditionalInfoSection = ({ info }) => {
	const { t } = useTranslation();

	return (
		<>
			<InfoTitle>{t('additional_information')}</InfoTitle>
			<TradeInfoWrapper>
				<InfoItemColumn>
					<Styled.InfoItem>
						<Type>{t('trade_date')}</Type>
						<Info data-test="trade-details-additional-info-trade-date">
							<OverflowText>{info.created_at}</OverflowText>
						</Info>
					</Styled.InfoItem>
				</InfoItemColumn>
				<InfoItemColumn>
					{info.additional_terms?.[0] && (
						<Styled.InfoItem $noRightMargin>
							<Type>{t('additional_terms')}</Type>
							<UrlValue
								url={info.additional_terms[0].original_link}
								description={info.additional_terms[0].name}
							/>
						</Styled.InfoItem>
					)}
				</InfoItemColumn>
			</TradeInfoWrapper>
		</>
	);
};
