/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { getChartData } from 'src/_api/trades.api';
import { Dateformat, parseDateStringAsUTC, formatDateRange } from 'src/_helpers/date';
import { tCargoTypeGroup, tPriceType, Market } from 'src/constants/contract';
import {
	tContinuousShipmentPeriod,
	ContinuousShipmentPeriod,
	ChartIntervals,
} from 'src/constants/chart';
import { loadState, storageKey } from 'src/_helpers/searchState';
import { initialChartFilters } from 'src/_store/reducers/chart.reducers';
import { useMemo, useState, useRef } from 'react';
import { useChartFilters } from 'src/containers/MobileChart/helpers/useChartFilters';
import { useActiveMarket } from 'src/_routes/useActiveMarket';
import { mapPaperFiltersToAPI, mapPhysicalFiltersToAPI } from 'src/_helpers/chart';
import { useSelector } from 'react-redux';
import { areFiltersValid, paperChartSelector, physicalChartSelector } from 'src/_store/selectors';

const configurationData = {
	supports_marks: false,
	supports_timescale_marks: false,
	supports_time: false,
	supported_resolutions: Object.keys(ChartIntervals),
};

export const getActiveAsset = () => {
	return window.localStorage.getItem('chart-active-asset');
};
export const setActiveAsset = ticker => {
	return window.localStorage.setItem('chart-active-asset', ticker);
};

const createShipmentData = (shipment, t) => {
	if (shipment?.format === Dateformat.Continuous && shipment?.continuousPeriod) {
		return tContinuousShipmentPeriod(t, ContinuousShipmentPeriod[shipment.continuousPeriod]);
	}

	if (shipment?.startDate && shipment?.endDate) {
		return formatDateRange({
			format: shipment.format,
			startDate: shipment?.startDate && parseDateStringAsUTC(shipment.startDate),
			endDate: shipment?.endDate && parseDateStringAsUTC(shipment.endDate),
		});
	}
};

export const useDatafeed = ({ t, setEntriesLength = () => {} }) => {
	const [isLoading, setIsLoading] = useState(false);
	const mobileChartFilters = useChartFilters();

	const platformMarket = useActiveMarket();
	const forcedMarket = mobileChartFilters?.paper ? Market.Paper : Market.Physical;

	const activeMarket = mobileChartFilters ? forcedMarket : platformMarket;

	const selector = activeMarket === Market.Paper ? paperChartSelector : physicalChartSelector;

	const webChartFilters = useSelector(selector);

	const resetChartDataFn = useRef();
	const updateDataPointFn = useRef();

	const areChartFiltersValid = areFiltersValid(
		mobileChartFilters?.[activeMarket] || webChartFilters,
		activeMarket
	);

	return useMemo(
		() => ({
			// asset config already comes for a specific market
			createAsset: assetConfig => {
				let descriptionToDisplay = '';

				if (activeMarket === Market.Paper) {
					const shipmentFormatted = createShipmentData(assetConfig.shipment, t);

					descriptionToDisplay = [
						assetConfig.paperPreset?.name || assetConfig.paperPreset?.text,
						assetConfig.priceType && tPriceType(t, assetConfig.priceType),
						shipmentFormatted,
					]
						.filter(Boolean)
						.join(', ');
				} else {
					const {
						product,
						inco,
						grade,
						location: { regions, countries },
						shipment,
						priceType,
						cargoType,
					} = assetConfig;

					const shipmentFormatted = createShipmentData(shipment, t);

					const locations = [...regions, ...countries].map(location => location?.name);

					const description = [
						product?.name,
						inco?.name,
						...locations,
						priceType && tPriceType(t, priceType),
						grade?.name,
						shipmentFormatted,
						cargoType?.length && cargoType.map(ct => tCargoTypeGroup(t, ct)).join(', '),
					]
						.filter(Boolean)
						.join(', ');

					const NOLA_FILTERS_TICKER = [
						`Soybeans, FOB, United States, ${t('flat')}, ${t('cargo_type_bulk')}`,
						`Corn, FOB, United States, ${t('flat')}, ${t('cargo_type_bulk')}`,
					];

					descriptionToDisplay = NOLA_FILTERS_TICKER.includes(description)
						? description.replace('United States', 'NOLA')
						: description;
				}

				const asset = {
					// Use ticker as an ID, its hidden from the user
					ticker: descriptionToDisplay,
					symbol: descriptionToDisplay,
					name: descriptionToDisplay,
					full_name: descriptionToDisplay,
					description: descriptionToDisplay,
				};

				return asset;
			},
			onReady: callback => {
				setTimeout(() => callback(configurationData)); // callback must be called asynchronously
			},
			// TODO: Implement symbol search API
			// https://vosbor.atlassian.net/browse/TD-520
			searchSymbols: async (userInput, exchange, symbolType, onResultReadyCallback) => {
				onResultReadyCallback(true); // to disables search and not throwing console error
			},
			resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => {
				setTimeout(() => {
					const filters =
						mobileChartFilters ||
						loadState(storageKey.chart) ||
						initialChartFilters[activeMarket];

					if (!filters) {
						onResolveErrorCallback('[resolveSymbol]: symbol not found');
						return;
					}

					onSymbolResolvedCallback({
						name: symbolName,
						ticker: symbolName,
						description: symbolName,
						filters,
						session: '24x7',
						minmov: 1,
						pricescale: 100,
						has_intraday: true,
						has_daily: true,
						has_weekly_and_monthly: true,
						has_no_volume: false,
						currency_code: 'USD',
						timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					});
				}, 0);
			},
			getBars: async (
				symbolInfo,
				interval,
				periodParams,
				onHistoryCallback,
				onErrorCallback
			) => {
				setIsLoading(true);

				if (!areChartFiltersValid) {
					setIsLoading(false);

					return;
				}

				const { firstDataRequest } = periodParams;

				if (!firstDataRequest) {
					onHistoryCallback([], {
						noData: true,
					});
					setIsLoading(false);

					return;
				}

				const mapper =
					activeMarket === Market.Paper ? mapPaperFiltersToAPI : mapPhysicalFiltersToAPI;

				const requestObject = mapper(symbolInfo.filters, interval);

				try {
					const klines = await getChartData({ ...requestObject });
					setEntriesLength(klines.length);

					onHistoryCallback(klines, {
						noData: klines.length === 0,
					});
				} catch (error) {
					onErrorCallback(error);
				} finally {
					setIsLoading(false);
				}
			},

			// subscription to real-time updates
			subscribeBars: (
				symbolInfo,
				resolution,
				onRealtimeCallback,
				subscriberUID,
				onResetCacheNeededCallback
			) => {
				onResetCacheNeededCallback();
				resetChartDataFn.current = onResetCacheNeededCallback;
				updateDataPointFn.current = onRealtimeCallback;
			},
			unsubscribeBars: _subscriberUID => {},
			setIsLoading,
			isLoading,
			resetChartDataFn: resetChartDataFn.current,
			updateDataPointFn: updateDataPointFn.current,
		}),
		[isLoading, activeMarket, t, mobileChartFilters, areChartFiltersValid, setEntriesLength]
	);
};
