/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserCompanyName, getUserId } from 'src/_store/selectors';
import {
	LegType,
	OrderType,
	PriceType,
	calculate2ndLegPrice,
	SpreadPriceFormat,
} from 'src/constants/contract';
import { formatDateMonthYear, formatDateRange, parseDateStringAsUTC } from 'src/_helpers/date';
import { SpreadLegRow } from 'src/components/ViewOrderDrawer/components/SpreadLegRow/SpreadLegRow';
import { Divider } from 'src/components/ViewOrderDrawer/styled';
import { useWatchPaperCounterFields } from '../../../../helpers/paper/useWatchPaperCounterFields';
import { formatProductAndFuturesCode } from 'src/_helpers/futuresContracts';
import { getCurrencyAndUnitOfOrderOrCounter } from 'src/_helpers/price';
import {
	calculateSpreadPrice,
	legShipmentLabel,
	flatBasisSpreadPriceFormat,
} from 'src/_helpers/spread';

export const CalendarSpreadInfo = ({ path, lastCounter }) => {
	const { t } = useTranslation();
	const myCompanyName = useSelector(getUserCompanyName);
	const currentUserId = useSelector(getUserId);

	const {
		firstLegFuturesMonthValue,
		firstLegMonthValue,
		secondLegMonthValue,
		secondLegFuturesMonthValue,
		firstLegPriceValue,
		priceValue,
		spreadTypeValue,
	} = useWatchPaperCounterFields(path);

	const isMyOrder = lastCounter.order_user_id === currentUserId;

	const isBasisPrice = lastCounter.price_type === PriceType.Basis;

	let buyer;
	let seller;

	const principalDisplayName = lastCounter.order_owner_principal.hidden
		? t('hidden')
		: lastCounter.order_owner_principal.company?.name || t('not_provided');

	if (isMyOrder) {
		if (lastCounter.order_type === OrderType.Sell) {
			seller = principalDisplayName;
			buyer = lastCounter.company.name;
		} else {
			buyer = principalDisplayName;
			seller = lastCounter.company.name;
		}
	} else if (lastCounter.order_type === OrderType.Sell) {
		seller = principalDisplayName;
		buyer = myCompanyName;
	} else {
		buyer = principalDisplayName;
		seller = myCompanyName;
	}

	const productCode = isBasisPrice
		? lastCounter.product.futures_contracts[0].product_code
		: undefined;

	const firstLegPriceSuffix = formatProductAndFuturesCode(productCode, firstLegFuturesMonthValue);

	const currentCounterOrderType =
		currentUserId !== lastCounter.order_user_id
			? lastCounter.order_type === OrderType.Buy
				? OrderType.Sell
				: OrderType.Buy
			: lastCounter.order_type;
	const isFirstLegBuyerInCurrentCounter = currentCounterOrderType === OrderType.Buy;

	const isPayCashSpread = lastCounter.spread_details.price_format === SpreadPriceFormat.PayCash;

	const spreadValuePrice = isPayCashSpread
		? calculateSpreadPrice(isFirstLegBuyerInCurrentCounter, spreadTypeValue, priceValue)
		: priceValue;

	const secondLegPriceSuffix = formatProductAndFuturesCode(
		productCode,
		secondLegFuturesMonthValue
	);

	const currencyAndUnit = getCurrencyAndUnitOfOrderOrCounter(lastCounter, t);

	const firstLegPriceValueOmitSingleDash =
		firstLegPriceValue === '-' ? undefined : firstLegPriceValue;

	const firstLegPriceFormatted = flatBasisSpreadPriceFormat(
		firstLegPriceValueOmitSingleDash,
		isBasisPrice,
		currencyAndUnit,
		firstLegPriceSuffix
	);

	const secondLegPriceFormatted = flatBasisSpreadPriceFormat(
		calculate2ndLegPrice(firstLegPriceValueOmitSingleDash, spreadValuePrice),
		isBasisPrice,
		currencyAndUnit,
		secondLegPriceSuffix
	);

	const firstLegData = [
		{
			label: t('seller'),
			value: seller,
		},
		{
			label: t('buyer'),
			value: buyer,
		},
		{
			label: t(legShipmentLabel(lastCounter, 0)),
			value: isPayCashSpread
				? formatDateMonthYear(firstLegMonthValue.startDate)
				: formatDateRange({
						startDate: parseDateStringAsUTC(lastCounter.firstLegMonth.startDate),
						endDate: parseDateStringAsUTC(lastCounter.firstLegMonth.endDate),
						format: lastCounter.firstLegMonth.format,
				  }),
		},
		{
			label: t('first_leg_price'),
			value: firstLegPriceFormatted,
		},
	];

	const secondLegData = [
		{
			label: t('seller'),
			value: buyer,
		},
		{
			label: t('buyer'),
			value: seller,
		},
		{
			label: t(legShipmentLabel(lastCounter, 1)),
			value: isPayCashSpread
				? formatDateMonthYear(secondLegMonthValue.startDate)
				: formatDateRange({
						startDate: parseDateStringAsUTC(lastCounter.secondLegMonth.startDate),
						endDate: parseDateStringAsUTC(lastCounter.secondLegMonth.endDate),
						format: lastCounter.secondLegMonth.format,
				  }),
		},
		{
			label: t('second_leg_price'),
			value: secondLegPriceFormatted,
		},
	];

	return (
		<>
			<SpreadLegRow legType={LegType.First} data={firstLegData} />
			<Divider />
			<SpreadLegRow legType={LegType.Second} data={secondLegData} />
		</>
	);
};
