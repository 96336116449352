/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check16x16.svg';
import { media } from 'src/_helpers';
import { Popup } from 'src/components/DateTimeTzPicker/styled';

export const InputContainer = styled.div`
	${({ $span }) => $span && `grid-column: span ${$span};`}
	position: relative;

	& input {
		padding-left: 10px;

		${media.xl`
			padding-left: 14px;
		`}
	}

	& .apply-ellipsis input {
		text-overflow: ellipsis;
	}

	> label {
		position: absolute;
		z-index: 0;
		top: 50%;
		left: 8px;
		transform: translateY(-50%);
		margin: 0;
		padding: 4px;
		transition: top 0.3s ease, transform 0.3s ease, color 0.3s ease;
		transform-origin: top left;
		background: ${({ $labelBackground }) => $labelBackground || 'var(--neutral-900)'};
		color: var(--neutral-100);
		cursor: text;
		pointer-events: none;
		display: inline-block;
		display: inline-block;
		max-width: calc(85%);

		span {
			display: inline-block;
			max-width: calc(100% - 10px);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		${media.ll`
			left: 8px;
		`}

		${media.xl`
			left: 12px;
		`}
	}

	&.number-input > label {
		width: 100%;
	}

	& > div.disabled ~ label {
		cursor: not-allowed;
		color: var(--input-disabled-text-color);
	}

	.asterisk {
		margin-left: 2px;
	}

	&.text-input > div.disabled input,
	&.date-input > div.disabled input,
	&.number-input > div.disabled input,
	&.select-input div.ui.selection.dropdown {
		background: transparent;
	}

	&.text-input > div,
	&.number-input > div,
	&.select-input div.ui.selection.dropdown {
		border: 1px solid var(--neutral-600);
		background: transparent;
	}

	&.text-input:hover > div:not(.disabled),
	&.number-input:hover > div:not(.disabled),
	&.select-input:hover > div:not(.disabled) div.ui.selection.dropdown,
	&.select-input div.ui.active.selection.dropdown,
	& > div:focus-within {
		border-color: var(--neutral-200);
	}

	& > div:focus-within ~ label,
	&.date-input:has(.date-selector-popup) > label,
	&.input-filled > label,
	&.input-filled-controlled > label {
		top: -10px;
		transform: translateY(0) scale(0.85, 0.85);
		cursor: default;
		z-index: 11;
		max-width: unset !important;
		width: unset !important;

		span:first-of-type {
			width: unset !important;
			max-width: unset !important;
		}
	}

	&.input-filled-controlled .dropdown.icon {
		display: none;
	}

	&.input-filled-controlled .date-selector-field {
		border-color: var(--neutral-300) !important;
	}

	&.input-filled:not(:has(.error)):not(:has(.disabled)) > label {
		color: var(--green-400);
	}

	&.disabled > label {
		cursor: not-allowed;
		color: var(--input-disabled-text-color);
	}

	&.disabled > svg {
		display: none;
	}

	&.input-filled > div,
	&.split-field.input-filled,
	&.input-filled div.ui.selection.dropdown,
	&.input-filled:hover {
		border-color: var(--green-400) !important;
	}

	&.split-field.input-filled {
		label {
			color: var(--green-400) !important;
		}
	}

	&.input-filled-controlled > label,
	&.input-filled-controlled > svg {
		color: var(--neutral-300);
	}

	&.input-filled-controlled > div:not(.disabled),
	&.split-field.input-filled-controlled,
	&.input-filled-controlled div.ui.selection.dropdown:not(.disabled),
	&.input-filled-controlled:hover {
		border-color: var(--neutral-300) !important;
	}

	&.input-filled {
		& .disabled div.ui.selection.dropdown,
		div.disabled {
			border-color: var(--neutral-600) !important;
		}
	}

	&.input-filled > div > input {
		padding-right: 35px;
	}

	&.input-filled.select-input i.dropdown.icon:not(.clear) {
		display: none;
	}

	&.select-input i.dropdown.icon.clear {
		display: none;
	}

	&.select-input div.ui.active.selection.dropdown {
		i.dropdown.icon.clear {
			display: block;
		}
	}

	&.select-input:has(div.ui.active.selection.dropdown) {
		label + .valid-value {
			display: none;
		}
	}

	&.split-field.error:has(.number-field.disabled) {
		border-color: var(--neutral-600) !important;
	}

	&.split-field.input-filled.error {
		> label {
			color: var(--material-input-error-border-color) !important;
		}

		> svg {
			display: none;
		}

		input + label {
			transform: translateX(0);
		}
	}

	&.select-input > div.error > div.ui.selection.dropdown,
	&.split-field.error,
	&.number-input > div.error,
	&.text-input > div.error {
		border-color: var(--material-input-error-border-color) !important;
	}

	&.number-input.input-filled > div.error {
		& ~ label {
			color: var(--material-input-error-border-color) !important;

			& + svg {
				display: none;
			}
		}

		input + label {
			transform: translateX(0);
		}
	}

	&.select-input > div.disabled.error ~ label {
		background: transparent;
	}

	&.split-field > div {
		position: relative;
		z-index: 1;
	}

	&.select-input {
		input + div {
			width: 98%;
			overflow: hidden;
			text-overflow: ellipsis !important;
		}
		.menu {
			background-color: var(--neutral-800);
		}
		.ui.search.dropdown > input.search {
			z-index: initial;
		}

		&.search {
			> label {
				max-width: 75%;
			}
		}

		/* increase z-index of the Select with open menu to make sure that it covers any inputs with input-filled class */
		&:has(.menu.visible) {
			z-index: 20;
		}
	}

	&.select-input.search {
		& .ui.selection.dropdown .menu {
			max-width: 100%;

			.item {
				span {
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
		& .ui.selection.dropdown > .dropdown.icon {
			color: var(--neutral-300);
		}

		&.input-filled {
			.dropdown.icon {
				display: none;
			}
		}
	}

	&.split-field .select-field {
		z-index: 12;
		margin-left: 4px;

		.ui.selection.dropdown {
			.menu {
				left: unset;
				right: -2px;
			}
		}
	}

	&.select-input,
	&.split-field .number-field,
	&.split-field .select-field {
		--_height: 36px;

		${media.ll`
			--_height: 36px;
		`}
		${media.xl`
			--_height: 40px;
		`}

		height: var(--_height) !important;

		input {
			height: var(--_height) !important;
		}
		.ui.selection.dropdown {
			height: var(--_height);
			max-height: var(--_height);
		}
	}

	&.text-input:not(.textarea),
	&.date-input > div,
	&.number-input {
		--_height: 36px;
		--_max_width_base: 85%;

		${media.xl`
			--_height: 40px;
		`}

		> div {
			height: var(--_height) !important;
		}

		input {
			height: calc(var(--_height) - 2px) !important;
		}
	}

	&.number-input.input-filled.with-checkmark:not(:has(.disabled)),
	&.number-input.input-filled-controlled.with-checkmark:not(:has(.disabled)) {
		input + label {
			transform: translateX(-24px);
		}
	}

	&.multi-select {
		&.input-disabled > label {
			color: var(--neutral-400);
		}

		.combobox-text-input {
			background: transparent;
			border-width: 1px;
			height: 36px;

			${media.ll`
				height: 36px !important;
			`}

			${media.xl`
				height: 40px !important;
			`}

			&.disabled {
				border-color: var(--neutral-600);
			}
		}

		.options {
			background-color: var(--neutral-800);

			.header {
				pointer-events: none;
				span {
					color: var(--neutral-100);
				}
			}
		}

		&:hover {
			.combobox-text-input {
				border-color: var(--neutral-200);

				&.disabled {
					border-color: var(--neutral-600);
				}
			}
		}

		.content-wrapper {
			div:last-of-type {
				z-index: 15;
			}
		}

		.expand-icon {
			right: 13px;
		}

		&.input-filled {
			.combobox-text-input {
				border-color: var(--green-400);
			}
		}

		&.input-filled-controlled {
			.combobox-text-input {
				border-color: var(--neutral-400);

				&.disabled {
					border-color: var(--neutral-600);

					~ label span {
						color: var(--neutral-400);
					}
				}
			}
		}

		&.input-filled,
		&.input-filled-controlled {
			.expand-icon,
			.clear-icon {
				display: none;
			}
		}
	}

	&.date-input {
		> label {
			display: flex;
			align-items: center;

			span {
				display: inline-block;
				max-width: 90px;
				overflow: hidden;
				text-overflow: ellipsis;

				${media.ll`
					max-width: 100px;
				`}

				${media.xl`
					max-width: unset;
				`}
			}
		}

		input {
			width: calc(100% - 26px) !important;
			overflow: hidden;
			text-overflow: ellipsis;

			${media.ll`
				width: calc(100% - 28px) !important;
			`}
		}

		.date-selector-popup {
			z-index: 20;
		}

		.date-selector-field {
			background: transparent;
			border-width: 1px;
			border-color: var(--neutral-600);
			justify-content: flex-start;

			&:hover:not(.disabled) {
				border-color: var(--neutral-200);
			}

			&.error {
				border-color: var(--material-input-error-border-color);

				&:hover {
					border-color: var(--material-input-error-border-color);
				}
			}

			.last-icon {
				z-index: 5;
			}
		}

		&.input-filled:not(:has(.error)) {
			.date-selector-field {
				border-color: var(--green-400);

				&:hover:not(.disabled) {
					border-color: var(--green-400);
				}
			}
		}

		&.input-filled:has(.error) {
			> label {
				color: var(--material-input-error-border-color);
			}

			&:hover {
				border-color: var(--material-input-error-border-color);
			}
		}

		&.input-filled,
		&.input-filled-controlled {
			.last-icon {
				display: none;
			}
		}

		&.align-right {
			.date-selector-popup {
				right: 0;
			}
		}
	}

	&.textarea {
		& textarea {
			height: 19px;
		}

		> div {
			padding: 7px 16px 8px 8px;

			${media.xl`
				padding: 9px 16px 10px 12px;
			`}
		}

		svg {
			top: 18px;
			right: 12px;

			${media.xl`
				top: 21px;
				right: 15px;
			`}
		}

		label {
			${media.xl`
				top: 20px;
				left: 10px;
			`}

			top: 18px;
		}

		& > div:focus-within ~ label,
		&.input-filled > label,
		&.input-filled-controlled > label {
			top: -10px;
		}

		textarea {
			padding-right: 12px;
		}
	}

	&.required:not(.input-filled) > label {
		color: var(--input-text-color);
	}

	&.split-field {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		border: 1px solid var(--neutral-600);
		border-radius: var(--large-border-radius);

		label {
			left: 8px;

			${media.xl`
				left: 12px;
			`}
		}

		> div,
		div.ui.selection.dropdown {
			background: transparent;
			border: 0;
		}

		.number-field {
			input {
				padding-right: 3px;
			}
		}

		.select-field {
			&.disabled {
				cursor: not-allowed;
			}

			.ui.selection.dropdown {
				min-width: var(--_width);
				padding-left: var(--_padding);
				user-select: none;
				color: var(--neutral-300);
			}

			&.changed {
				.ui.selection.dropdown {
					color: var(--input-text-color);
				}
			}
		}

		&.input-filled {
			.ui.selection.dropdown {
				color: var(--input-text-color);
			}
			.dropdown.icon {
				display: none;
			}
		}
	}

	&.price-currency {
		color: var(--neutral-300);

		.number-field {
			--_width: 62px;
			--_padding: 12px;

			${media.ll`
				--_width: 80px;
			`}

			${media.xl`
				--_padding: 16px;
			`}

			width: var(--_width);

			input {
				padding-left: var(--_padding);
				padding-right: var(--_padding);
			}
		}

		.select-field {
			--_width: 81px;
			--_padding: 4px;

			${media.xl`
				--_width: 106px;
				--_padding: 12px;
			`}

			width: var(--_width);
		}
	}

	&.select-input .ui.selection.dropdown {
		padding-left: 11px;

		${media.xl`
			padding-left: 14px;
		`}
	}

	&.number-input {
		input {
			padding-left: 11px;

			${media.xl`
				padding-left: 14px;
			`}
		}
	}
`;

export const IconWrapper = styled.span`
	position: absolute;
	top: 10px;
	right: 12px;
	color: var(--neutral-300);
	pointer-events: none;
`;

export const ValidCheckIcon = styled(CheckIcon)`
	position: absolute;
	right: 13px;
	top: 50%;
	transform: translateY(-50%);
	color: var(--green-400);
	pointer-events: none;

	${media.xl`
		right: 15px;
	`}
`;

export const Separator = styled.span`
	width: 2px;
	height: 24px;
	background: var(--neutral-600);
`;

export const DrawerPopup = styled(Popup)`
	.ui.popup& {
		&::before {
			display: none;
		}
	}
`;
