/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useSelector } from 'react-redux';
import { Menu, Icon, Sidebar } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Flags } from 'src/featureFlags/Flags';
import { useFeatureFlags } from 'src/featureFlags/FeatureFlagsContext';
import { getUserRole } from 'src/_store/selectors';
import { ReactComponent as Logo } from 'src/assets/images/logo.svg';
import { media } from 'src/_helpers';
import { FlagNames } from 'src/constants/flags';
import { h400 } from '../Typography';
import { useTogglable } from 'src/_helpers/useTogglable';
import { Notifications } from './Notifications';
import { PageSidebar, useSidebarToggler } from 'src/components/PageSidebar/PageSidebar';
import { NotificationsIndicator } from './components/NotificationsIndicator';
import { ChatIndicator } from './components/ChatIndicator';
import { useEmit } from 'src/shared/useEmit';
import { NavigationLinks } from './components/NavigationLinks';
import { UserProfileDropdown } from './UserProfileDropdown/UserProfileDropdown';
import { ChatEvent } from 'src/shared/constants';
import { MarketSwitch } from './MarketSwitch';
import { useActiveMarket } from 'src/_routes/useActiveMarket';
import { Market } from 'src/constants/contract';
import { ROUTES } from 'src/constants/routes';
import { ConversationType } from 'src/chat/constants';
import { UserRole } from 'src/constants/user';

const BubbleStyles = {
	[UserRole.Admin]: {
		background: 'var(--green-500)',
		$content: 'Admin',
	},
	[UserRole.Support]: {
		background: 'var(--yellow-800)',
		$content: 'Support',
	},
	[UserRole.Broker]: {
		background: 'var(--purple-800)',
		$content: 'Broker',
	},
	[UserRole.Trader]: {
		background: 'var(--red-600)',
		$content: 'Trader',
	},
	[UserRole.Operator]: {
		background: 'var(--blue-600)',
		$content: 'Operator',
	},
	[UserRole.Editor]: {
		background: 'var(--neutral-600)',
		$content: 'Editor',
	},
	[UserRole.Reviewer]: {
		background: 'var(--neutral-600)',
		$content: 'Reviewer',
	},
};

const HeaderActionButton = ({ action, children, ...props }) => {
	return (
		<ActionMenuButton onClick={action} className="item" {...props}>
			{children}
		</ActionMenuButton>
	);
};

export const HeaderMenu = () => {
	const userRole = useSelector(getUserRole);
	const isDemo = document.location.hostname.includes('demo');
	const [isVisible, { toggle, close }] = useTogglable(false);
	const [
		notificationsVisible,
		{ open: openNotifications, close: closeNotifications },
	] = useSidebarToggler();
	const market = useActiveMarket();
	const { isFlagEnabled } = useFeatureFlags();

	const toggleChat = useEmit(ChatEvent.Toggle, {
		type: ConversationType.Instant,
	});

	const logoLink =
		market === Market.Physical || isFlagEnabled(FlagNames.PaperOtcOrders)
			? ROUTES.root
			: ROUTES.exchange;

	return (
		<>
			<HeaderMenuWrapper size="massive" data-test="header-menu" secondary>
				<LogoLink to={logoLink}>
					<Logo />
				</LogoLink>
				<BubblesWrapper>
					{isDemo && <DemoBubble />}

					{process.env.REACT_APP_DISPLAY_ROLE_BADGE === 'true' ? (
						<HeaderBubble {...BubbleStyles[userRole]} />
					) : (
						<>
							{userRole === UserRole.Support && (
								<HeaderBubble {...BubbleStyles[UserRole.Support]} />
							)}
							{userRole === UserRole.Admin && (
								<HeaderBubble {...BubbleStyles[UserRole.Admin]} />
							)}
						</>
					)}
				</BubblesWrapper>
				<MarketSwitch />
				<PrimaryMenu data-test="primary-menu">
					<NavigationLinks />
				</PrimaryMenu>
				<MenuRight position="right">
					<div>
						<HeaderActionButton
							data-test="notifications"
							action={openNotifications}
							id="notifications-button"
						>
							<NotificationsIndicator />
						</HeaderActionButton>
						<Flags
							authorizedFlags={[FlagNames.Chat]}
							renderOn={() => (
								<HeaderActionButton
									data-test="unread-conversations-desktop"
									action={toggleChat}
									id="unread-conversations-button"
								>
									<ChatIndicator />
								</HeaderActionButton>
							)}
						/>
					</div>
					<VerticalDivider />
					<UserProfileDropdown />
					<SmallscreenOnly>
						<Menu.Item name="burger" active={isVisible} onClick={toggle}>
							<Icon name="bars" size="large" />
						</Menu.Item>
					</SmallscreenOnly>
				</MenuRight>
			</HeaderMenuWrapper>
			<SmallscreenOnly>
				<Sidebar
					as={Menu}
					visible={isVisible}
					animation="overlay"
					icon="labeled"
					vertical
					width="thin"
					secondary
				>
					<Menu.Item name="close" onClick={close}>
						<Icon name="close" size="huge" />
					</Menu.Item>
					<NavigationLinks />
					<HeaderActionButton action={openNotifications}>
						<NotificationsIndicator />
					</HeaderActionButton>
					<Flags
						authorizedFlags={[FlagNames.Chat]}
						renderOn={() => (
							<HeaderActionButton
								data-test="unread-conversations-mobile"
								action={toggleChat}
								id="unread-conversations-button"
							>
								<ChatIndicator suppressEventEmission />
							</HeaderActionButton>
						)}
					/>
				</Sidebar>
			</SmallscreenOnly>
			<PageSidebar
				className="sidebar--notifications"
				animation="overlay"
				direction="right"
				visible={notificationsVisible}
				onHide={closeNotifications}
				width="wide"
			>
				<Notifications onClose={closeNotifications} />
			</PageSidebar>
		</>
	);
};

export const VerticalDivider = styled.div`
	width: 1px;
	height: 2em;
	margin: auto;
	background-color: #1f2337;
`;

const LogoLink = styled(Link)`
	display: flex;
	align-items: center;
`;

const HeaderMenuWrapper = styled(Menu)`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: var(--z-index-top-most);

	padding-left: 24px;
	margin: 0 !important;
	border-radius: 0 !important;

	display: flex;
	align-items: center;

	height: var(--app-bar-height);

	// notifications placeholder
	flex-shrink: 0;
`;

const PrimaryMenu = styled(Menu.Menu)`
	margin-left: 22px !important;
	display: none;

	${media.m`
		display: flex;
	`}

	> .item {
		${h400}
		height: 100%;
		padding: clamp(10px, calc(-30px + 3.13vw), 15px) !important;
		margin: 0 !important;
	}

	> .item.active {
		&:after {
			content: ' ';
			display: block;
			width: 100%;
			height: 3px;
			border-radius: 2px;
			position: absolute;
			top: 0;
			left: 0;
			background: var(--primary-color);
		}
	}
`;

const ActionMenuButton = styled(Menu.Item)`
	.ui.menu .item& {
		position: relative;
		color: #43496c;

		:hover {
			color: #fff;
			background: transparent !important;
		}
	}
`;

const MenuRight = styled(Menu.Menu)`
	display: flex;
	align-items: center;

	> div:first-of-type {
		display: flex;
		position: relative;

		.item {
			.ui.menu & {
				margin: 0;
			}
		}
	}
`;

const SmallscreenOnly = styled.div`
	${media.l`
		display: none;
	`}
`;

const BubblesWrapper = styled.div`
	position: fixed;
	color: white;
	top: 1px;
	left: 24px;
	height: 13px;

	span {
		border-radius: 20px;
		padding: 0 4px;
		font-size: 11px;
		font-weight: 500;
		line-height: 12.89px;
		margin-right: 5px;
		box-shadow: 0 1.2px 18px 0px #4200ff40;
	}
`;

const DemoBubble = styled.span`
	background-color: var(--green-900);

	::before {
		content: 'Demo';
	}
`;

const HeaderBubble = styled.span`
	background-color: ${({ background }) => background};

	::before {
		content: '${({ $content }) => $content}';
	}
`;
