/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { ROUTES } from 'src/constants/routes';

export const ExpandableSection = {
	Orderbook: 'orderbook',
	MyOrders: 'my_orders',
	MyNegotiations: 'my_negotiations',
	MyTrades: 'my_trades',
};

export const ExpandableMyTabs = [
	ExpandableSection.MyOrders,
	ExpandableSection.MyNegotiations,
	ExpandableSection.MyTrades,
];

export const FullscreenNavigationMap = {
	[ExpandableSection.Orderbook]: ROUTES.orderbook,
	[ExpandableSection.MyOrders]: ROUTES.myOrders,
	[ExpandableSection.MyNegotiations]: ROUTES.myNegotiations,
	[ExpandableSection.MyTrades]: ROUTES.myTrades,
};
