/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { H200, H400 } from 'src/components/Typography';
import styled from 'styled-components';

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 5px;
`;

export const Label = styled(H200)`
	color: var(--neutral-100);
`;

export const Value = styled(H400)`
	color: var(--white);
`;
