/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import moment from 'moment';
import { Dateformat } from 'src/_helpers/date';
import { dateFromUnix } from 'src/_helpers/yup';
import { timezoneDateToIsoString, getCurrentTzOrDefault } from 'src/components/DateTimeTzPicker';
import {
	DrawerPriceType,
	Environment,
	Market,
	NameVisibility,
	OrderType,
	PriceType,
	QuoteType,
	defaultCurrencyUnit,
	Role,
	PaperInstruments,
	CalendarSpreadPayment,
	SpreadPriceFormat,
	isBasisPrice,
	PreAdvice,
	ShortPreAdviceRange,
} from 'src/constants/contract';
import {
	basisPriceTooBigTest,
	basisPriceTooSmallTest,
	flatPriceTooBigTest,
	flatPriceTooSmallTest,
	FlatLegPriceRange,
	FlatPriceRange,
} from 'src/constants/priceValidation';
import {
	getTimeOptionMinutes,
	isTimeInMinutes,
	mapTimeRangeOptionToApi,
	formatTradingSessionWithFullDate,
	isApiTime,
	mapApiTimeValue,
	TimePrefix,
} from 'src/_helpers/timeRange';
import { getNumberOfMonths } from 'src/_helpers/date';
import * as yup from 'yup';
import { isPriceDefined } from 'src/_helpers/price';
import { calculateSpreadPrice, calculatePayCashDirection } from 'src/_helpers/spread';

export const contractPricingPath = 'contractPricing';

export const paranaguaDefaultVolume = 10000;

export const defaultContractPricingRowValue = {
	orderType: QuoteType.Indicative,
	nameVisibility: NameVisibility.Hidden,
	principalVisibility: NameVisibility.Hidden,
	delivery: {
		format: Dateformat.Months,
	},
	secondLegDelivery: {
		format: Dateformat.Months,
	},
	firstLegMonth: {
		format: Dateformat.Months,
	},
	secondLegMonth: {
		format: Dateformat.Months,
	},
	currencyUnit: defaultCurrencyUnit,
	environment: Environment.Exchange,
	role: Role.Principal,
	spreadType: CalendarSpreadPayment.Cash,
};

export const defaultIndicativeTimeValue = 'MIN1440';
export const defaultFirmTimeValue = 'MIN5';

export const defaultValidity = {
	tz: getCurrentTzOrDefault(),
	localDate: moment().add(getTimeOptionMinutes(defaultIndicativeTimeValue), 'minutes').toDate(),
	time: defaultIndicativeTimeValue,
};

export const defaultValues = {
	contractPricing: [defaultContractPricingRowValue],
	validity: defaultValidity,
	validityChanged: false,
	futuresMonthChanged: false,
	firstLegFuturesMonthChanged: false,
	secondLegFuturesMonthChanged: false,
	instrument: PaperInstruments.Outright,
};

const shipmentSchema = yup
	.object({
		startDate: yup.string().required(),
		endDate: yup.string().required(),
		format: yup.mixed().oneOf(Object.values(Dateformat)),
	})
	.required();

const deliveryLengthEqualTest = delivery => ({
	name: 'delivery_months_not_equal',
	params: { delivery },
	message: delivery,
	test: value => {
		const firstLegMonths = getNumberOfMonths(delivery.startDate, delivery.endDate);
		const secondLegMonths = getNumberOfMonths(value.startDate, value.endDate);

		return firstLegMonths === secondLegMonths;
	},
});

export const createPaperOrderSchema = yup.object({
	presetID: yup.string().required(),
	instrument: yup.mixed().oneOf(Object.values(PaperInstruments)).required(),
	contractPricing: yup.array(
		yup.object().shape({
			orderType: yup.mixed().oneOf(Object.values(QuoteType)).required(),
			type: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Outright) {
					return yup.mixed().oneOf(Object.values(OrderType)).required();
				}
			}),
			nameVisibility: yup.mixed().oneOf(Object.values(NameVisibility)).required(),
			firstLegMonth: yup
				.mixed()
				.when(['$instrument', '$spreadPriceFormat'], (instrument, spreadPriceFormat) => {
					if (
						instrument === PaperInstruments.Spread &&
						spreadPriceFormat === SpreadPriceFormat.PayCash
					) {
						return shipmentSchema;
					}
				}),
			secondLegMonth: yup
				.mixed()
				.when(['$instrument', '$spreadPriceFormat'], (instrument, spreadPriceFormat) => {
					if (
						instrument === PaperInstruments.Spread &&
						spreadPriceFormat === SpreadPriceFormat.PayCash
					) {
						return shipmentSchema;
					}
				}),
			delivery: yup
				.mixed()
				.when(['$instrument', '$spreadPriceFormat'], (instrument, spreadPriceFormat) => {
					if (
						instrument === PaperInstruments.Outright ||
						spreadPriceFormat === SpreadPriceFormat.Spread
					) {
						return shipmentSchema;
					}
				}),
			secondLegDelivery: yup
				.mixed()
				.when(
					['$instrument', '$spreadPriceFormat', 'delivery'],
					(instrument, spreadPriceFormat, delivery) => {
						if (
							instrument === PaperInstruments.Spread &&
							spreadPriceFormat === SpreadPriceFormat.Spread
						) {
							return yup
								.object({
									startDate: yup.string().required(),
									endDate: yup.string().required(),
									format: yup.mixed().oneOf(Object.values(Dateformat)),
								})
								.test(deliveryLengthEqualTest(delivery))
								.required();
						}
					}
				),
			priceType: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Outright) {
					return yup
						.mixed()
						.oneOf([DrawerPriceType.Flat, DrawerPriceType.CBOT])
						.required();
				}
			}),
			firstLegPrice: yup
				.mixed()
				.when(
					['$instrument', 'priceType', 'currencyUnit', 'orderType'],
					(instrument, priceType, unit, orderType) => {
						if (instrument === PaperInstruments.Spread) {
							if (priceType === PriceType.Flat) {
								if (orderType === QuoteType.Firm) {
									return yup
										.number()
										.test(flatPriceTooBigTest(unit, FlatLegPriceRange))
										.test(flatPriceTooSmallTest(unit, FlatLegPriceRange))
										.required();
								}

								return yup
									.mixed()
									.test(flatPriceTooBigTest(unit, FlatLegPriceRange))
									.test(flatPriceTooSmallTest(unit, FlatLegPriceRange))
									.nullable();
							}

							if (orderType === QuoteType.Firm) {
								return yup
									.number()
									.test(basisPriceTooBigTest(unit))
									.test(basisPriceTooSmallTest(unit))
									.required();
							}

							return yup
								.mixed()
								.test(basisPriceTooBigTest(unit))
								.test(basisPriceTooSmallTest(unit))
								.nullable();
						}
					}
				),
			price: yup
				.mixed()
				.when(
					[
						'$instrument',
						'$spreadPriceFormat',
						'priceType',
						'currencyUnit',
						'spreadType',
					],
					(instrument, spreadPriceFormat, priceType, unit, spreadType) => {
						if (instrument === PaperInstruments.Outright) {
							if (priceType !== DrawerPriceType.Flat) {
								return yup
									.number()
									.test(basisPriceTooBigTest(unit))
									.test(basisPriceTooSmallTest(unit))
									.required();
							} else {
								return yup
									.number()
									.test('min', val => val >= 0)
									.required();
							}
						} else if (spreadPriceFormat === SpreadPriceFormat.PayCash) {
							if (spreadType !== CalendarSpreadPayment.Even) {
								return yup
									.number()
									.test('min', val => val > 0)
									.required();
							}

							return yup
								.number()
								.test(basisPriceTooBigTest(unit))
								.test(basisPriceTooSmallTest(unit))
								.required();
						} else {
							return yup
								.number()
								.test(flatPriceTooBigTest(unit, FlatPriceRange))
								.test(flatPriceTooSmallTest(unit, FlatPriceRange))
								.required();
						}
					}
				),
			runs: yup.mixed().when(['$runsRequired', 'orderType'], (validateRuns, orderType) => {
				return validateRuns && orderType === QuoteType.Firm
					? yup
							.number()
							.test('min', val => val > 0)
							.required()
					: yup.mixed();
			}),
			volume: yup.mixed().when(['orderType'], orderType => {
				if (orderType === QuoteType.Firm) {
					return yup.number().required().min(1);
				}

				return yup.mixed();
			}),
			futuresMonth: yup.mixed().when(['$instrument'], instrument => {
				if (instrument === PaperInstruments.Outright) {
					return yup.mixed().when('priceType', {
						is: val => val !== DrawerPriceType.Flat,
						then: yup.date().transform(dateFromUnix).required(),
					});
				}
			}),
			firstLegFuturesMonth: yup
				.mixed()
				.when(['$instrument', '$spreadPriceFormat'], (instrument, spreadPriceFormat) => {
					if (
						instrument === PaperInstruments.Spread &&
						spreadPriceFormat === SpreadPriceFormat.PayCash
					) {
						return yup.date().transform(dateFromUnix).required();
					}
				}),
			secondLegFuturesMonth: yup
				.mixed()
				.when(['$instrument', '$spreadPriceFormat'], (instrument, spreadPriceFormat) => {
					if (
						instrument === PaperInstruments.Spread &&
						spreadPriceFormat === SpreadPriceFormat.PayCash
					) {
						return yup.date().transform(dateFromUnix).required();
					}
				}),
			environment: yup.mixed().oneOf(Object.values(Environment)).required(),
			role: yup.mixed().oneOf(Object.values(Role)).required(),
			principal: yup.string().nullable(),
			principalVisibility: yup.mixed().oneOf(Object.values(NameVisibility)).nullable(),
			preAdviceDays: yup.mixed().when('preAdvice', preAdvice => {
				if (preAdvice === PreAdvice.Short) {
					return yup
						.number()
						.min(ShortPreAdviceRange[0], 'pre-advice value too small')
						.max(ShortPreAdviceRange[1], 'pre-advice value too big')
						.required();
				}

				return yup.mixed().nullable();
			}),
			preAdviceVesselName: yup.mixed().when('preAdvice', preAdvice => {
				if (preAdvice === PreAdvice.ToSuitVessel) {
					return yup.string().required();
				}

				return yup.mixed().nullable();
			}),
			counterparties: yup
				.mixed()
				.when(['environment', '$paperCounter'], (environment, $paperCounter) => {
					return environment === Environment.OTC && !$paperCounter
						? yup.array().required()
						: yup.array();
				}),
		})
	),
	validity: yup
		.object({
			time: yup.string(),
			localDate: yup.date().when('time', time => {
				if (!!time) {
					return yup.date();
				}
				return yup.date().test('min', val => yup.date().min(new Date()).isValidSync(val));
			}),
			tz: yup.string(),
		})
		.required(),
});

export const mapDataToApi = data => {
	const { presetID, contractPricing, validity, instrument, spreadPriceFormat } = data;

	return contractPricing.map(orderRow => {
		const recipients = (orderRow.fullContactsList || [])
			.map(user => user._key)
			.filter((id, index, list) => list.indexOf(id) === index);

		const isBasis = isBasisPrice(orderRow.priceType);
		const isPayCashSpread = spreadPriceFormat === SpreadPriceFormat.PayCash;

		const spreadFields = isPayCashSpread
			? {
					firstLegMonth: 'firstLegMonth',
					secondLegMonth: 'secondLegMonth',
					volume: 'volume',
			  }
			: {
					firstLegMonth: 'delivery',
					secondLegMonth: 'secondLegDelivery',
					volume: 'totalVolume',
			  };

		const calendarSpreadResult =
			instrument === PaperInstruments.Spread
				? {
						delivery_date_from: orderRow[spreadFields.firstLegMonth].startDate,
						delivery_date_to: orderRow[spreadFields.firstLegMonth].endDate,
						delivery_mode: orderRow[spreadFields.firstLegMonth].format,
						futures_contract_date: isBasis
							? moment(orderRow.firstLegFuturesMonth).toISOString()
							: undefined,
						spread_details: {
							delivery_date_from: orderRow[spreadFields.secondLegMonth]?.startDate,
							delivery_date_to: orderRow[spreadFields.secondLegMonth]?.endDate,
							delivery_mode: orderRow[spreadFields.secondLegMonth]?.format,
							futures_contract_date: isBasis
								? moment(orderRow.secondLegFuturesMonth).toISOString()
								: undefined,
							first_leg_price: orderRow.firstLegPrice
								? +orderRow.firstLegPrice
								: undefined,
						},
						volume: orderRow.volume ? +orderRow[spreadFields.volume] : undefined,
						price: isPayCashSpread
							? calculateSpreadPrice(
									orderRow.type === OrderType.Buy,
									orderRow.spreadType,
									orderRow.price
							  )
							: +orderRow.price,
				  }
				: {};

		return {
			market: Market.Paper,
			preset_id: presetID,
			instrument: instrument,
			environment: orderRow.environment,
			role: orderRow.role,
			hidden: orderRow.nameVisibility === NameVisibility.Hidden,
			...(orderRow.principal && {
				order_owner_principal_hidden:
					orderRow.principalVisibility === NameVisibility.Hidden,
				order_owner_principal_id: orderRow.principal,
			}),
			runs: orderRow.runs ? +orderRow.runs : undefined,
			is_indicative: orderRow.orderType === QuoteType.Indicative,
			pre_advice: orderRow.preAdvice,
			pre_advice_days:
				orderRow.preAdvice === PreAdvice.Short ? +orderRow.preAdviceDays : undefined,
			pre_advice_vessel_name:
				orderRow.preAdvice === PreAdvice.ToSuitVessel
					? orderRow.preAdviceVesselName
					: undefined,
			order_type: orderRow.type,
			has_price: true, // required by BE for model consistency
			delivery_date_from: orderRow.delivery.startDate,
			delivery_date_to: orderRow.delivery.endDate,
			delivery_mode: orderRow.delivery.format,
			volume: orderRow.totalVolume ? +orderRow.totalVolume : undefined,
			price: +orderRow.price,
			price_type: orderRow.priceType !== PriceType.Flat ? PriceType.Basis : PriceType.Flat,
			...(orderRow.priceType !== PriceType.Flat && {
				futures_contract: orderRow.priceType,
				futures_contract_date: moment(orderRow.futuresMonth).toISOString(),
			}),
			...(orderRow.environment === Environment.OTC && {
				recipients,
			}),
			...calendarSpreadResult,
			validity_option: isTimeInMinutes(validity.time)
				? mapTimeRangeOptionToApi(validity.time)
				: validity.time || undefined,
			validity: validity.time
				? isTimeInMinutes(validity.time)
					? moment().utc().add(getTimeOptionMinutes(validity.time), 'minutes').toDate()
					: formatTradingSessionWithFullDate(validity.time, true)
				: timezoneDateToIsoString(validity),
			validity_timezone: validity.tz,
			...(data.version && { version: data.version }),
		};
	});
};

export const mapPreviewDataToOrderView = (preview, formData, user) => {
	const { selectedProductPreset, contractPricing } = formData;
	const { inco_id, product, instruments, additional_terms } = selectedProductPreset;

	return contractPricing.map((orderRow, index) => {
		const [currency, price_unit] = orderRow.currencyUnit.split('/');

		const spreadPriceFormat = instruments.find(i => i.type === formData.instrument)
			?.spread_price_format;

		return {
			...preview[index],
			role: orderRow.role,
			is_order_preview: true,
			is_my_order: true,
			order_owner_principal: {
				hidden:
					orderRow.role === Role.Principal
						? orderRow.nameVisibility === NameVisibility.Hidden
						: orderRow.principalVisibility === NameVisibility.Hidden,
				...(orderRow.principalUser
					? {
							user: {
								_key: orderRow.principalUser.contact_user_id,
								name: orderRow.principalUser.name,
							},
							company: {
								name: orderRow.principalUser.company_name,
								avatar_color: orderRow.principalUser.company_avatar_color,
							},
					  }
					: orderRow.role === Role.Principal && {
							user: {
								_key: user.session._key,
								name: user.session.name,
								avatar_color: user.avatar_color,
							},
							company: {
								name: user.company_name,
								avatar_color: user.company_avatar_color,
							},
					  }),
			},
			product,
			inco: {
				name: inco_id,
			},
			pre_advice: orderRow.preAdvice,
			pre_advice_days: orderRow.preAdviceDays,
			pre_advice_vessel_name: orderRow.preAdviceVesselName,
			volume: orderRow.totalVolume,
			...(preview[index].instrument === PaperInstruments.Spread &&
				spreadPriceFormat === SpreadPriceFormat.PayCash && {
					secondLegQuote: orderRow.secondLegQuote,
					firstLegMonth: orderRow.firstLegMonth,
					secondLegMonth: orderRow.secondLegMonth,
					firstLegFuturesMonth: orderRow.firstLegFuturesMonth,
					secondLegFuturesMonth: orderRow.secondLegFuturesMonth,
					firstLegPrice: orderRow.firstLegPrice ? +orderRow.firstLegPrice : undefined,
					volume: orderRow.volume,
				}),
			...(preview[index].instrument === PaperInstruments.Spread &&
				spreadPriceFormat === SpreadPriceFormat.Spread && {
					secondLegQuote: orderRow.secondLegQuote,
					firstLegMonth: orderRow.delivery,
					secondLegMonth: orderRow.secondLegDelivery,
					firstLegPrice: orderRow.firstLegPrice ? +orderRow.firstLegPrice : undefined,
					volume: orderRow.totalVolume,
				}),
			spread_details: {
				price_format: spreadPriceFormat,
				spread_type: orderRow.spreadType,
			},
			primary_ports: [
				{
					name:
						selectedProductPreset.loading_port?.name ||
						selectedProductPreset.discharging_port?.name ||
						'',
					country_id: selectedProductPreset.origin_country_id,
				},
			],
			environment: orderRow.environment,
			price: orderRow.price,
			currency,
			price_unit,
			order_type: orderRow.type,
			futures_contract_date: orderRow.futuresMonth,
			origin_countries: [...selectedProductPreset?.origin_countries],
			shipment_type: selectedProductPreset.shipment_type,
			user: {
				_key: user.session._key,
				avatar_color: user.avatar_color,
				name: user.session.name,
				first_name: user.session.first_name,
				last_name: user.session.last_name,
				company: {
					name: user.company_name,
					avatar_color: user.company_avatar_color,
				},
			},
			terms: selectedProductPreset.terms,
			additional_terms: [...(additional_terms || [])],
			grade: selectedProductPreset.grade,
			runs: orderRow.runs,
			...(orderRow.environment === Environment.OTC && {
				recipients_list: !!orderRow.fullContactsList.length
					? orderRow.fullContactsList
					: orderRow.recipients_list,
			}),
		};
	});
};

export const getPaperDefaultValuesFromApi = (response, recipients, isEditing, isCopying) => {
	const editingParams = isEditing
		? {
				created_at: response.created_at,
				_key: response._key,
				version: response.version,
				isEditing,
		  }
		: {
				isCopying,
		  };

	const isPayCashSpread = response.spread_details?.price_format === SpreadPriceFormat.PayCash;
	const isSpreadSpread = response.spread_details?.price_format === SpreadPriceFormat.Spread;

	const getPrice = () => {
		if (isPayCashSpread) {
			return response.price && Math.abs(response.price);
		}

		return isPriceDefined(response.price) ? response.price.toString() : response.price;
	};

	const price = getPrice();

	return {
		...editingParams,
		presetID: response.preset_id,
		instrument: response.instrument || PaperInstruments.Outright,
		contractPricing: [
			{
				orderType: response.is_indicative ? QuoteType.Indicative : QuoteType.Firm,
				type: response.order_type,
				role: response.role,
				environment: response.environment,
				nameVisibility: response.hidden ? NameVisibility.Hidden : NameVisibility.Visible,
				...(response.role === Role.Broker &&
					response.order_owner_principal && {
						principalVisibility: response.order_owner_principal.hidden
							? NameVisibility.Hidden
							: NameVisibility.Visible,
						principal: response.order_owner_principal.user?._key,
					}),
				delivery: {
					startDate: response.delivery_date_from,
					endDate: response.delivery_date_to,
					format: response.delivery_mode,
				},
				preAdvice: response.pre_advice,
				preAdviceDays: response.pre_advice_days,
				preAdviceVesselName: response.pre_advice_vessel_name,
				priceType: response.has_price
					? response.price_type === PriceType.Flat
						? DrawerPriceType.Flat
						: response.futures_contract
					: DrawerPriceType.NoPrice,
				price,
				...(response.runs && { runs: response.runs }),
				volume: response.multi_month
					? response.volume /
					  getNumberOfMonths(response.delivery_date_from, response.delivery_date_to)
					: response.volume,
				totalVolume: response.volume,
				futuresMonth: response.futures_contract_date
					? new Date(response.futures_contract_date).valueOf()
					: undefined,
				counterparties: recipients || response.recipients || [],
				recipients_list: response.recipients_list || [],
				...(response.instrument === PaperInstruments.Spread && {
					secondLegQuote: response.secondLegQuote,
					firstLegMonth: response.firstLegMonth,
					secondLegMonth: response.secondLegMonth,
					firstLegFuturesMonth:
						response.price_type === PriceType.Basis
							? +new Date(response.futures_contract_date)
							: undefined,
					secondLegFuturesMonth:
						response.price_type === PriceType.Basis
							? +new Date(response.spread_details.futures_contract_date)
							: undefined,
					secondLegDelivery: isSpreadSpread
						? {
								startDate: response.spread_details.delivery_date_from,
								endDate: response.spread_details.delivery_date_to,
								format: response.spread_details.delivery_mode,
						  }
						: undefined,
					firstLegPrice: response.firstLegPrice,
					spreadType: isPayCashSpread
						? price === undefined
							? CalendarSpreadPayment.Cash
							: calculatePayCashDirection(
									response.order_type === OrderType.Buy,
									response.price
							  )
						: response.spread_details?.price_format,
				}),
			},
		],
		// validity
		validity: isEditing
			? response.force_validity
				? {
						tz: getCurrentTzOrDefault(),
						localDate: moment()
							.add(getTimeOptionMinutes(response.force_validity), 'minutes')
							.toDate(),
						time: response.force_validity,
				  }
				: mapValidity(response)
			: mapValidity(response),
	};
};

export const mapValidity = response => {
	if (response.force_validity) {
		return {
			tz: getCurrentTzOrDefault(),
			localDate: moment()
				.add(getTimeOptionMinutes(response.force_validity), 'minutes')
				.toDate(),
			time: response.force_validity,
		};
	}

	if (response.validity_option) {
		if (isApiTime(response.validity_option)) {
			const { hours, minutes } = mapApiTimeValue(response.validity_option);
			const timeToAdd = hours ? [hours, 'hours'] : [minutes, 'minutes'];

			return {
				tz: getCurrentTzOrDefault(),
				localDate: moment().add(timeToAdd[0], timeToAdd[1]).toDate(),
				time: hours ? `${TimePrefix}${hours * 60}` : `${TimePrefix}${minutes}`,
			};
		} else {
			return {
				tz: getCurrentTzOrDefault(),
				localDate: moment().toDate(),
				time: response.validity_option,
			};
		}
	}

	return defaultValidity;
};

export const mapCounterFormToCounterAPI = (values, order, lastCounter) => {
	const counterValues = values.contractPricing[0];

	const lastCounterOrderType = lastCounter.counter_order_type || lastCounter.order_type;
	const isBuyerInCurrentCounter = lastCounterOrderType === OrderType.Sell;

	const price = counterValues.spreadType
		? calculateSpreadPrice(
				isBuyerInCurrentCounter,
				counterValues.spreadType,
				+counterValues.price
		  )
		: +counterValues.price;
	const isMyOrder = order.is_my_order;

	return {
		instrument: values.instrument,
		type_id: order.type_id,
		inco_id: order.inco_id,
		product_id: order.product_id,
		order_type: order.order_type,
		role: order.role,
		price_type: order.price_type,
		futures_contract: order.futures_contract,
		futures_contract_date: order.futures_contract_date,
		delivery_date_from: order.delivery_date_from,
		delivery_date_to: order.delivery_date_to,
		...(order.role === Role.Broker &&
			isMyOrder &&
			counterValues.principalId && {
				order_owner_principal_id: counterValues.principalId,
				order_owner_principal_hidden:
					counterValues.principalVisibility === NameVisibility.Hidden,
			}),
		environment: order.environment,
		market: order.market,
		order_id: order._key,
		is_indicative: counterValues.orderType === QuoteType.Indicative,
		price,
		runs: counterValues.runs ? +counterValues.runs : undefined,
		volume: counterValues.totalVolume ? +counterValues.totalVolume : undefined,
		...(values.instrument === PaperInstruments.Spread && {
			volume: counterValues.totalVolume,
			spread_details: {
				first_leg_price: counterValues.firstLegPrice
					? +counterValues.firstLegPrice
					: undefined,
			},
		}),
		validity_option: isTimeInMinutes(values.validity.time)
			? mapTimeRangeOptionToApi(values.validity.time)
			: values.validity.time || undefined,
		validity: values.validity.time
			? isTimeInMinutes(values.validity.time)
				? moment().utc().add(getTimeOptionMinutes(values.validity.time), 'minutes').toDate()
				: formatTradingSessionWithFullDate(values.validity.time, true)
			: timezoneDateToIsoString(values.validity),
	};
};
