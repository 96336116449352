/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { tCurrency, PriceType } from 'src/constants/contract';

export const MaxBasisPrice = 10;
export const MinBasisPrice = -10;

export const MinFlatLegPrice = 0;
export const MaxFlatLegPrice = 10000;

export const MinFlatPrice = -1000;
export const MaxFlatPrice = 1000;

// last element => is the MaxFlatLegPrice allowed (true) or not (false)
export const FlatLegPriceRange = [MinFlatLegPrice, MaxFlatLegPrice, false];
export const FlatPriceRange = [MinFlatPrice, MaxFlatPrice, true];

export const PriceTooBigKey = 'price_too_big';
export const PriceTooSmallKey = 'price_too_small';
export const PriceNotInRange = 'price_should_be_within_range';

export const formatPriceError = (error, t) => {
	const [currency, unit] = error.message.split('/');
	const currencyUnit = `${tCurrency(t, currency)}/${t('price_unit_short_' + unit, {
		lng: 'en',
	})}`;

	const minMaxValues = error.ref?.name?.includes('firstLegPrice')
		? [MinFlatLegPrice, MaxFlatLegPrice]
		: [MinFlatPrice, MaxFlatPrice];

	const params =
		error.type === PriceNotInRange
			? {
					min: new Intl.NumberFormat('en').format(minMaxValues[0]),
					max: new Intl.NumberFormat('en').format(minMaxValues[1]),
			  }
			: {};

	return t(error.type, {
		...params,
		unit: currencyUnit,
	});
};

export const basisPriceTooBigTest = unit => ({
	name: PriceTooBigKey,
	params: { unit },
	message: unit,
	test: price => {
		if (price >= MaxBasisPrice) {
			return false;
		}
		return true;
	},
});

export const basisPriceTooSmallTest = unit => ({
	name: PriceTooSmallKey,
	params: { unit },
	message: unit,
	test: price => {
		if (price <= MinBasisPrice) {
			return false;
		}
		return true;
	},
});

export const flatPriceTooBigTest = (unit, range) => ({
	name: PriceNotInRange,
	params: { unit },
	message: unit,
	test: price => {
		if (range[2] === true && price > range[1]) {
			return false;
		}
		if (range[2] === false && price >= range[1]) {
			return false;
		}
		return true;
	},
});

export const flatPriceTooSmallTest = (unit, range) => ({
	name: PriceNotInRange,
	params: { unit },
	message: unit,
	test: price => {
		if (price < range[0]) {
			return false;
		}
		return true;
	},
});

export const isLegPriceInRange = (order, price) => {
	if (order.price_type === PriceType.Basis) {
		return price > MinBasisPrice && price < MaxBasisPrice;
	}

	if (order.price_type === PriceType.Flat) {
		return price >= MinFlatLegPrice && price < MaxFlatLegPrice;
	}

	return true;
};
