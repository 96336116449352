/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
export const getInstrumentPreAdviceOptions = (preset, instrument) => {
	if (!preset || !instrument) {
		return null;
	}

	const options =
		preset?.instruments?.reduce((acc, { pre_advice, type }) => {
			acc[type] = pre_advice || null;

			return acc;
		}, {}) || {};

	return options[instrument];
};
