/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { PositiveButton } from 'src/components/Buttons/PositiveButton';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { acceptOrder } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useTogglable } from 'src/_helpers/useTogglable';
import { OrderType, tOrderType, PaperInstruments } from 'src/constants/contract';
import { useNavigate } from 'react-router-dom';
import {
	Modal,
	ButtonsWrapper,
	ModalIconWrapper,
	ModalHeaderWrapper,
	CancelButton,
} from 'src/components/Modal';
import { Tooltip, TooltipTrigger, TooltipContent } from 'src/components/Tooltip/Tooltip';
import { ModalSize, ModalType } from 'src/constants/modal';
import { useViewOrderDrawerContext } from 'src/components/ViewOrderDrawer/ViewOrderDrawerProvider';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { useAcceptOrderAsBroker } from 'src/containers/OrderDetails/useAcceptOrderAsBroker';
import { PrincipalSelectorDropdown } from '../../components/PrincipalSelectorDropdown/PrincipalSelectorDropdown';
import { AcceptOutrightSummary } from './AcceptOutrightSummary';
import { AcceptSpreadSummary } from './AcceptSpreadSummary';
import * as Styled from './styled';

export const AcceptOrderModal = ({ order, submitButtonId, trigger }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [isOpen, { open, close }] = useTogglable();
	const { addToast } = useToast();
	const queryClient = useQueryClient();
	const isBidOrder = order.order_type === OrderType.Buy;
	const isSpread = order.instrument === PaperInstruments.Spread;
	const userId = useSelector(getUserId);

	const { setAccepteeId } = useViewOrderDrawerContext();

	const {
		userHasBrokerRole,
		canAcceptOrder,
		acceptButtonTooltip,
		principalOptions,
		principalId,
		setPrincipalId,
		selectedPrincipal,
	} = useAcceptOrderAsBroker(order, isOpen);

	const modalTitleKey = isSpread
		? 'confirm_accepting_spread'
		: isBidOrder
		? 'confirm_accepting_paper_bid'
		: 'confirm_accepting_paper_offer';
	const modalSubtitleKey = isSpread
		? 'confirm_accepting_spread_order_info'
		: isBidOrder
		? 'confirm_accepting_bid_info'
		: 'confirm_accepting_offer_info';

	const { mutate, isLoading } = useMutation(acceptOrder, {
		onSuccess: async ({ _key: negotiationId }) => {
			if (!negotiationId) {
				addToast({
					message: t('order_no_longer_available', {
						orderType: tOrderType(t, order.order_type),
					}),
					kind: 'error',
				});
				await queryClient.invalidateQueries(['order', order._key]);

				return;
			}

			await queryClient.invalidateQueries(['my-orders']);
			await queryClient.invalidateQueries(['my-trades']);

			await queryClient.invalidateQueries(['order', order._key]);
			await queryClient.invalidateQueries(['order_with_counters', order._key, negotiationId]);

			navigate(
				`?orderEnvironment=${order.environment}&orderId=${order._key}&negotiationId=${negotiationId}`
			);

			close();
		},
		onError: err => {
			addToast({
				message: t('accept_{{order_type}}_failed', {
					order_type: tOrderType(t, order.order_type).toLowerCase(),
				}),
				kind: 'error',
			});
		},
	});

	if (!order) {
		return;
	}

	const PrincipalSelectorComponent = userHasBrokerRole ? (
		<PrincipalSelectorDropdown
			options={principalOptions}
			principalId={principalId}
			setPrincipalId={setPrincipalId}
		/>
	) : null;

	return (
		<Modal
			data-test="accept-order"
			size={ModalSize.Tiny}
			type={ModalType.Success}
			open={open}
			close={close}
			isOpen={isOpen}
			trigger={trigger}
		>
			<ModalHeaderWrapper>
				<ModalIconWrapper type={ModalType.Warning} />
				<Styled.ModalTitle>{t(modalTitleKey)}</Styled.ModalTitle>
				<Styled.ModalSubTitle>
					{t(modalSubtitleKey, {
						counterparty: order.hidden ? t('counterparty_lowercase') : order.user?.name,
					})}
				</Styled.ModalSubTitle>
			</ModalHeaderWrapper>
			{isOpen && (
				<Styled.ModalContent>
					{order.instrument === PaperInstruments.Spread ? (
						<AcceptSpreadSummary
							order={order}
							PrincipalSelectorComponent={PrincipalSelectorComponent}
							selectedPrincipal={selectedPrincipal}
						/>
					) : (
						<AcceptOutrightSummary
							order={order}
							PrincipalSelectorComponent={PrincipalSelectorComponent}
						/>
					)}
				</Styled.ModalContent>
			)}
			<ButtonsWrapper>
				<CancelButton onClick={close} />
				{canAcceptOrder ? (
					<PositiveButton
						loading={isLoading}
						type="button"
						data-test="accept-order-modal-button"
						id={submitButtonId}
						onClick={() => {
							setAccepteeId(userId);
							mutate({
								id: order._key,
								environment: order.environment,
								version: order.version,
								principal_id: principalId,
							});
						}}
					>
						{t('accept')}
					</PositiveButton>
				) : (
					<Tooltip>
						<TooltipTrigger>
							<Styled.InactiveTooltipAcceptButton>
								{t('accept')}
							</Styled.InactiveTooltipAcceptButton>
						</TooltipTrigger>
						<TooltipContent>{acceptButtonTooltip}</TooltipContent>
					</Tooltip>
				)}
			</ButtonsWrapper>
		</Modal>
	);
};
