/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { OrderStatus } from 'src/constants/orderStatus';
import {
	fetchFromExchangeApi,
	map404ToEmptyList,
	map404ToEmptyObject,
	map404ToNull,
	Timeout,
} from './model';
import { Environment, CargoType, PaperInstruments, Market } from 'src/constants/contract';
import { Dateformat } from 'src/_helpers/date';
import { getMyContacts, getGroups } from './my-account.api';
import { FilterQueryParam, FilterType } from 'src/containers/TeamsDashboard/constants';
import { sortOrder } from 'src/_helpers/sort';

const analytics = require('ga-browser')();

export const getOrderDetailsFromCounter = async ({ environment, orderId, negotiationId }) => {
	const counter = await fetchFromExchangeApi(
		`${environment}/read_order/${orderId}/negotiation/${negotiationId}`,
		{
			method: 'GET',
			mapStatusToResult: map404ToEmptyList,
		}
	);

	return counter.at(-1);
};

export const getMyUnseenNegotiations = async () => {
	return fetchFromExchangeApi('market/unseen/count', {
		method: 'GET',
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const getOrderDetailsFromTrade = async ({ tradeId }) => {
	const trade = await fetchFromExchangeApi(`trade/${tradeId}`, {
		method: 'GET',
		mapStatusToResult: map404ToEmptyObject,
	});

	if (!trade) {
		return null;
	}

	const order = await fetchOrder({ orderId: trade.order_id, environment: trade.environment });

	if (!order) {
		return null;
	}

	return {
		...trade,
		hidden: order.hidden,
	};
};

export const getOrderDetails = async ({ orderId, environment }) => {
	const order = await fetchOrder({ orderId, environment });

	return orderMapper(order);
};

export const getOrderNegotiations = async ({
	environment,
	orderId,
	_order = 'DESC',
	_sort = 'updated_at',
	status,
	counter_order_user_id,
}) => {
	const counters = await fetchFromExchangeApi(
		`${environment}/read_order/${orderId}/negotiation`,
		{
			method: 'GET',
			query: {
				_order,
				_sort,
				...(counter_order_user_id && { counter_order_user_id }),
				...(status && { status }),
			},
			mapStatusToResult: map404ToEmptyList,
		}
	);

	return counters.map(orderMapper);
};

export const paperOrderMapper = (order = {}) => ({
	...order,
	delivery_mode: order.delivery_mode || Dateformat.Months,
	file_ids: order.file_ids || [],
	files: (order.files || []).map(file => ({
		...file,
		owner: order.user,
	})),
	grade: order.grade?.is_custom
		? {
				...order.grade,
				gradespecs: (order.grade?.gradespecs || []).map(gradeSpec => ({
					...gradeSpec,
					value:
						order.orderspecs?.find(orderSpec => orderSpec.spec_id === gradeSpec.spec_id)
							?.value || '',
				})),
		  }
		: order.grade,
});

const gradeSpecComparator = (a, b) => {
	if (a.display_order === b.display_order) {
		return 0;
	}

	return a.display_order > b.display_order ? 1 : -1;
};

export const orderMapper = (order = {}) => {
	const sortedGradeSpecs = order.grade?.gradespecs
		? order.grade?.gradespecs.sort(gradeSpecComparator)
		: undefined;

	// TODO: used origin_country for backward compatibility, to remove after release https://vosbor.atlassian.net/browse/MAR-2617
	const origin =
		order.origin_countries == null
			? order.origin_country
				? [order.origin_country]
				: null
			: order.origin_countries;

	const spreadOrderData =
		order.market === Market.Paper && order.instrument === PaperInstruments.Spread
			? {
					firstLegMonth: {
						startDate: order.delivery_date_from,
						endDate: order.delivery_date_to,
						format: order.delivery_mode,
					},
					firstLegFuturesMonth: new Date(order.futures_contract_date),
					secondLegMonth: {
						startDate: order.spread_details?.delivery_date_from,
						endDate: order.spread_details?.delivery_date_to,
						format: order.spread_details?.delivery_mode,
					},
					secondLegFuturesMonth: new Date(order.spread_details?.futures_contract_date),
					firstLegPrice: order.spread_details?.first_leg_price,
			  }
			: {};

	return {
		...order,
		...spreadOrderData,
		reactKey: order.otc_type ? `${order._key}_${order.otc_type}` : order._key,
		shipment_type: order.shipment_type || CargoType.Bulk,
		origin,
		file_ids: order.file_ids || [],
		files: (order.files || []).map(file => ({
			...file,
			owner: order.user,
		})),
		grade: order.grade?.is_custom
			? {
					...order.grade,
					gradespecs: (sortedGradeSpecs || []).map(gradeSpec => ({
						...gradeSpec,
						value: order.orderspecs?.find(
							orderSpec => orderSpec.spec_id === gradeSpec.spec_id
						)?.value,
					})),
			  }
			: {
					...order.grade,
					gradespecs: sortedGradeSpecs,
			  },
		is_order: order._id?.startsWith('orders'),
	};
};

export const searchOrdersByFilter = async ({
	market,
	products,
	order,
	myApprovedContacts,
	...query
}) => {
	const body = {
		market,
		products,
		order,
	};

	if (myApprovedContacts) {
		query.my_approved_contacts = true;
	}

	const result = await fetchFromExchangeApi('search_orders', {
		method: 'POST',
		body,
		query,
		mapStatusToResult: map404ToEmptyObject,
	});

	return {
		items: result.data.map(orderMapper),
		cursor: result.meta?.next_page_cursor || '',
	};
};

export const listTeamOrders = async ({
	teamId,
	environment,
	closed,
	traders,
	orderType,
	otcType,
	...paginationParams
}) => {
	const query = {
		environment,
		expired: closed,
		otc_type: otcType,
		...paginationParams,
	};

	if (traders) {
		query.trader_id = traders;
	}

	if (orderType !== FilterType.All) {
		query.order_type = FilterQueryParam[orderType];
	}

	if (!teamId) {
		return { items: [] };
	}

	const result = await fetchFromExchangeApi(`teams/${teamId}/orders`, {
		method: 'GET',
		query,
		mapStatusToResult: map404ToEmptyObject,
	});

	return {
		items: result.data.map(orderMapper),
		cursor: result.meta?.next_page_cursor || '',
	};
};

export const searchOrdersByIDsFilter = async ({ market, order_ids, ...query }) => {
	const orders = await fetchFromExchangeApi('search_orders_by_ids', {
		method: 'POST',
		body: {
			market,
			order_ids,
		},
		query,
		mapStatusToResult: map404ToEmptyList,
	});

	return {
		items: orders.map(orderMapper),
	};
};

const baseListMyTabsOrders = async (url, query) => {
	const result = await fetchFromExchangeApi(url, {
		query,
		mapStatusToResult: map404ToEmptyList,
	});

	/** old endpoint returns plain array */
	if (Array.isArray(result)) {
		return {
			items: result.map(orderMapper),
		};
	}

	/** new endpoint will return { data: [], meta: { cursor }} */
	return {
		items: result.data.map(orderMapper),
		cursor: result.meta?.next_page_cursor || '',
	};
};

export const listMyOrdersCount = async query => {
	const result = await fetchFromExchangeApi('list_my_orders_v2', {
		query: {
			...query,
			count: true,
		},
		mapStatusToResult: map404ToEmptyObject,
	});

	return { count: result?.meta?.count || 0 };
};

export const listMyOrders = async query => {
	return baseListMyTabsOrders('list_my_orders_v2', {
		only_inactive: false,
		only_active: false,
		...query,
	});
};

export const listMyNegotiations = async query => {
	return baseListMyTabsOrders('list_my_negotiations', {
		only_inactive: false,
		only_active: false,
		...query,
	});
};

export const updateOrder = async ({ id, ...body }) => {
	return fetchFromExchangeApi(`${body.environment}/edit_order/${id}`, {
		method: 'POST',
		body,
		mapStatusToResult: map404ToNull,
	});
};

export const createOrder = async body => {
	return fetchFromExchangeApi(`${body.environment}/create_order`, {
		method: 'POST',
		body,
		mapStatusToResult: map404ToNull,
	});
};
export const createOrders = async ({ orders }) => {
	analytics('create_orders', window.gtagID, 'auto');

	return fetchFromExchangeApi('create_orders', {
		method: 'POST',
		body: orders,
		mapStatusToResult: map404ToNull,
	});
};

export const createCounter = async body => {
	return fetchFromExchangeApi(`${body.environment}/create_counter_order_v2`, {
		method: 'POST',
		body,
	});
};

export const getOrderWithCounters = async ({ environment, orderId, negotiationId }) => {
	const [order, counters] = await Promise.all([
		fetchOrder({ orderId, environment, negotiationId }),
		fetchNegotiationCounters({ orderId, environment, negotiationId }),
	]);

	let counterparty = null;

	if (!!counters.length) {
		const { counter_order_user, counter_order_user_company } = counters[0];

		counterparty = { ...counter_order_user, company: { ...counter_order_user_company } };
	}

	return {
		order: orderMapper(order),
		counters: counters.map(orderMapper),
		counterparty,
	};
};

function fetchNegotiationCounters({ orderId, environment, negotiationId }) {
	if (!negotiationId) {
		return [];
	}

	return fetchFromExchangeApi(
		`${environment}/read_order/${orderId}/negotiation/${negotiationId}`,
		{
			method: 'GET',
			mapStatusToResult: map404ToEmptyList,
		}
	);
}

export const fetchOrder = ({ orderId, environment, negotiationId }) => {
	const url = negotiationId
		? `${environment}/read_order/${orderId}?first_counter_id=${negotiationId}`
		: `${environment}/read_order/${orderId}`;

	return fetchFromExchangeApi(url, {
		method: 'GET',
		mapStatusToResult: map404ToNull,
	});
};

export function acceptOrder({ id, environment, version, principal_id }) {
	const ordersTable = environment === Environment.OTC ? 'orders_otc' : 'orders';
	return fetchFromExchangeApi(`${environment}/process_order/${ordersTable}/${id}`, {
		method: 'POST',
		body: {},
		query: {
			status: OrderStatus.AcceptedNoCounter,
			principal_id,
			version,
		},
	});
}

export function acceptCounter({ id, environment }) {
	return fetchFromExchangeApi(`${environment}/process_order/counter_orders/${id}`, {
		method: 'POST',
		body: {},
		query: {
			status: OrderStatus.Accepted,
		},
	});
}

export function confirmCounter({ id, environment }) {
	return fetchFromExchangeApi(`${environment}/process_order/counter_orders/${id}`, {
		method: 'POST',
		body: {},
		query: {
			status: OrderStatus.Confirmed,
		},
	});
}

export const withdrawMultipleOrders = ({
	order_ids,
	withdraw_counters,
	is_indicative,
	environment,
	market,
	principal_company_ids,
}) => {
	return fetchFromExchangeApi('withdraw_orders', {
		method: 'POST',
		body: {
			query: {
				order_ids,
				market,
				is_indicative,
				environment,
				principal_company_ids,
			},
			action: {
				withdraw_counters,
			},
		},
		query: {},
	});
};

export function terminateOrder({ environment, id, shouldCloseNegotiations, version }) {
	const ordersTable = environment === Environment.OTC ? 'orders_otc' : 'orders';
	return fetchFromExchangeApi(`${environment}/process_order/${ordersTable}/${id}`, {
		method: 'POST',
		body: {},
		query: {
			status: OrderStatus.Terminated,
			leave_counters_open: !shouldCloseNegotiations,
			version,
		},
	});
}

export const listPaperOrderPresets = () => {
	return fetchFromExchangeApi(`list/paper_contract_presets`, {
		query: { include_all_fields: true, _sort: 'sort_order' },
		method: 'GET',
		mapStatusToResult: map404ToEmptyList,
	});
};

export const listGroupedPaperOrders = async ({
	market,
	instrument,
	products,
	order,
	myApprovedContacts,
	...query
}) => {
	const body = {
		market,
		instrument,
		products,
		order,
	};

	if (myApprovedContacts) {
		query.my_approved_contacts = true;
	}

	const result = await fetchFromExchangeApi('list_grouped_orders_bids_offers_v2', {
		method: 'POST',
		body,
		query,
		mapStatusToResult: map404ToEmptyObject,
	});

	return {
		items: result.data.map(paperOrder => {
			const group = paperOrderMapper(paperOrder);
			const groupKey = createGroupKey(group, query.expired);

			return { _key: groupKey, ...group };
		}),
		cursor: result.meta?.next_page_cursor || '',
	};
};

export const getCounterparties = async () => {
	const query = {
		_sort: 'name',
		_order: sortOrder.asc,
		_limit: 1000,
	};
	const contactsQuery = { ...query, exclude_my_company: true, otc_nominable: true };
	const groupsQuery = { ...query, otc_nominable: true };
	const [contacts, groups] = await Promise.all([
		getMyContacts(contactsQuery),
		getGroups(groupsQuery),
	]);

	return {
		contacts,
		groups,
	};
};

const createGroupKey = (group, expired) => {
	return [
		group.instrument,
		group.product_id,
		group.inco_id,
		group.futures_contract,
		group.futures_contract_date,
		group.delivery_date_from,
		group.delivery_date_to,
		group.primary_port_id,
		group.spread_details?.delivery_date_from,
		group.spread_details?.futures_contract_date,
		expired ? 'closed' : 'open',
	]
		.filter(Boolean)
		.join(':');
};

export const isMyContactOrders = async ({ environment, order_id = [] }) => {
	return fetchFromExchangeApi(`${environment}/is_my_contact_orders`, {
		query: { order_id },
		method: 'GET',
		mapStatusToResult: map404ToEmptyList,
	});
};

export const getMyLastOrder = async ({ market }) => {
	return fetchFromExchangeApi('list_my_orders_v2', {
		query: {
			_limit: 1,
			_sort: 'created_at',
			_order: 'DESC',
			only_active: false,
			market,
		},
		method: 'GET',
		mapStatusToResult: map404ToEmptyObject,
	});
};

export const getNegotiationCounters = (environment, orderId, firstCounterId) => {
	if (!orderId || !firstCounterId) {
		return [];
	}

	return fetchFromExchangeApi(
		`${environment}/read_order/${orderId}/negotiation/${firstCounterId}`,
		{
			method: 'GET',
			mapStatusToResult: map404ToEmptyList,
		}
	);
};

export const editMultipleOrders = orders => {
	return fetchFromExchangeApi('edit_orders', {
		method: 'PATCH',
		body: {
			orders,
		},
		signal: Timeout(60).signal,
	});
};

export const listOrderBrokerContacts = (environment, orderId, query) => {
	return fetchFromExchangeApi(`${environment}/read_order/${orderId}/list_broker_contacts`, {
		query: {
			...query,
			exclude_deactivated: true,
		},
		mapStatusToResult: map404ToEmptyObject,
	});
};
