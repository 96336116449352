/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import { PaperInstruments, tPaperInstrument } from 'src/constants/contract';
import { useWatchPhysicalCounterFields } from 'src/components/ViewOrderDrawer/CounterForm/helpers/useWatchPhysicalCounterFields';

export const useProductCounterFields = () => {
	const { t } = useTranslation();
	const paperPresets = useAllPaperPresets();

	const { presetIDValue } = useWatchPhysicalCounterFields();

	const productsPresets = useMemo(
		() =>
			paperPresets.map(preset => {
				return {
					text: preset.name,
					value: preset._key,
				};
			}),
		[paperPresets]
	);

	const instrumentOptions = Object.values(PaperInstruments).map(instrument => ({
		text: tPaperInstrument(t, instrument),
		key: instrument,
		value: instrument,
	}));

	const selectedProductPreset = useMemo(() => {
		return paperPresets.find(preset => preset._key === presetIDValue);
	}, [paperPresets, presetIDValue]);

	const terms = selectedProductPreset
		? {
				name: selectedProductPreset.terms.name,
				url: selectedProductPreset.terms.original_link,
		  }
		: null;

	return {
		productsPresets: {
			options: productsPresets,
			counterable: false,
		},
		instruments: { options: instrumentOptions, counterable: false },
		terms,
	};
};
