/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { media } from 'src/_helpers';
import { SectionWrapper } from '../../../components/FoldableSection/styled';

export const ProductSectionWrapper = styled(SectionWrapper)`
	.select-input {
		max-width: 308px;

		${media.ll`
			max-width: inherit;
		`}
	}
`;
