/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';
import { H200, h400 } from 'src/components/Typography';

export const ContractLinkWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
`;

export const ContractLabel = styled(H200)`
	color: var(--neutral-200);
`;

export const ContractLink = styled.a`
	${h400}
	max-width: fit-content;
	color: var(--primary-100);

	&:hover {
		color: var(--primary-100);
		text-decoration: underline;
	}

	svg {
		position: relative;
		top: 1px;
		left: 3px;
	}
`;
