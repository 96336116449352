/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { terminateOrder } from 'src/_api';
import { useToast } from 'src/components/Toast';
import { useTogglable } from 'src/_helpers/useTogglable';
import { Modal } from 'src/components/Modal';
import { ModalSize, ModalType } from 'src/constants/modal';
import { tOrderType } from 'src/constants/contract';
import { useHighlightItemContext } from 'src/containers/HighlightItemProvider/HighlightItemProvider';
import { WithdrawOrderModalContent } from './WithdrawOrderModalContent';

export const useTerminateOrderMutation = (props = {}, order) => {
	const { t } = useTranslation();
	const { addToast } = useToast();
	const queryClient = useQueryClient();

	return useMutation(terminateOrder, {
		...props,
		onSuccess: async (result, variables) => {
			await queryClient.invalidateQueries(['order', variables.id]);
			props.onSuccess?.(result, variables);
		},
		onError: err => {
			props.onError?.(err);
			addToast({
				message: t('terminate_{{order_type}}_failed', {
					order_type: tOrderType(t, order.order_type).toLowerCase(),
				}),
				kind: 'error',
			});
		},
	});
};

export const WithdrawOrderModal = ({
	orderId,
	orderType,
	orderVersion,
	hasActiveNegotiations,
	checkActiveNegotiations, // if set to true, the component will try to determine if negotiations exist
	environment,
	instrument,
	market,
	trigger,
}) => {
	const [isOpen, { open, close }] = useTogglable();
	const [shouldCloseNegotiations, setShouldCloseNegotiations] = useState(false);
	const queryClient = useQueryClient();

	const { highlightItem } = useHighlightItemContext();

	const { mutate, isLoading } = useTerminateOrderMutation({
		onSuccess: async () => {
			await queryClient.invalidateQueries(['physical-orders']);

			await queryClient.invalidateQueries(['my-orders']);

			close();
		},
	});

	const withdrawOrder = () => {
		mutate({
			id: orderId,
			environment,
			shouldCloseNegotiations: JSON.parse(shouldCloseNegotiations),
			version: orderVersion,
		});
	};

	const handleOpen = () => {
		open();
		highlightItem(orderId);
	};

	const handleClose = () => {
		close();
		highlightItem(null);
	};

	return (
		<Modal
			data-test="terminate-order"
			size={ModalSize.Tiny}
			type={ModalType.Warning}
			open={handleOpen}
			close={handleClose}
			isOpen={isOpen}
			trigger={trigger}
		>
			<WithdrawOrderModalContent
				environment={environment}
				market={market}
				instrument={instrument}
				orderType={orderType}
				orderId={orderId}
				checkActiveNegotiations={checkActiveNegotiations}
				hasActiveNegotiations={hasActiveNegotiations}
				shouldCloseNegotiations={shouldCloseNegotiations}
				setShouldCloseNegotiations={setShouldCloseNegotiations}
				close={handleClose}
				isLoading={isLoading}
				withdrawOrder={withdrawOrder}
			/>
		</Modal>
	);
};
