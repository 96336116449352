/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import styled from 'styled-components';

export const Label = styled.span`
	background: var(--primary-800);
	min-width: 32px;
	height: 14px;
	border-radius: 4px;
	color: var(--white);
	font-weight: 500;
	font-size: 10px;
	line-height: 11.72px;
	letter-spacing: -0.3px;
	display: flex;
	align-items: center;
	justify-content: center;

	.table-closed & {
		background: var(--neutral-300);
		color: var(--neutral-950) !important;
	}
`;
