/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { ROUTES } from 'src/constants/routes';

export const HeaderType = {
	Default: 'default',
	MyOrder: 'my_order',
	Info: 'info',
	Success: 'success',
	Warning: 'warning',
};

export const DrawerVisibleOnPathnames = [
	ROUTES.root,
	ROUTES.teamsDashboard,
	ROUTES.orderbook,
	ROUTES.myOrders,
	ROUTES.myNegotiations,
	ROUTES.myTrades,
];
