/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useWatch } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { getNumberOfDeliveryMonths } from 'src/components/CreateOrderDrawer/paper/useWatchPaperFields';

export const useWatchPaperCounterFields = path => {
	const presetIDValue = useWatch({ name: 'presetID' });
	const orderTypeChangedValue = useWatch({ name: 'orderTypeChanged' });
	const instrumentValue = useWatch({ name: 'instrument' });
	const currencyUnitValue = useWatch({ name: toPath(path, 'currencyUnit') });
	const priceTypeValue = useWatch({ name: toPath(path, 'priceType') });
	const runsValue = useWatch({ name: toPath(path, 'runs') });
	const deliveryValue = useWatch({ name: toPath(path, 'delivery') });
	const volumeValue = useWatch({ name: toPath(path, 'volume') });
	const totalVolumeValue = useWatch({ name: toPath(path, 'totalVolume') });
	const environmentValue = useWatch({ name: toPath(path, 'environment') });
	const typeValue = useWatch({ name: toPath(path, 'type') });
	const orderTypeValue = useWatch({ name: toPath(path, 'orderType') });
	const priceValue = useWatch({ name: toPath(path, 'price') });
	const validityChangedValue = useWatch({ name: 'validityChanged' });
	const spreadTypeValue = useWatch({ name: toPath(path, 'spreadType') });

	const firstLegFuturesMonthValue = useWatch({ name: toPath(path, 'firstLegFuturesMonth') });
	const firstLegMonthValue = useWatch({ name: toPath(path, 'firstLegMonth') });
	const firstLegPriceValue = useWatch({ name: toPath(path, 'firstLegPrice') });
	const secondLegFuturesMonthValue = useWatch({ name: toPath(path, 'secondLegFuturesMonth') });
	const secondLegMonthValue = useWatch({ name: toPath(path, 'secondLegMonth') });
	const spreadMetaValue = useWatch({ name: toPath(path, 'spreadMeta') });

	const forceFirmValue = useWatch({ name: 'forceFirm' });

	const principalIdValue = useWatch({ name: toPath(path, 'principalId') });
	const principalVisibilityValue = useWatch({ name: toPath(path, 'principalVisibility') });

	const preAdviceValue = useWatch({ name: toPath(path, 'preAdvice') });
	const preAdviceDaysValue = useWatch({ name: toPath(path, 'preAdviceDays') });
	const preAdviceVesselNameValue = useWatch({ name: toPath(path, 'preAdviceVesselName') });

	return {
		presetIDValue,
		instrumentValue,
		currencyUnitValue,
		priceTypeValue,
		runsValue,
		deliveryValue,
		numberOfDeliveryMonthsValue: getNumberOfDeliveryMonths(deliveryValue),
		volumeValue,
		totalVolumeValue,
		environmentValue,
		typeValue,
		orderTypeValue,
		orderTypeChangedValue,
		priceValue,
		validityChangedValue,
		firstLegFuturesMonthValue,
		firstLegMonthValue,
		firstLegPriceValue,
		secondLegFuturesMonthValue,
		secondLegMonthValue,
		spreadMetaValue,
		forceFirmValue,
		principalIdValue,
		spreadTypeValue,
		principalVisibilityValue,
		preAdviceValue,
		preAdviceDaysValue,
		preAdviceVesselNameValue,
	};
};
