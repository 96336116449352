/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { ReactComponent as ExternalLinkIcon } from 'src/assets/icons/external_link_icon.svg';
import * as Styled from './styled';

export const Terms = ({ label, terms }) => {
	if (!terms) {
		return null;
	}

	return (
		<Styled.ContractLinkWrapper>
			<Styled.ContractLabel>{label}</Styled.ContractLabel>
			<Styled.ContractLink href={terms.original_link || terms.url} target="_blank">
				{terms.name} <ExternalLinkIcon />
			</Styled.ContractLink>
		</Styled.ContractLinkWrapper>
	);
};
