/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useQuery } from 'react-query';
import { listPaperOrderPresets } from 'src/_api/orders.api';
import { PaperPorts } from 'src/constants/contract';

const ShorthandPresetName = {
	FOB_PARANAGUA_SOYBEANS: 'FOB PPGUA SB',
	FOB_PARANAGUA_SOYBEAN_MEALS: 'FOB PPGUA SBM',
	FOB_PKPG: 'FOB PK/PG',
};

const Soybeans = 'SOYBEANS';

export const getPaperPresetName = preset => {
	const port = preset.loading_port || preset.discharging_port || preset.primary_port || {};

	if (port.name === PaperPorts.PKPG) {
		return ShorthandPresetName.FOB_PKPG;
	}

	if (port._key === PaperPorts.PARANAGUA) {
		return preset.product_id === Soybeans
			? ShorthandPresetName.FOB_PARANAGUA_SOYBEANS
			: ShorthandPresetName.FOB_PARANAGUA_SOYBEAN_MEALS;
	}

	// fallback to a regular name
	return `${preset.inco_id} ${preset.port?.name}`;
};

export const useAllPaperPresets = () => {
	const { data } = useQuery('all-paper-presets', listPaperOrderPresets, {
		staleTime: Infinity,
	});

	return (
		data?.map(preset =>
			!preset.name ? { ...preset, name: getPaperPresetName(preset) } : preset
		) || []
	);
};
