/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { H600 } from 'src/components/Typography/fonts';
import { VolumeDisplay } from 'src/components/Volume';
import { formatPriceWithoutCurrency, getCurrencyAndUnitOfOrderOrCounter } from 'src/_helpers/price';
import { parseDateStringAsUTC, formatDateRange } from 'src/_helpers/date';
import { calculate2ndLegPrice, SpreadPriceFormat } from 'src/constants/contract';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { useOrderDetails } from 'src/_helpers/useOrderDetails';
import { useSellerBuyerSummary } from 'src/_helpers/order';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import * as Styled from './styled';

export const AcceptSpreadSummary = ({
	order,
	counters = [],
	PrincipalSelectorComponent,
	selectedPrincipal,
}) => {
	const { t } = useTranslation();

	const paperPresets = useAllPaperPresets();
	const orderPreset = paperPresets.find(preset => preset._key === order.preset_id) || {};

	const { seller, buyer, areBrokersFromTheSameCompany } = useSellerBuyerSummary(order, counters);
	const lastCounter = [order, ...counters].at(-1);

	const { isBasisPrice, isSellOrder } = useOrderDetails(order);

	const shouldShowBuyerPrincipalComponent = !!PrincipalSelectorComponent && isSellOrder;
	const shouldShowSellerPrincipalComponent = !!PrincipalSelectorComponent && !isSellOrder;

	const isPayCashSpread = order.spread_details.price_format === SpreadPriceFormat.PayCash;

	const price = formatPriceWithoutCurrency(lastCounter.price);
	const spreadPrice = isPayCashSpread
		? formatPriceWithoutCurrency(Math.abs(price))
		: price > 0
		? `+${price}`
		: price;
	const firstLegPrice = lastCounter.spread_details.first_leg_price;

	const secondLegPrice = calculate2ndLegPrice(firstLegPrice, lastCounter.price);
	const currencyUnit = getCurrencyAndUnitOfOrderOrCounter(order, t);

	const hasRunsSupport = order.runs;

	const quantityLabel = hasRunsSupport ? t('total_quantity') : t('quantity');

	const singleBrokerDefined =
		(seller.broker && !buyer.broker) || (!seller.broker && buyer.broker);

	return (
		<>
			<Styled.OrderValueRow>
				<Styled.Label>{t('contract')}</Styled.Label>
				<H600>{orderPreset.name}</H600>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{quantityLabel}</Styled.Label>
				<VolumeDisplay as={H600} value={lastCounter.volume} />
			</Styled.OrderValueRow>
			{hasRunsSupport && (
				<Styled.OrderValueRow>
					<Styled.Label>{t('runs')}</Styled.Label>
					<H600>{lastCounter.runs}</H600>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('spread')}</Styled.Label>
				<H600>
					{spreadPrice} {currencyUnit}
				</H600>
			</Styled.OrderValueRow>
			<Styled.SectionName>{t('first_leg')}</Styled.SectionName>
			<Styled.OrderValueRow>
				<Styled.Label>{t('seller')}</Styled.Label>
				{shouldShowSellerPrincipalComponent ? (
					PrincipalSelectorComponent
				) : (
					<Styled.OverflowedValue>
						<OverflowText>{seller.principal}</OverflowText>
					</Styled.OverflowedValue>
				)}
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('buyer')}</Styled.Label>
				{shouldShowBuyerPrincipalComponent ? (
					PrincipalSelectorComponent
				) : (
					<Styled.OverflowedValue>
						<OverflowText>{buyer.principal}</OverflowText>
					</Styled.OverflowedValue>
				)}
			</Styled.OrderValueRow>
			{areBrokersFromTheSameCompany ? (
				<Styled.OrderValueRow>
					<Styled.Label>{t('broker')}</Styled.Label>
					<Styled.OverflowedValue>
						<OverflowText>{seller.broker || buyer.broker}</OverflowText>
					</Styled.OverflowedValue>
				</Styled.OrderValueRow>
			) : (
				<>
					{seller.broker && (
						<Styled.OrderValueRow>
							<Styled.Label>{seller.broker_label}</Styled.Label>
							<Styled.OverflowedValue>
								<OverflowText>{seller.broker}</OverflowText>
							</Styled.OverflowedValue>
						</Styled.OrderValueRow>
					)}
					{buyer.broker && (
						<Styled.OrderValueRow>
							<Styled.Label>{buyer.broker_label}</Styled.Label>
							<Styled.OverflowedValue>
								<OverflowText>{buyer.broker}</OverflowText>
							</Styled.OverflowedValue>
						</Styled.OrderValueRow>
					)}
				</>
			)}

			<Styled.OrderValueRow>
				<Styled.Label>{t('shipment')}</Styled.Label>
				<H600>
					{formatDateRange({
						format: order.delivery_mode,
						startDate: parseDateStringAsUTC(order.delivery_date_from),
						endDate: parseDateStringAsUTC(order.delivery_date_to),
					})}
				</H600>
			</Styled.OrderValueRow>
			{isBasisPrice && (
				<Styled.OrderValueRow>
					<Styled.Label>{t('futures_contract')}</Styled.Label>
					<H600>{`${order.futures_contract} ${moment(order.futures_contract_date).format(
						'MMM YYYY'
					)}`}</H600>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('price')}</Styled.Label>
				<H600>
					{formatPriceWithoutCurrency(firstLegPrice)} {currencyUnit}
				</H600>
			</Styled.OrderValueRow>
			<Styled.SectionName>{t('second_leg')}</Styled.SectionName>
			<Styled.OrderValueRow>
				<Styled.Label>{t('seller')}</Styled.Label>
				<Styled.OverflowedValue>
					<OverflowText>
						{shouldShowBuyerPrincipalComponent
							? selectedPrincipal?.text || '---'
							: buyer.principal}
					</OverflowText>
				</Styled.OverflowedValue>
			</Styled.OrderValueRow>
			<Styled.OrderValueRow>
				<Styled.Label>{t('buyer')}</Styled.Label>
				<Styled.OverflowedValue>
					<OverflowText>
						{shouldShowSellerPrincipalComponent
							? selectedPrincipal?.text || '---'
							: seller.principal}
					</OverflowText>
				</Styled.OverflowedValue>
			</Styled.OrderValueRow>
			{areBrokersFromTheSameCompany || singleBrokerDefined ? (
				<Styled.OrderValueRow>
					<Styled.Label>{t('broker')}</Styled.Label>
					<Styled.OverflowedValue>
						<OverflowText>{buyer.broker || seller.broker}</OverflowText>
					</Styled.OverflowedValue>
				</Styled.OrderValueRow>
			) : (
				<>
					{buyer.broker && (
						<Styled.OrderValueRow>
							<Styled.Label>{`${t('broker')} (${t('seller')})`}</Styled.Label>
							<Styled.OverflowedValue>
								<OverflowText>{buyer.broker}</OverflowText>
							</Styled.OverflowedValue>
						</Styled.OrderValueRow>
					)}
					{seller.broker && (
						<Styled.OrderValueRow>
							<Styled.Label>{`${t('broker')} (${t('buyer')})`}</Styled.Label>
							<Styled.OverflowedValue>
								<OverflowText>{seller.broker}</OverflowText>
							</Styled.OverflowedValue>
						</Styled.OrderValueRow>
					)}
				</>
			)}

			<Styled.OrderValueRow>
				<Styled.Label>{t('shipment')}</Styled.Label>
				<H600>
					{formatDateRange({
						format: order.spread_details.delivery_mode,
						startDate: parseDateStringAsUTC(order.spread_details.delivery_date_from),
						endDate: parseDateStringAsUTC(order.spread_details.delivery_date_to),
					})}
				</H600>
			</Styled.OrderValueRow>
			{isBasisPrice && (
				<Styled.OrderValueRow>
					<Styled.Label>{t('futures_contract')}</Styled.Label>
					<H600>{`${order.futures_contract} ${moment(
						order.spread_details.futures_contract_date
					).format('MMM YYYY')}`}</H600>
				</Styled.OrderValueRow>
			)}
			<Styled.OrderValueRow>
				<Styled.Label>{t('price')}</Styled.Label>
				<H600>
					{formatPriceWithoutCurrency(secondLegPrice)} {currencyUnit}
				</H600>
			</Styled.OrderValueRow>
		</>
	);
};
