/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { TradePosition } from './tradebook';
import { isNil } from 'src/_helpers';

export const Role = {
	Principal: 'principal',
	Broker: 'broker',
};

export const ContractType = {
	ORDER: 'ORDER',
	COUNTER: 'COUNTER',
};

// IN CHINESE INDICATIVE HAS A DIFFERENT TRANSLATION IF IS FOR COUNTERS OR ORDERS
const QuoteIndicativeTypeLabel = {
	ORDER: 'quote_indicative',
	COUNTER: 'quote_indicative_counter',
};

export function tRole(t, value) {
	switch (value) {
		case Role.Principal:
			return t('principal');
		case Role.Broker:
			return t('broker');
		default:
			return '';
	}
}

export const NameVisibility = {
	Visible: 'visible',
	Hidden: 'hidden',
};

export const tNameVisibility = (t, value) => {
	switch (value) {
		case NameVisibility.Hidden:
			return t('name_visibility_hidden');
		case NameVisibility.Visible:
			return t('name_visibility_visible');
		default:
			return '';
	}
};

export const DrawerPriceType = {
	Flat: 'flat',
	NoPrice: 'no_price',
	MATIF: 'MATIF',
	CBOT: 'CBOT',
	MGEX: 'MGEX',
	KCBOT: 'KCBOT',
};

export const isBasisPrice = value =>
	[
		DrawerPriceType.CBOT,
		DrawerPriceType.MATIF,
		DrawerPriceType.KCBOT,
		DrawerPriceType.MGEX,
	].includes(value);

export const tDrawerPriceType = (t, value) => {
	switch (value) {
		case DrawerPriceType.Flat:
			return t('flat');
		case DrawerPriceType.NoPrice:
			return t('price_indication_no_price');
		default:
			return value;
	}
};

export const PriceType = {
	Flat: 'flat',
	Basis: 'basis',
};

export const Currency = {
	USD: 'USD',
	EUR: 'EUR',
};

export const tCurrency = (t, currency) => {
	switch (currency) {
		case Currency.USD:
			return t('usd');
		case Currency.EUR:
			return t('eur');
		default:
			return t('usd');
	}
};

export function tPriceType(t, value) {
	switch (value) {
		case PriceType.Flat:
			return t('flat');
		case PriceType.Basis:
			return t('basis');
		default:
			return '';
	}
}

export const PortType = {
	LOADING: 'loading',
	DISCHARGE: 'discharge',
};

export const IncoType = {
	CFR: 'CFR',
	CIF: 'CIF',
	FAS: 'FAS',
	FOB: 'FOB',
	RENDU: 'RENDU',
};

export const isShipmentInco = inco => [IncoType.CFR, IncoType.CIF].includes(inco);

export const isBuyerOptionTolerance = inco =>
	[IncoType.FOB, IncoType.FAS, IncoType.RENDU].includes(inco);

export const tToleranceKey = inco => {
	if (isBuyerOptionTolerance(inco)) {
		return 'tolerance_buyers_option';
	} else {
		return 'tolerance_sellers_option';
	}
};

export const tToleranceLabel = (t, inco) => t(tToleranceKey(inco));

export const PriceIncrementType = {
	None: 'none',
	HalfMonthly: 'half_monthly',
	Monthly: 'monthly',
};

export const tPriceIncrement = (t, value) => {
	switch (value) {
		case PriceIncrementType.None:
			return t('price_increment_none');
		case PriceIncrementType.HalfMonthly:
			return t('half_monthly');
		case PriceIncrementType.Monthly:
			return t('monthly');
		default:
			return '';
	}
};

export const Payment = {
	CashOnDocuments: 'CAD',
	TelegraphicTransfer: 'TT',
	DocumentsAgainstPayment: 'D/P',
	LetterOfCredit: 'L/C',
};

export function tPayment(t, value) {
	switch (value) {
		case Payment.CashOnDocuments:
			return t('cash_against_documents');
		case Payment.TelegraphicTransfer:
			return t('telegraphic_transfer');
		case Payment.LetterOfCredit:
			return t('letter_of_credit');
		case Payment.DocumentsAgainstPayment:
			return t('documents_against_payment');
		default:
			return '';
	}
}

export function tPaymentTooltip(t, value) {
	switch (value) {
		case Payment.CashOnDocuments:
			return t('cash_against_documents_tooltip');
		case Payment.TelegraphicTransfer:
			return t('telegraphic_transfer_tooltip');
		case Payment.LetterOfCredit:
			return t('letter_of_credit_tooltip');
		case Payment.DocumentsAgainstPayment:
			return t('documents_against_payment_tooltip');
		default:
			return '';
	}
}

export const Inspection = {
	Buyer: 'buyer',
	Seller: 'seller',
};

export const tInspection = (t, value, fullText = true) => {
	switch (value) {
		case Inspection.Buyer:
			return fullText ? t('buyers_option') : t('buyers_option_short');
		case Inspection.Seller:
			return fullText ? t('sellers_option') : t('sellers_option_short');
		default:
			return '';
	}
};

export const CargoType = {
	Bulk: 'bulk',
	Container20: 'container_20',
	Container40: 'container_40',
	ContainerOther: 'container_other',
};

export const tCargoType = (t, value) => {
	switch (value) {
		case CargoType.Bulk:
			return t('cargo_type_bulk');
		case CargoType.Container20:
			return t('cargo_type_container_20');
		case CargoType.Container40:
			return t('cargo_type_container_40');
		case CargoType.ContainerOther:
			return t('cargo_type_container_other');
		default:
			return '';
	}
};

export const CargoTypeGroup = {
	Bulk: 'bulk',
	Container: 'container',
};

export const tCargoTypeGroup = (t, value) => {
	switch (value) {
		case CargoTypeGroup.Bulk:
			return t('cargo_type_bulk');
		case CargoTypeGroup.Container:
			return t('cargo_type_container');
		default:
			return '';
	}
};

export const OrderType = {
	Buy: 'buy',
	Sell: 'sell',
};

export const tOrderType = (t, value, instrument, defaultSpreadKey = 'spread') => {
	if (instrument === PaperInstruments.Spread) {
		return t(defaultSpreadKey);
	}
	switch (value) {
		case OrderType.Buy:
			return t('bid');
		case OrderType.Sell:
			return t('offer');
		default:
			return '';
	}
};

export const tLastCounterType = (t, order, lastCounter, instrument) => {
	if (instrument === PaperInstruments.Spread) {
		return t('spread');
	}

	if (lastCounter.counter_order_user_id === order.user_id) {
		return tOrderType(t, order.order_type);
	}

	return tOrderType(t, order.order_type === OrderType.Buy ? OrderType.Sell : OrderType.Buy);
};

export function tConfirmCounterOffer(t, orderType, isOwnOffer) {
	if (
		(orderType === OrderType.Sell && isOwnOffer) ||
		(orderType === OrderType.Buy && !isOwnOffer)
	) {
		return t('confirm_your_offer');
	}

	return t('confirm_your_bid');
}

export function tNoPriceOrderType(t, value) {
	switch (value) {
		case OrderType.Buy:
			return t('bid_no_price');
		case OrderType.Sell:
			return t('offer_no_price');
		default:
			return '';
	}
}

export const QuoteType = {
	Firm: 'firm',
	Indicative: 'indicative',
};

export function tQuoteValueType(t, value, type = ContractType.ORDER) {
	switch (value) {
		case QuoteType.Firm:
			return t('quote_firm');
		case QuoteType.Indicative:
			return t(QuoteIndicativeTypeLabel[type]);
		default:
			return '';
	}
}

export const PriceIndication = {
	Price: 'price',
	NoPrice: 'no_price',
};

export const tPriceIndication = (t, value) => {
	if (value === PriceIndication.Price) {
		return t('price_indication_price');
	}

	return t('price_indication_no_price');
};

export const tMultiSubmitButton = (t, value, count) => {
	if (count === 1) {
		return tSubmitButton(t, value);
	}

	switch (value) {
		case OrderType.Buy:
			return t('submit_{{count}}_bids', { count });
		case OrderType.Sell:
			return t('submit_{{count}}_offers', { count });
		default:
			return t('submit_{{count}}_orders', { count });
	}
};

export function tSubmitButton(t, value) {
	switch (value) {
		case OrderType.Buy:
			return t('submit_bid');
		case OrderType.Sell:
			return t('submit_offer');
		default:
			return t('submit_order');
	}
}

export const Environment = {
	Exchange: 'exchange',
	OTC: 'otc',
};

export const Market = {
	Physical: 'physical',
	Paper: 'paper',
};

export const tEnvironment = (t, value) => {
	switch (value) {
		case Environment.Exchange:
			return t('exchange');
		case Environment.OTC:
			return t('otc');
		default:
			return '';
	}
};

export const tEnvironmentAlternative = (t, value) => {
	switch (value) {
		case Environment.Exchange:
			return t('exchange');
		case Environment.OTC:
			return t('otc_direct');
		default:
			return '';
	}
};

export function tMarket(t, value) {
	switch (value) {
		case Market.Physical:
			return t('physical_market');
		case Market.Paper:
			return t('paper_market');
		default:
			return '';
	}
}

export const tBoolean = (t, value) => {
	return value ? t('yes') : t('no');
};

export const tQuoteType = (t, is_indicative, type = ContractType.ORDER) => {
	return is_indicative ? t(QuoteIndicativeTypeLabel[type]) : t('quote_firm');
};

export const paperVolume = [60000, 63000, 66000, 70000];

export const portsIncos = {
	loadingPorts: [IncoType.FAS, IncoType.FOB, IncoType.RENDU],
	dischargePorts: [IncoType.CIF, IncoType.CFR],
};

export const CUSTOM_CONTRACT_TERMS = 'custom';

export const BidOfferDetailsHeader = {
	[Market.Paper]: {
		[OrderType.Buy]: 'paper_bid_details',
		[OrderType.Sell]: 'paper_offer_details',
	},
	[Market.Physical]: {
		[OrderType.Buy]: 'bid_details',
		[OrderType.Sell]: 'offer_details',
	},
};

export const BidOfferHeader = {
	[Market.Paper]: {
		[OrderType.Buy]: 'paper_bid',
		[OrderType.Sell]: 'paper_offer',
	},
	[Market.Physical]: {
		[OrderType.Buy]: 'bid',
		[OrderType.Sell]: 'offer',
	},
};

export const CompanyHeader = {
	[OrderType.Buy]: 'buyer',
	[OrderType.Sell]: 'seller',
};

export const CounterpartyHeader = {
	[OrderType.Buy]: 'seller',
	[OrderType.Sell]: 'buyer',
};

export const tContractNumber = (t, name, number) => {
	if (!name) {
		return '---';
	}

	if (!number) {
		return name;
	}

	return t('contract_no', { name, number });
};

export const PriceUnit = {
	Bushel: 'bushel',
	MetricTon: 'metric_ton',
};

export const ContractMonthCodes = ['F', 'G', 'H', 'J', 'K', 'M', 'N', 'Q', 'U', 'V', 'X', 'Z'];

export const PaperPorts = {
	PARANAGUA: 'BRPNG',
	PKPG: 'PK/PG',
};

export const defaultCurrencyUnit = `${Currency.USD}/${PriceUnit.MetricTon}`;

export const PerformanceBondOptions = [5, 10, 15, 20].map(value => ({ text: `${value}%`, value }));

export const PaperInstruments = {
	Outright: 'outright',
	Spread: 'calendar_spread',
};

export const PreAdvice = {
	Standard: 'standard',
	Short: 'short',
	ToSuitVessel: 'to_suit_vessel',
};

export const ShortPreAdviceRange = [1, 14];

export const tPaperInstrument = (t, value) => {
	switch (value) {
		case PaperInstruments.Outright:
			return t('outright');
		case PaperInstruments.Spread:
			return t('calendar_spread');
		default:
			return '';
	}
};

export const tLegQuote = (t, value) => {
	switch (value) {
		case OrderType.Buy:
			return t('buy');
		case OrderType.Sell:
			return t('sell');
		default:
			return '';
	}
};

export const positionToOrderType = type => {
	switch (type) {
		case TradePosition.Short:
			return OrderType.Sell;
		case TradePosition.Long:
			return OrderType.Buy;
		default:
			return '';
	}
};

export const CalendarSpreadPayment = {
	Cash: 'cash',
	Even: 'even',
	Pay: 'pay',
};

export const tCalendarSpreadPayment = (t, value) => {
	switch (value) {
		case CalendarSpreadPayment.Cash:
			return t('cash');
		case CalendarSpreadPayment.Pay:
			return t('pay');
		case CalendarSpreadPayment.Even:
			return t('even');
		default:
			return '';
	}
};

export const calculate2ndLegPrice = (firstLegPrice, spreadPrice) => {
	if (isNil(firstLegPrice) || isNil(spreadPrice)) {
		return;
	}

	return firstLegPrice - spreadPrice;
};

export const LegType = {
	First: 'first_leg',
	Second: 'second_leg',
};

export const SpreadPriceFormat = {
	Spread: 'spread',
	PayCash: 'pay_cash',
};
