/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { useToast } from 'src/components/Toast';
import { useTermsAndConditions } from 'src/_api/queries';
import { acceptTermsAndConditions } from 'src/_api/settings.api';
import { TermsAndConditionsModal } from './TermsAndConditionsModal';
import * as Styled from './styled';

export const TermsAndConditions = () => {
	const { t } = useTranslation();
	const { addToast } = useToast();

	const { mutate, isLoading: isAccepting } = useMutation(acceptTermsAndConditions, {
		onError: () => {
			addToast({
				message: t('terms_accept_failed'),
				kind: 'error',
			});
		},
		onSuccess: () => {
			window.location.reload();
		},
	});

	const { data: termsAndConditions } = useTermsAndConditions();

	const handleAccept = () => {
		mutate({
			version: termsAndConditions.region
				? termsAndConditions._key
				: termsAndConditions.version,
		});
	};

	return (
		termsAndConditions && (
			<Styled.TCWrapper>
				<Styled.HeaderNavigationPlaceholder>
					<Styled.LeftNavigationPlaceholder />
					<Styled.RightNavigationPlaceholder />
				</Styled.HeaderNavigationPlaceholder>
				<TermsAndConditionsModal
					terms={termsAndConditions.text}
					onAccept={handleAccept}
					isAccepting={isAccepting}
					pdfLink={termsAndConditions.pdf_link}
				/>
			</Styled.TCWrapper>
		)
	);
};
