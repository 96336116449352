/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { H600 } from 'src/components/Typography/fonts';
import { useAcceptOutrightSummary } from './useAcceptOutrightSummary';
import * as Styled from './styled';

export const AcceptOutrightSummary = ({ order, PrincipalSelectorComponent }) => {
	const data = useAcceptOutrightSummary(order, PrincipalSelectorComponent);

	return (
		<>
			{data.map(({ label, value, data_test }, index) => {
				return (
					<Styled.OrderValueRow key={index} data-test={data_test}>
						<Styled.Label>{label}</Styled.Label>
						<H600>{value}</H600>
					</Styled.OrderValueRow>
				);
			})}
		</>
	);
};
