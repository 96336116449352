/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Divider } from 'semantic-ui-react';
import { CounterpartiesSection } from './CounterpartiesSection/CounterpartiesSection';
import { ContractSection } from './ContractSection/ContractSection';
import { AdditionalInfoSection } from './AdditionalInfoSection/AdditionalInfoSection';
import { SpecSection } from './SpecSection/SpecSection';
import { CommentsSection } from '../../sections/CommentsSection/CommentsSection';
import { useTradeDataContext } from 'src/containers/ExecutionDetails/Paper/TradeDataContext';
import * as Styled from './styledTradeInfoComponents';

export const PaperTradeInfo = () => {
	const {
		tradeData: {
			counterparties_seller_info,
			counterparties_buyer_info,
			spec,
			additional_information,
			negotiation_comments,
			order_user_id,
			market,
			contract,
			buyer_broker_info,
			seller_broker_info,
		},
		tradeId,
		tradeStep,
	} = useTradeDataContext();

	return (
		<Styled.TradeInfoWrapper data-test="trade-details-info-component">
			<CounterpartiesSection
				sellerInfo={counterparties_seller_info}
				buyerInfo={counterparties_buyer_info}
				sellerBrokerInfo={seller_broker_info}
				buyerBrokerInfo={buyer_broker_info}
				tradeId={tradeId}
				tradeStep={tradeStep}
				market={market}
			/>
			<Divider />
			<ContractSection contractInfo={contract} />
			<Divider />
			<SpecSection specInfo={spec.specs} />
			<Divider />
			<AdditionalInfoSection info={additional_information} />
			{negotiation_comments && (
				<>
					<Divider />
					<CommentsSection comments={negotiation_comments} orderOwnerId={order_user_id} />
				</>
			)}
		</Styled.TradeInfoWrapper>
	);
};
