/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { createSelector } from 'reselect';
import { isNotNil } from 'src/_helpers';
import { getCargoTypeFilterValues } from 'src/_helpers/getCargoTypeFilterValues';
import { Market } from 'src/constants/contract';
import { FilterType } from 'src/containers/TeamsDashboard/constants';

const stateSelector = state => state.search;

export const searchSelector = createSelector(stateSelector, state => {
	return {
		...state,
		...state[state.market],
	};
});
export const searchVisibleGradeId = createSelector(searchSelector, search => search.visibleGradeId);
export const searchVisibleProductId = createSelector(
	searchSelector,
	search => search.visibleProductId
);
export const searchVisibleProductName = createSelector(
	searchSelector,
	search => search.visibleProductName
);
export const searchProducts = createSelector(searchSelector, search => search.products);
export const searchVisibleProduct = createSelector(searchSelector, search =>
	search.products.find(x => x._key === search.visibleProductId)
);
export const searchOrderType = createSelector(searchSelector, search => search.orderType);
export const searchProductsKeys = createSelector(searchSelector, search =>
	search.products.map(p => p._key)
);
export const searchVisibleProducts = createSelector(searchSelector, search => search.products);

export const searchInstrument = createSelector(searchSelector, search => search.instrument);

// Returns the data of the grade and the gradespecs to be displayed
// but not the value set by the user (if custom)
// for the product displayed in menu
export const searchVisibleGrade = createSelector(searchSelector, search => search.visibleGrade);

// Returns only the spec '_key' and the 'value' set by the user (if custom)
// for the product displayed in menu
export const searchVisibleGradeSpecs = createSelector(searchVisibleProduct, product => {
	return product?.grades?.map(grade => grade.specs).flat() || [];
});

export const searchProductSelectionCount = createSelector(searchProducts, products => {
	return products.reduce((sum, product) => {
		const selfCount = 1;

		return (
			sum +
			selfCount +
			product.origins.length +
			product.specialities.length +
			product.harvestYears.length +
			product.grades.length +
			product.grades.reduce((state, { specs }) => state + specs.length, 0)
		);
	}, 0);
});

export const searchVisibleProductKeys = createSelector(searchVisibleProducts, products =>
	products.map(x => x._key)
);

export const searchOriginKeys = createSelector(searchVisibleProduct, product => {
	return product?.origins || [];
});
export const searchHarvestYearsKeys = createSelector(searchVisibleProduct, product => {
	return product?.harvestYears || [];
});
export const searchGradesKeys = createSelector(searchVisibleProduct, product => {
	return (product?.grades || []).map(x => x._key);
});
export const searchSpecialitiesKeys = createSelector(searchVisibleProduct, product => {
	return product?.specialities || [];
});
export const searchGradeSpecValues = createSelector(searchVisibleProduct, product => {
	return product?.specialities || [];
});

export const searchEnvironment = createSelector(searchSelector, search => search.environment);
export const searchInco = createSelector(searchSelector, search => search.inco);
export const searchPreset = createSelector(searchSelector, search => search.preset || []);
export const searchPriceType = createSelector(searchSelector, search => search.priceType);
export const searchShipmentType = createSelector(searchSelector, search => search.shipmentType);
export const searchPayment = createSelector(searchSelector, search => search.payment);
export const searchRole = createSelector(searchSelector, search => search.role);
export const searchVolumeRange = createSelector(searchSelector, search => ({
	volumeMin: search.volumeMin,
	volumeMax: search.volumeMax,
}));
export const searchVolume = createSelector(searchSelector, search => search.volume || []);
export const searchLocationPorts = createSelector(searchSelector, search => search.locationPorts);
export const searchLocationCountryRegions = createSelector(
	searchSelector,
	search => search.location
);

export const searchLocationCountryRegionsCount = createSelector(
	searchSelector,
	search => search.location.countries.length + search.location.regions.length
);

export const searchShipment = createSelector(searchSelector, search => search.shipment);

export const searchQueryProducts = createSelector(searchSelector, search => {
	// Active Type not used
	// search.type is not needed for perform the search call
	// search.products array {_key, type_id, name, count}

	/*
	Object example:
	{
		"products": [
			{
				"product_id": "188",
				"harvest_years": [2021, 2022, 2023],
				"origin_country_ids": ["PL", "BR"],
				"specialities": ["organic"],
				"grades": [
					{
						"grade_id": "5",
						"custom_grade_spec": [{
							"spec_id": "13",
							"max_value": 4.0
						}, {
							"spec_id": "12",
							"min_value": 1.0
						}]
					}
				]
			},
			{
				"product_id": "1888",
				"harvest_years": [2021, 2022, 2023],
				"origin_country_ids": ["ES"],
				"specialities": ["organic"]
			}
		]
	}
	*/
	return search.products.length === 0 && search.orderType
		? [
				{
					product_id: null,
					order_type: search.orderType,
				},
		  ]
		: search.products.map(p => ({
				product_id: p._key,
				origin_country_ids: p.origins,
				specialities: p.specialities,
				harvest_years: p.harvestYears,
				grades: p.grades.map(grade => ({
					grade_id: grade._key,
					custom_grade_spec: grade.specs.map(spec => ({
						spec_id: spec._key,
						...(isNotNil(spec.min) && { min_value: spec.min }),
						...(isNotNil(spec.max) && { max_value: spec.max }),
					})),
				})),
		  }));
});

export const searchQueryOrder = createSelector(searchSelector, search => {
	return {
		order_type: search.orderType,
		shipment_type: getCargoTypeFilterValues(search.shipmentType),
		inco: search.inco,
		...(search.market === Market.Paper
			? { paper_contract_preset_ids: search.paper.preset }
			: undefined),
		price_type: search.priceType,
		payment: search.payment,
		role: search.role,
		max_volume: search.volumeMax,
		min_volume: search.volumeMin,
		volumes: search.volume,
		delivery_date_from: search.shipment?.startDate,
		delivery_date_to: search.shipment?.endDate,
		primary_ports_ids: search.locationPorts.map(x => x._key),
		primary_ports_country_ids: search.location.countries.map(c => c._key),
		primary_ports_region_ids: search.location.regions.map(r => r._key),
	};
});

export const searchSelectionObject = createSelector(
	searchSelector,
	searchQueryProducts,
	searchQueryOrder,
	(search, products, order) => {
		return {
			environment: search.environment,
			closed: search.closed,
			market: search.market,
			instrument: search.instrument || FilterType.All,
			myApprovedContacts: search.myApprovedContacts,
			products,
			order,
		};
	}
);

export const searchClosed = createSelector(searchSelector, search => search.closed);

export const searchMyApprovedContacts = createSelector(
	searchSelector,
	search => search.myApprovedContacts
);

export const searchSelectedElementsCount = createSelector(searchSelector, search => {
	return (
		search.inco?.length +
		search.priceType?.length +
		search.payment?.length +
		search.role?.length +
		search.shipmentType?.length +
		(search.volumeMin !== null) +
		(search?.volumeMax !== null)
	);
});
