/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { Controller } from 'react-hook-form';
import { toPath } from 'src/_helpers';
import { MaterialNumberInput } from 'src/designSystem/Form/MaterialInput/MaterialNumberInput';
import { usePaperOrderContext } from 'src/components/CreateOrderDrawer/paper/CreatePaperOrderDrawerContent';
import { SpreadPriceFormat, tCurrency, PaperInstruments } from 'src/constants/contract';
import { useCalendarSpreadSection } from '../useCalendarSpreadSection';
import { getPresetCurrencyAndUnitOrDefault } from 'src/_helpers/currency';
import * as Styled from './styled';

const SpreadPriceField = ({ path, t }) => {
	const { selectedPreset: selectedProductPresetValue } = usePaperOrderContext();
	const { price } = useCalendarSpreadSection({ path });

	const selectedPresetSpreadPriceFormat = selectedProductPresetValue.instruments.find(
		i => i.type === PaperInstruments.Spread
	).spread_price_format;

	const { currency, unit } = getPresetCurrencyAndUnitOrDefault(selectedProductPresetValue, t);

	const allowNegativePrice = selectedPresetSpreadPriceFormat === SpreadPriceFormat.Spread;
	const decimalScale = selectedPresetSpreadPriceFormat === SpreadPriceFormat.Spread ? 2 : 3;

	return (
		<Controller
			name={toPath(path, 'price')}
			mandatory
			render={({ field, fieldState: { error } }) => (
				<Styled.SpreadPriceContainer>
					<MaterialNumberInput
						error={!!error}
						data-test="priceCurrencyUnit"
						label={t('spread_price')}
						required
						parameters={<FormattedCurrencyUnit t={t} currency={currency} unit={unit} />}
						decimalScale={decimalScale}
						allowNegative={allowNegativePrice}
						disabled={price.disabled}
						{...field}
					/>
				</Styled.SpreadPriceContainer>
			)}
		/>
	);
};

export const FormattedCurrencyUnit = ({ t, currency, unit }) => {
	return (
		<>
			<Styled.CurrencyUnitSeparator />
			<span>{`${tCurrency(t, currency)}/${t(`price_unit_short_${unit}`, {
				lng: 'en',
			})}`}</span>
		</>
	);
};

export const spreadPriceField = (path, t) => <SpreadPriceField path={path} t={t} />;
