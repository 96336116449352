/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'src/i18n';
import { ReactComponent as AddIcon } from 'src/assets/icons/add.svg';
import { ReactComponent as DoubleCheckIcon } from 'src/assets/icons/double_check.svg';
import { ReactComponent as CheckIcon } from 'src/assets/icons/check.svg';
import { ReactComponent as NominationIcon } from 'src/assets/icons/otc.svg';
import { ReactComponent as ShipIcon } from 'src/assets/icons/ship.svg';
import { ReactComponent as ExchangeIcon } from 'src/assets/icons/exchange.svg';
import { ReactComponent as ShipReplaceIcon } from 'src/assets/icons/ship-replace.svg';
import { ReactComponent as RejectedIcon } from 'src/assets/icons/rejected.svg';
import { ReactComponent as ContactIcon } from 'src/assets/icons/contact.svg';
import { ReactComponent as PlusIcon } from 'src/assets/icons/plus.svg';
import { ReactComponent as MinusIcon } from 'src/assets/icons/minus.svg';
import { useMutation, useQueryClient } from 'react-query';
import { markAllNotificationsAsRead, markNotificationsAsRead } from 'src/_api';
import {
	formatDateRange,
	getSpreadMonths,
	getSpreadMultiMonths,
	parseDateStringAsUTC,
	Dateformat,
} from 'src/_helpers/date';
import { useToast } from 'src/components/Toast';
import { ROUTES } from 'src/constants/routes';
import {
	IncoType,
	OrderType,
	tOrderType,
	PaperInstruments,
	tCalendarSpreadPayment,
	SpreadPriceFormat,
	tLegQuote,
} from 'src/constants/contract';
import { KycTrackRecordTabIds } from 'src/constants/trackRecord';
import { EFPNotificationText, EFPNotificationTitle, EFPNotificationType } from './model_efp';
import { useUnseenActivitiesContext } from 'src/containers/Dashboard/Footer/MyTabsContext';
import { calculatePayCashDirection } from 'src/_helpers/spread';
import { formatPriceWithoutCurrency } from 'src/_helpers/price';

export const NotificationType = {
	CounterReceived: 'counter_received',
	OrderAccepted: 'order_accepted',
	OrderConfirmed: 'order_confirmed',
	CounterConfirmed: 'counter_confirmed',
	OrderNomination: 'order_nomination',
	OrderEdited: 'order_edited',
	OrderTerminated: 'order_terminated',
	PendingSignature: 'pending_signature',
	DocumentUploadedSignatures: 'trade_signatures_document_uploaded',
	DocumentUploadedShippingAdvice: 'trade_shipping_advice_document_uploaded',
	DocumentUploadedVesselNomination: 'trade_vessel_nomination_document_uploaded',
	DocumentUploadedShippingDocuments: 'trade_shipping_documents_document_uploaded',
	DocumentUploadedInvoicing: 'trade_invoicing_document_uploaded',
	CounterpartySigned: 'counterparty_signed',
	ShippingAdviceCompleted: 'shipping_advice_completed',
	VesselNominationCompleted: 'vessel_nomination_completed',
	VesselNameUpdated: 'vessel_name_updated',
	LaycanNameUpdated: 'laycan_name_updated',
	ShippingAdviceUpdated: 'shipping_advice_updated',
	BLNominationUpdated: 'bl_nomination_updated',
	BLUpdated: 'bill_of_lading_updated',
	ShippingTenderCompleted: 'shipping_tender_completed',
	CompletionCompleted: 'completion_completed',
	CounterpartyCompletedShippingAdvice: 'counterparty_completed_shipping_advice',
	CounterpartyCompletedVesselNomination: 'counterparty_completed_vessel_nomination',
	CounterpartyCompletedCompletion: 'counterparty_completed_completion',
	KYCRequestSubmitted: 'kyc_request_submitted',
	KYCRequestAccepted: 'kyc_request_accepted',
	ContactInviteRequestReceived: 'contact_invitation_request',
	ContactInviteAccepted: 'contact_invitation_accepted',
	TrackRecordRequestReceived: 'track_record_request_received',
	TrackRecordResultReceived: 'track_record_result_received',
	TradeUserAdded: 'trade_user_added',
	TradeUserRemoved: 'trade_user_removed',
	TradeDocumentRequest: 'trade_documents_request',
	VesselInfoUpdated: 'vessel_info_updated',
	ShippingDocumentsCompleted: 'shipping_documents_completed',
	InvoicingCompleted: 'invoicing_completed',
	ETADischargePortUpdated: 'eta_discharge_port_updated',
	NewTeamInvitation: 'new_team_invitation',
	TeamInvitationApproved: 'team_invitation_approved',
	TeamDeleted: 'team_deleted',
	OTCOrderSeenByCounterparty: 'otc_order_seen_by_counterparty',
	...EFPNotificationType,
};

// TODO: Group notifications by type and combine based on needs; for now all are stacked in one object
export const PhysicalExecutionNotifications = [
	NotificationType.DocumentUploadedSignatures,
	NotificationType.DocumentUploadedShippingAdvice,
	NotificationType.DocumentUploadedVesselNomination,
	NotificationType.DocumentUploadedShippingDocuments,
	NotificationType.DocumentUploadedInvoicing,
	NotificationType.CounterpartySigned,
	NotificationType.SignaturesCompleted,
	NotificationType.ShippingAdviceCompleted,
	NotificationType.VesselNominationCompleted,
	NotificationType.VesselNameUpdated,
	NotificationType.LaycanNameUpdated,
	NotificationType.ShippingAdviceUpdated,
	NotificationType.BLNominationUpdated,
	NotificationType.BLUpdated,
	NotificationType.ShippingTenderCompleted,
	NotificationType.VesselInfoUpdated,
	NotificationType.ShippingDocumentsCompleted,
	NotificationType.InvoicingCompleted,
	NotificationType.CounterpartyCompletedShippingAdvice,
	NotificationType.CounterpartyCompletedVesselNomination,
	NotificationType.TradeDocumentRequest,
	...Object.values(EFPNotificationType),
];

export const orderDetailsPageNotificationTypeArray = [
	NotificationType.CounterReceived,
	NotificationType.OrderAccepted,
	NotificationType.OrderConfirmed,
	NotificationType.CounterConfirmed,
	NotificationType.OrderTerminated,
];

export const useNotification = item => {
	const { t } = useTranslation();
	const { addToast } = useToast();
	const { decreaseNegotiationsCount, decreaseTradesCount } = useUnseenActivitiesContext();
	const queryClient = useQueryClient();

	const buildNegotiationLink = (environment, orderId, firstCounterId) => {
		if (!firstCounterId) {
			return null;
		}

		return `/?orderEnvironment=${environment}&orderId=${orderId}&negotiationId=${firstCounterId}`;
	};

	return useMemo(() => {
		const defaultNotificationData = {
			textHighlighted: getNotificationCompanyName(item),
			text: getNotificationText(item, t),
		};

		const defaultTradeWithNotificationData = {
			translationKey: !!item?.company?.name
				? 'notification_trade_with_text'
				: 'notification_trade_with_text_no_company',
			company: getNotificationCompanyName(item),
			text_info: getNotificationText(item, t),
			price: getNotificationPrice(item, t),
			shipment: getShipmentText(item, t),
		};

		const defaultOrderTypeFromNotificationData = {
			translationKey: !!item?.company?.name
				? 'notification_order_type_from_text'
				: 'notification_order_type_from_text_hidden_company',
			company: getNotificationCompanyName(item),
			text_info: getNotificationText(item, t),
			order_type: tOrderType(t, item.order_type, item.instrument),
			price: getNotificationPrice(item, t),
			shipment: getShipmentText(item, t),
		};

		switch (item.type) {
			case NotificationType.CounterReceived:
				return {
					kind: 'info',
					Icon: AddIcon,
					title: t('notification_counter_received'),
					url: buildNegotiationLink(
						item.environment,
						item.order_id,
						item.first_counter_id
					),
					queries: ['order', 'order_with_counters'],
					onClickFallback: async () => {
						if (!item.read) {
							decreaseNegotiationsCount();
						}

						await queryClient.invalidateQueries(['my-negotiations']);
					},
					...defaultOrderTypeFromNotificationData,
				};

			case NotificationType.CounterConfirmed:
				return {
					kind: 'success',
					Icon: DoubleCheckIcon,
					title: t('notification_counter_confirmed'),
					url: buildNegotiationLink(
						item.environment,
						item.order_id,
						item.first_counter_id
					),
					queries: ['order', 'order_with_counters'],
					onClickFallback: async () => {
						if (!item.read) {
							decreaseTradesCount();
						}

						await queryClient.invalidateQueries(['my-trades']);
					},
					...defaultTradeWithNotificationData,
				};

			case NotificationType.OrderAccepted:
				return {
					kind: 'success',
					Icon: CheckIcon,
					title: t('notification_order_accepted'),
					url: buildNegotiationLink(
						item.environment,
						item.order_id,
						item.first_counter_id
					),
					translationKey: 'notification_order_accepted_text',
					order_type: tOrderType(t, item.order_type, item.instrument),
					queries: ['order'],
					text_info: getNotificationText(item, t),
					company: getNotificationCompanyName(item),
				};

			case NotificationType.OrderTerminated:
				return {
					kind: 'error',
					Icon: RejectedIcon,
					title: t('notification_order_no_longer_available'),
					url: `/?orderId=${item.order_id}&orderEnvironment=${item.environment}`,
					queries: ['order'],
					...defaultOrderTypeFromNotificationData,
				};

			case NotificationType.OrderConfirmed:
				return {
					kind: 'success',
					Icon: DoubleCheckIcon,
					title: t('notification_order_confirmed'),
					url: item.first_counter_id
						? buildNegotiationLink(
								item.environment,
								item.order_id,
								item.first_counter_id
						  )
						: `/?orderId=${item.order_id}&orderEnvironment=${item.environment}`,
					queries: ['order'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.OrderEdited:
				return {
					kind: 'info',
					Icon: NominationIcon,
					title: t('notification_order_edited'),
					url: `/?orderId=${item.order_id}&orderEnvironment=${item.environment}`,
					queries: ['order'],
					...defaultOrderTypeFromNotificationData,
				};

			case NotificationType.OrderNomination:
				return {
					kind: 'info',
					Icon: NominationIcon,
					title: t('notification_order_nomination'),
					url: `/?orderId=${item.order_id}&orderEnvironment=${item.environment}`,
					queries: ['order'],
					...defaultOrderTypeFromNotificationData,
				};

			case NotificationType.PendingSignature:
				const isRenduInco = item.inco_id === IncoType.RENDU;
				return {
					kind: 'info',
					Icon: ShipIcon,
					title: t('notification_pending_signature'),
					url: isRenduInco
						? undefined
						: buildTradeURL(item, ROUTES.physicalExecutionSignatures),
					onClickFallback: isRenduInco
						? () => {
								addToast({
									message: t('rendu_no_post_trade_available'),
									kind: 'warning',
								});
						  }
						: undefined,
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.DocumentUploadedSignatures:
				return {
					kind: 'info',
					Icon: ShipIcon,
					title: t('notification_document_uploaded_signatures'),
					url: buildTradeURL(item, ROUTES.physicalExecutionSignatures),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.DocumentUploadedShippingAdvice:
				return {
					kind: 'info',
					Icon: ShipIcon,
					title: t('notification_document_uploaded_shipping_advice'),
					url: buildTradeURL(item, ROUTES.physicalExecutionShippingAdvice),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.DocumentUploadedVesselNomination:
				return {
					kind: 'info',
					Icon: ShipIcon,
					title: t('notification_document_uploaded_vessel_nomination'),
					url: buildTradeURL(item, ROUTES.physicalExecutionVesselNomination),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.DocumentUploadedShippingDocuments:
				return {
					kind: 'info',
					Icon: ShipIcon,
					title: t('notification_document_uploaded_shipping_documents'),
					url: buildTradeURL(item, ROUTES.physicalExecutionShippingDocuments),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.DocumentUploadedInvoicing:
				return {
					kind: 'info',
					Icon: ShipIcon,
					title: t('notification_document_uploaded_invoicing'),
					url: buildTradeURL(item, ROUTES.physicalExecutionInvoicing),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.CounterpartySigned:
				return {
					kind: 'success',
					Icon: ShipIcon,
					title: t('notification_counterparty_signed'),
					url: buildTradeURL(item, ROUTES.physicalExecutionSignatures),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.InvoicingCompleted:
				return {
					kind: 'success',
					Icon: ShipIcon,
					title: t('notification_invoicing_completed'),
					url: buildTradeURL(item, ROUTES.physicalExecutionInvoicing),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.ShippingDocumentsCompleted:
				return {
					kind: 'success',
					Icon: ShipIcon,
					title: t('notification_shipping_documents_completed'),
					url: buildTradeURL(item, ROUTES.physicalExecutionShippingDocuments),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.ShippingAdviceCompleted:
				return {
					kind: 'info',
					Icon: ShipIcon,
					title: t('notification_vessel_info_provided'),
					url: buildTradeURL(item, ROUTES.physicalExecutionShippingAdvice),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.VesselNominationCompleted:
				return {
					kind: 'info',
					Icon: ShipIcon,
					title: t('notification_vessel_nominated'),
					url: buildTradeURL(item, ROUTES.physicalExecutionVesselNomination),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.VesselNameUpdated:
				return {
					kind: 'info',
					Icon: ShipReplaceIcon,
					title: t('vessel_substituted'),
					url: buildTradeURL(item, ROUTES.physicalExecutionVesselNomination),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.VesselInfoUpdated:
				return {
					kind: 'info',
					Icon: ShipReplaceIcon,
					title: t('notification_vessel_name_updated'),
					url: buildTradeURL(item, ROUTES.physicalExecutionVesselNomination),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.LaycanNameUpdated:
				return {
					kind: 'info',
					Icon: ShipReplaceIcon,
					title: t('notification_laycan_name_updated'),
					url: buildTradeURL(item, ROUTES.physicalExecutionVesselNomination),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.TradeDocumentRequest:
				return {
					kind: 'info',
					Icon: ShipIcon,
					title: t('notification_documents_request'),
					url: buildTradeURL(item, ROUTES.physicalExecutionInstructions),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.ShippingAdviceUpdated:
				const isFOBFAS = [IncoType.FOB, IncoType.FAS].includes(item.inco_id);

				return {
					kind: 'info',
					Icon: ShipReplaceIcon,
					title: isFOBFAS
						? t('notification_shipping_advice_updated')
						: t('notification_shipping_info_updated'),
					url: buildTradeURL(
						item,
						isFOBFAS
							? ROUTES.physicalExecutionVesselNomination
							: ROUTES.physicalExecutionShippingAdvice
					),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.BLNominationUpdated:
				return {
					kind: 'info',
					Icon: ShipReplaceIcon,
					title: t('notification_bl_nomination_updated'),
					url: buildTradeURL(item, ROUTES.physicalExecutionShippingTender),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.BLUpdated:
				return {
					kind: 'info',
					Icon: ShipIcon,
					title: t('notification_bl_info_provided'),
					url: buildTradeURL(item, ROUTES.physicalExecutionShippingTender),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.ShippingTenderCompleted:
				return {
					kind: 'success',
					Icon: ShipIcon,
					title: t('notification_shipping_tender_completed'),
					url: buildTradeURL(item, ROUTES.physicalExecutionShippingTender),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.CompletionCompleted:
				return {
					kind: 'success',
					Icon: ShipIcon,
					title: t('notification_settlement_completed'),
					url: buildTradeURL(item),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.CounterpartyCompletedShippingAdvice:
				return {
					kind: 'success',
					Icon: ShipIcon,
					title: t('notification_shipping_advice_provided'),
					url: buildTradeURL(item, ROUTES.physicalExecutionShippingAdvice),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.CounterpartyCompletedVesselNomination:
				return {
					kind: 'success',
					Icon: ShipIcon,
					title: t('notification_vessel_nomination_provided'),
					url: buildTradeURL(item, ROUTES.physicalExecutionVesselNomination),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.CounterpartyCompletedCompletion:
				return {
					kind: 'success',
					Icon: ShipIcon,
					title: t('notification_settlement_done_by_counterparty'),
					url: buildTradeURL(item),
					queries: ['trade'],
					...defaultTradeWithNotificationData,
				};

			case NotificationType.KYCRequestSubmitted:
				return {
					kind: 'info',
					Icon: NominationIcon,
					title: t('notification_new_kyc_request'),
					url: `${ROUTES.kyc}/${ROUTES.kycPendingRequest}`,
					queries: ['kyc-conversation', 'kyc-active-conversations'],
					translationKey: 'notification_message_KYCNewRequest',
					company: getNotificationCompanyName(item),
				};

			case NotificationType.KYCRequestAccepted:
				return {
					kind: 'success',
					Icon: NominationIcon,
					title: t('notification_kyc_request_accepted'),
					url: `/kyc/active-conversations/${item.kyc_conversation_id}`,
					queries: ['kyc-conversation', 'kyc-active-conversations'],
					translationKey: 'notification_message_KYCRequestAccepted',
					company: getNotificationCompanyName(item),
				};

			case NotificationType.ContactInviteRequestReceived:
				return {
					kind: 'info',
					Icon: ContactIcon,
					title: t('contacts'),
					url: `${ROUTES.userNetwork}/${ROUTES.userNetworkInvites}/${ROUTES.userNetworkInvitesReceived}`,
					translationKey: 'notification_message_ContactInviteRequestReceived',
					name: getNotificationInviterName(item),
					company: getNotificationCompanyName(item),
				};

			case NotificationType.ContactInviteAccepted:
				return {
					kind: 'success',
					Icon: ContactIcon,
					title: t('contacts'),
					url: `${ROUTES.userNetworkMyContacts}/${item.invitee_user_id}`,
					translationKey: 'notification_message_ContactInviteAccepted',
					name: getNotificationInviteeName(item),
					company: getNotificationCompanyName(item),
				};

			case NotificationType.TrackRecordRequestReceived:
				return {
					kind: 'info',
					Icon: AddIcon,
					title: t('track_record_request_received'),
					url: `${ROUTES.kyc}/${ROUTES.kycTrackRecord}`,
					state: {
						tabToOpen: KycTrackRecordTabIds.RECEIVED,
					},
					queries: ['kyc-conversation', 'kyc-active-conversations'],
					...defaultNotificationData,
				};

			case NotificationType.TrackRecordResultReceived:
				return {
					kind: 'info',
					Icon: AddIcon,
					title: t('track_record_result_received'),
					url: `${ROUTES.kyc}/${ROUTES.kycTrackRecord}`,
					state: {
						tabToOpen: KycTrackRecordTabIds.SENT,
					},
					queries: ['kyc-conversation', 'kyc-active-conversations'],
					...defaultNotificationData,
				};

			case NotificationType.TradeUserAdded:
				return {
					kind: 'info',
					Icon: PlusIcon,
					title: t('added_to_trade'),
					url: buildTradeURL(item),
					translationKey: 'notification_message_TradeUserAdded',
					queries: ['trade'],
					name: getNotificationInviterName(item),
					company: getNotificationCompanyName(item),
					text_info: getNotificationText(item, t),
				};

			case NotificationType.TradeUserRemoved:
				return {
					kind: 'error',
					Icon: MinusIcon,
					title: t('removed_from_trade'),
					url: buildTradeURL(item),
					translationKey: 'notification_message_TradeUserRemoved',
					queries: ['trade'],
					name: getNotificationInviterName(item),
					company: getNotificationCompanyName(item),
					text_info: getNotificationText(item, t),
				};

			case NotificationType.ETADischargePortUpdated:
				return {
					kind: 'info',
					Icon: ShipReplaceIcon,
					title: t('notification_eta_discharge_port_provided'),
					url: buildTradeURL(item, ROUTES.physicalExecutionShippingAdvice),
					queries: ['trade'],
					...defaultNotificationData,
				};

			case NotificationType.NewTeamInvitation:
				return {
					kind: 'info',
					Icon: NominationIcon,
					title: t('team_invitation'),
					url: `${ROUTES.userNetwork}/${ROUTES.userNetworkTeams}`,
					queries: ['my-teams', 'my-teams-count'],
					translationKey: 'notification_message_NewTeamInvitation',
					name: item.source_user.name,
				};

			case NotificationType.TeamInvitationApproved:
				return {
					kind: 'info',
					Icon: NominationIcon,
					title: t('team_invitation_accepted'),
					url: `${ROUTES.userNetwork}/${ROUTES.userNetworkTeams}`,
					queries: ['my-teams', 'my-teams-count'],
					translationKey: 'notification_message_TeamInvitationAccepted',
					name: item.source_user.name,
				};

			case NotificationType.TeamDeleted:
				return {
					kind: 'info',
					Icon: NominationIcon,
					title: t('team_deleted'),
					url: `${ROUTES.userNetwork}/${ROUTES.userNetworkTeams}`,
					queries: ['my-teams', 'my-teams-count'],
					translationKey: 'notification_message_TeamDelete',
					name: item.source_user.name,
					team: item.team.name,
				};

			case NotificationType.OTCOrderSeenByCounterparty: {
				const titleTranslationKey =
					item.order_type === OrderType.Buy ? 'bid_seen' : 'offer_seen';
				return {
					kind: 'info',
					Icon: NominationIcon,
					title: t(`notification_${titleTranslationKey}`, {
						counterparty: item.source_user.name,
					}),
					url: `/?orderId=${item.order_id}&orderEnvironment=${item.environment}`,
					queries: ['order'],
					translationKey: `notification_otc_order_${titleTranslationKey}_by`,
					company: getNotificationCompanyName(item),
					text_info: getNotificationText(item, t),
				};
			}

			case NotificationType.NewTradeFuturesContractDetails:
			case NotificationType.NewDirectTradeFuturesContractDetails:
			case NotificationType.DeletedTradeFuturesContractDetails:
			case NotificationType.DeletedDirectTradeFuturesContractDetails:
			case NotificationType.ApprovedTradeFuturesContractDetails:
			case NotificationType.PartialFilledTradeFuturesContractDetails:
			case NotificationType.ExecutedTradeFuturesContractDetails:
			case NotificationType.ExpiredTradeFuturesContractDetails:
				return {
					kind: 'info',
					Icon: ExchangeIcon,
					title: t(EFPNotificationTitle[item.type]),
					url: buildTradeURL(item, ROUTES.physicalExecutionInvoicing),
					queries: ['trade'],
					translationKey: EFPNotificationText[item.type],
					company: item.source_user_company?.name || '',
					order: getNotificationText(item, t),
				};

			default:
				console.log('missing implementation for notification type:', item.type);
				return {
					kind: 'todo',
				};
		}
	}, [t, item, addToast, decreaseTradesCount, decreaseNegotiationsCount, queryClient]);
};

export const colorsByKind = {
	info: { color: 'var(--text-color-default)', background: 'var(--primary-400)' },
	error: { color: 'var(--text-color-default)', background: 'var(--red-400)' },
	success: { color: 'var(--neutral-1000)', background: 'var(--green-400)' },
};

export const useMarkAsReadMutation = props => {
	const queryClient = useQueryClient();

	return useMutation(markNotificationsAsRead, {
		onSuccess: () => {
			return queryClient.invalidateQueries(['notifications']);
		},
		...props,
	});
};

export const useMarkAllAsReadMutation = props => {
	const queryClient = useQueryClient();
	const { addToast } = useToast();
	const { t } = useTranslation();

	return useMutation(markAllNotificationsAsRead, {
		onSuccess: async () => {
			await queryClient.invalidateQueries(['notifications']);
			addToast({
				message: t('all_notifications_marked_as_read'),
			});
		},
		onError: () => {
			addToast({
				message: t('marking_notifications_as_read_error'),
				kind: 'error',
			});
		},
		...props,
	});
};

export const buildTradeURL = (item, path) => {
	const tradeRoute = ROUTES.tradeDetails;
	const baseURL = tradeRoute.replace(':tradeId', item.trade_id);

	if (!path) {
		return baseURL;
	}

	return `${baseURL}/${path}`;
};

export const getNotificationText = (item, t) => {
	const productInfo = getNotificationProductInfo(item, t);

	return productInfo;
};

export const getShipmentText = (item, t) => {
	const hasDate = item.delivery_mode && item.delivery_date_from && item.delivery_date_to;

	if (!hasDate) {
		return '';
	}

	const isSpread = item.instrument === PaperInstruments.Spread;
	const isPayCashSpread =
		isSpread && item.spread_details.price_format === SpreadPriceFormat.PayCash;

	if (!isSpread) {
		return formatDateRange({
			startDate: parseDateStringAsUTC(item.delivery_date_from),
			endDate: parseDateStringAsUTC(item.delivery_date_to),
			format: item.delivery_mode,
		});
	}

	const [firstLegMonth, secondLegMonth] = isPayCashSpread
		? getSpreadMonths(item.delivery_date_from, item.spread_details.delivery_date_from)
		: getSpreadMultiMonths(
				{
					startDate: item.delivery_date_from,
					endDate: item.delivery_date_to,
					format: Dateformat.Months,
				},
				{
					startDate: item.spread_details.delivery_date_from,
					endDate: item.spread_details.delivery_date_to,
					format: Dateformat.Months,
				}
		  );

	const firstLegType = item.order_type;
	const firstLegQuote = tLegQuote(t, firstLegType);
	const secondLegQuote =
		firstLegType === OrderType.Buy ? tLegQuote(t, OrderType.Sell) : tLegQuote(t, OrderType.Buy);

	return `${firstLegQuote} ${firstLegMonth} × ${secondLegQuote} ${secondLegMonth}`;
};

export const getNotificationCompanyName = item => {
	return item.company?.name || '';
};

export const getNotificationInviterName = item => {
	return item.inviter ? `${item.inviter.first_name} ${item.inviter.last_name}` : '';
};

export const getNotificationInviteeName = item => {
	return item.invitee ? `${item.invitee.first_name} ${item.invitee.last_name}` : '';
};

export const getNotificationProductInfo = (item, t) => {
	const volume = new Intl.NumberFormat('en').format(item.volume);
	const notificationInfo = {
		volume: !!item.volume ? `${volume}${t('mt')}` : null,
		name: item.product?.name,
		inco: item.inco?.name,
		port: item.port?.name,
	};

	return Object.values(notificationInfo).join(' ');
};

const getNotificationPrice = (item, t) => {
	const isPriceUndefined = !item.price && item.price !== 0;

	if (isPriceUndefined || !item.currency) {
		return '';
	}

	const priceUnit = t(`price_unit_short_${item.price_unit}`, {
		lng: 'en',
	});

	const currencyAndUnit = `${item.currency}/${priceUnit}`;

	let formattedPrice;

	if (item.spread_details?.price_format === SpreadPriceFormat.PayCash) {
		const spreadDirection = calculatePayCashDirection(
			item.order_type === OrderType.Buy,
			item.price
		);

		formattedPrice = `${tCalendarSpreadPayment(
			t,
			spreadDirection
		)} ${formatPriceWithoutCurrency(Math.abs(item.price))}
			`;
	} else {
		const price = parseInt(item.price) === item.price ? item.price.toFixed(2) : item.price;

		formattedPrice = new Intl.NumberFormat(i18n.language, {
			roundingPriority: 'lessPrecision',
			minimumFractionDigits: 2,
			minimumSignificantDigits: 2,
		}).format(price);

		if (item.spread_details?.price_format === SpreadPriceFormat.Spread) {
			formattedPrice = price > 0 ? `+${formattedPrice}` : formattedPrice;
		}
	}

	return `${formattedPrice} ${currencyAndUnit} `;
};
