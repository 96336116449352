/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

import { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import { useWatchPaperFields } from '../../useWatchPaperFields';
import { useFormContext } from 'react-hook-form';
import { PaperPorts, tPaperInstrument, PaperInstruments } from 'src/constants/contract';
import { usePaperOrderContext } from '../../CreatePaperOrderDrawerContent';
import { toPath } from 'src/_helpers';
import { contractPricingPath } from '../../model';
import { getDefaultValuesForPreset } from '../../helpers/getDefaultValuesForPreset';
import { Dateformat } from 'src/_helpers/date';
import { useSettings } from 'src/components/Menus/UserProfileDropdown/useSettings';

export const useProductSectionOptions = () => {
	const { t } = useTranslation();
	const { setValue, unregister } = useFormContext();
	const { setSelectedPreset } = usePaperOrderContext();
	const [isDefaultPresetSet, setDefaultPresetSet] = useState(false);
	const { defaultPaperContractID, isLoading: isLoadingSettings } = useSettings();

	const paperPresets = useAllPaperPresets();

	const productsPresets = useMemo(
		() =>
			paperPresets.map(preset => {
				return {
					text: preset.name,
					value: preset._key,
				};
			}),
		[paperPresets]
	);

	const {
		presetIDValue,
		isEditing,
		contractPricingValues,
		availableInstruments,
		instrumentValue,
	} = useWatchPaperFields();

	const selectedProductPreset = useMemo(() => {
		return paperPresets.find(preset => preset._key === presetIDValue);
	}, [paperPresets, presetIDValue]);

	useEffect(() => {
		if (selectedProductPreset) {
			setValue('selectedProductPreset', selectedProductPreset);
			setValue('presetID', selectedProductPreset._key);
			setSelectedPreset(selectedProductPreset);
		}
	}, [selectedProductPreset, setValue, setSelectedPreset]);

	const terms = selectedProductPreset
		? {
				name: selectedProductPreset.terms.name,
				url: selectedProductPreset.terms.original_link,
		  }
		: null;

	/* Instrument */
	const instrumentOptions = useMemo(
		() =>
			Object.keys(availableInstruments).map(instrument => ({
				text: tPaperInstrument(t, instrument),
				key: instrument,
				value: instrument,
			})),
		[availableInstruments, t]
	);

	useEffect(() => {
		const availableInstrumentsKeys = Object.keys(availableInstruments);

		if (availableInstrumentsKeys.length === 0) {
			// presets not loaded yet
			return;
		}

		if (!instrumentValue && availableInstrumentsKeys.length === 1) {
			setValue('instrument', availableInstrumentsKeys[0]);
		}

		if (
			instrumentValue &&
			availableInstrumentsKeys.length > 0 &&
			!availableInstrumentsKeys.includes(instrumentValue)
		) {
			setValue('instrument', availableInstrumentsKeys[0]);
		}
	}, [availableInstruments, instrumentValue, setValue]);

	/**
	 * Reset form to default values for each preset
	 */
	const handleProductChange = useCallback(
		presetId => {
			const orderRows = contractPricingValues.length;

			const preset = paperPresets.find(item => item._key === presetId);
			if (!preset) {
				return;
			}

			const defaultValues = getDefaultValuesForPreset(preset);

			setValue('instrument', PaperInstruments.Outright);

			for (let index = 0; index < orderRows; index++) {
				setValue(toPath(contractPricingPath, index, 'volume'), defaultValues.volume);
				setValue(toPath(contractPricingPath, index, 'priceType'), defaultValues.priceType);

				if (defaultValues.futuresMonth === null) {
					unregister(toPath(contractPricingPath, index, 'futuresMonth'));
				}

				if (!!defaultValues.runsRequired) {
					setValue(toPath(contractPricingPath, index, 'runs'), defaultValues.runs);
				} else {
					unregister(toPath(contractPricingPath, index, 'runs'));
				}
				setValue('runsRequired', defaultValues.runsRequired);
			}
		},
		[contractPricingValues.length, paperPresets, setValue, unregister]
	);

	useEffect(() => {
		if (
			!isDefaultPresetSet &&
			paperPresets.length &&
			!isEditing &&
			!presetIDValue &&
			!isLoadingSettings
		) {
			setDefaultPresetSet(true);
			let presetToSet;

			if (defaultPaperContractID) {
				presetToSet = paperPresets.find(preset => preset._key === defaultPaperContractID);
			} else {
				presetToSet = paperPresets.find(
					preset => preset.loading_port?._key === PaperPorts.PARANAGUA
				);
			}

			if (presetToSet) {
				setValue('presetID', presetToSet._key);
				setValue('selectedProductPreset', presetToSet);
				setSelectedPreset(presetToSet);
				setTimeout(() => {
					handleProductChange(presetToSet._key);
				});
			}
		}
	}, [
		defaultPaperContractID,
		handleProductChange,
		isDefaultPresetSet,
		isEditing,
		isLoadingSettings,
		presetIDValue,
		paperPresets,
		setSelectedPreset,
		setValue,
	]);

	const handleInstrumentChange = instrument => {
		if (instrument === PaperInstruments.Spread) {
			setValue(toPath(contractPricingPath, 0, 'firstLegMonth'), {
				format: Dateformat.Months,
			});
			setValue(toPath(contractPricingPath, 0, 'secondLegMonth'), {
				format: Dateformat.Months,
			});
			setValue(toPath(contractPricingPath, 0, 'secondLegDelivery'), {
				format: Dateformat.Months,
			});
		}
	};

	return {
		productsPresets: {
			options: productsPresets,
			ready: paperPresets.length,
			onChange: handleProductChange,
		},
		instruments: {
			options: instrumentOptions,
			ready: !!presetIDValue && instrumentOptions.length > 1,
			onChange: handleInstrumentChange,
		},
		terms,
	};
};
