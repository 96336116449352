/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldContainer } from 'src/components/CreateOrderDrawer/components/FoldableSection/styled';
import { useCounterSpreadStrategy } from './useCounterSpreadStrategy';
import { Terms } from 'src/components/Terms/Terms';

export const CalendarSpreadForm = ({ path, lastCounter }) => {
	const { t } = useTranslation();
	const { spreadCounterFields } = useCounterSpreadStrategy({ path, lastCounter });

	return (
		<div>
			<FieldContainer>
				{spreadCounterFields}
				<Terms label={t('additional_terms')} terms={lastCounter.additional_terms?.[0]} />
			</FieldContainer>
		</div>
	);
};
