/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { ChartDefaultFilters } from 'src/constants/chart';
import { CurrentSettingsVersion, CurrentSettingsVersionKey } from 'src/constants/storageKeys';
import { toggle } from 'src/_helpers';
import { loadState, storageKey } from 'src/_helpers/searchState';
import {
	CHART_SELECT_PRODUCT,
	CHART_SELECT_GRADE,
	CHART_SELECT_INCO,
	CHART_SELECT_LOCATION,
	CHART_SELECT_SHIPMENT,
	CHART_SELECT_PRICE_TYPE,
	CHART_RESET_FILTERS,
	CHART_SELECT_CARGO_TYPE,
	CHART_SELECT_PAPER_PRESET,
} from '../actions';
import { Market, PriceType } from 'src/constants/contract';

export const initialPaperFilters = {
	paperPreset: null,
	priceType: null,
	shipment: null,
};

export const initialPhysicalFilters = {
	product: ChartDefaultFilters.Product,
	inco: ChartDefaultFilters.Inco,
	priceType: ChartDefaultFilters.PriceType,
	location: ChartDefaultFilters.Location,
	cargoType: ChartDefaultFilters.CargoType,
	grade: null,
	shipment: null,
};

export const initialChartFilters = {
	[Market.Paper]: { ...initialPaperFilters },
	[Market.Physical]: { ...initialPhysicalFilters },
};

const getInitialState = () => {
	const hasOutdatedFilters =
		window.localStorage.getItem(CurrentSettingsVersionKey) !== CurrentSettingsVersion;

	if (!hasOutdatedFilters && loadState(storageKey.chart)) {
		return loadState(storageKey.chart);
	}

	return initialChartFilters;
};

export const chartReducers = (state = getInitialState(), action) => {
	const { type, payload } = action;

	switch (type) {
		// Physical filters
		case CHART_SELECT_PRODUCT: {
			return {
				...state,
				[Market.Physical]: {
					...state[Market.Physical],
					product: state[Market.Physical].product?._key === payload._key ? null : payload,
					grade: null,
				},
			};
		}

		case CHART_SELECT_INCO:
			return {
				...state,
				[Market.Physical]: {
					...state[Market.Physical],
					inco: state[Market.Physical].inco?._key === payload._key ? null : payload,
				},
			};

		case CHART_SELECT_CARGO_TYPE:
			return {
				...state,
				[Market.Physical]: {
					...state[Market.Physical],
					cargoType: toggle(state[Market.Physical].cargoType, payload),
				},
			};

		case CHART_SELECT_LOCATION:
			return {
				...state,
				[Market.Physical]: {
					...state[Market.Physical],
					location: payload,
				},
			};

		case CHART_SELECT_GRADE:
			return {
				...state,
				[Market.Physical]: {
					...state[Market.Physical],
					grade: state[Market.Physical].grade?._key === payload._key ? null : payload,
				},
			};

		// Paper filters
		case CHART_SELECT_PAPER_PRESET:
			const getPriceTyperValue = () => {
				if (payload.price_type === PriceType.Flat) {
					return PriceType.Flat;
				}

				return payload.price_type || PriceType.Basis;
			};

			return {
				...state,
				[Market.Paper]: {
					...state[Market.Paper],
					paperPreset: payload,
					priceType: getPriceTyperValue(),
				},
			};

		// Common filters
		case CHART_SELECT_PRICE_TYPE:
			return {
				...state,
				[payload.market]: {
					...state[payload.market],
					priceType:
						state[payload.market]?.priceType === payload.priceType
							? null
							: payload.priceType,
				},
			};

		case CHART_SELECT_SHIPMENT:
			return {
				...state,
				[payload.market]: {
					...state[payload.market],
					shipment: payload.shipment,
				},
			};

		case CHART_RESET_FILTERS:
			return {
				...initialChartFilters,
				[Market.Paper]: {
					...state[Market.Paper],
					priceType: state[Market.Paper].paperPreset?.price_type || PriceType.Basis,
					shipment: initialPaperFilters.shipment,
				},
			};

		default:
			return state;
	}
};

export const isLocationSelected = location => {
	return location.countries.length + location.regions.length > 0;
};
