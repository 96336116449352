/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCountry } from 'src/_api/queries';
import { TradeInfoWrapper, Type, Info } from '../../../sections/common';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { isInactiveUser } from 'src/_helpers/isInactiveUser';
import { UserStatus } from 'src/components/UserStatus/UserStatus';
import { UserProfileLink } from 'src/components/UserProfileModal/UserProfileLink';
import { DownloadTradesButton } from 'src/components/DownloadTradesButton/DownloadTradesButton';
import { DashEmpty } from 'src/components/DashEmpty/DashEmpty';
import * as StyledTradeInfo from '../styledTradeInfoComponents';
import * as Styled from './styled';

export const CounterpartiesSection = ({
	sellerInfo,
	buyerInfo,
	sellerBrokerInfo,
	buyerBrokerInfo,
	tradeId,
	tradeStep,
	market,
}) => {
	const { t } = useTranslation();

	const { data: sellerCountry } = useCountry({ _key: sellerInfo?.country });
	const { data: buyerCountry } = useCountry({ _key: buyerInfo?.country });
	const { data: sellerBrokerCountry } = useCountry({
		_key: sellerBrokerInfo?.company?.country_code,
	});
	const { data: buyerBrokerCountry } = useCountry({
		_key: buyerBrokerInfo?.company?.country_code,
	});

	const areBrokersFromTheSameCompany =
		sellerBrokerInfo?.company?.name === buyerBrokerInfo?.company?.name;

	const getBrokerLabel = type => {
		let label = t('broker');

		const bothBrokersDefined = sellerBrokerInfo && buyerBrokerInfo;

		if (bothBrokersDefined && !areBrokersFromTheSameCompany) {
			label += ` (${type})`;
		}

		return label;
	};

	const sellerBrokerLabel = getBrokerLabel(t('seller'));
	const buyerBrokerLabel = getBrokerLabel(t('buyer'));

	return (
		<StyledTradeInfo.RelativeWrapper data-test="execution-counterparties">
			<Styled.HeaderContainer>
				<Styled.StyledInfoTitle>{t('counterparties')}</Styled.StyledInfoTitle>
				<DownloadTradesButton tradeId={tradeId} tradeStep={tradeStep} market={market} />
			</Styled.HeaderContainer>
			<TradeInfoWrapper>
				<StyledTradeInfo.InfoItemColumn data-test="seller-company">
					<Type>{t('seller')}</Type>
					<Info>
						<OverflowText>
							<DashEmpty value={sellerInfo?.company} />
						</OverflowText>
					</Info>
				</StyledTradeInfo.InfoItemColumn>
				<StyledTradeInfo.InfoItemColumn data-test="seller-country">
					<Type>{t('country')}</Type>
					<Info>
						<OverflowText>
							<DashEmpty value={sellerCountry?.name} />
						</OverflowText>
					</Info>
				</StyledTradeInfo.InfoItemColumn>
				<StyledTradeInfo.InfoItemColumn data-test="seller-name">
					<Type>{t('trader')}</Type>
					<StyledTradeInfo.TraderNameInfo>
						{sellerInfo?.trader_name ? (
							<UserProfileLink
								userId={sellerInfo?.id}
								isInactive={isInactiveUser(sellerInfo)}
							>
								{sellerInfo?.trader_name}
								<UserStatus status={sellerInfo?.status} />
							</UserProfileLink>
						) : (
							'---'
						)}
					</StyledTradeInfo.TraderNameInfo>
				</StyledTradeInfo.InfoItemColumn>
			</TradeInfoWrapper>
			<TradeInfoWrapper>
				<StyledTradeInfo.InfoItemColumn data-test="buyer-company">
					<Type>{t('buyer')}</Type>
					<Info>
						<OverflowText>
							<DashEmpty value={buyerInfo?.company} />
						</OverflowText>
					</Info>
				</StyledTradeInfo.InfoItemColumn>
				<StyledTradeInfo.InfoItemColumn data-test="buyer-country">
					<Type>{t('country')}</Type>
					<Info>
						<OverflowText>
							<DashEmpty value={buyerCountry?.name} />
						</OverflowText>
					</Info>
				</StyledTradeInfo.InfoItemColumn>
				<StyledTradeInfo.InfoItemColumn data-test="buyer-name">
					<Type>{t('trader')}</Type>
					<StyledTradeInfo.TraderNameInfo>
						{buyerInfo?.trader_name ? (
							<UserProfileLink userId={buyerInfo?.id}>
								{buyerInfo?.trader_name}
								<UserStatus status={buyerInfo?.status} />
							</UserProfileLink>
						) : (
							<DashEmpty value={buyerInfo?.trader_name} />
						)}
					</StyledTradeInfo.TraderNameInfo>
				</StyledTradeInfo.InfoItemColumn>
			</TradeInfoWrapper>
			{sellerBrokerInfo && (
				<TradeInfoWrapper>
					<StyledTradeInfo.InfoItemColumn data-test="seller-broker-company">
						<Type>{sellerBrokerLabel}</Type>
						<Info>
							<OverflowText>{sellerBrokerInfo?.company?.name}</OverflowText>
						</Info>
					</StyledTradeInfo.InfoItemColumn>
					<StyledTradeInfo.InfoItemColumn data-test="seller-broker-country">
						<Type>{t('country')}</Type>
						<Info>
							<OverflowText>{sellerBrokerCountry?.name}</OverflowText>
						</Info>
					</StyledTradeInfo.InfoItemColumn>
					<StyledTradeInfo.InfoItemColumn data-test="seller-broker-name">
						<Type>{t('trader')}</Type>
						<StyledTradeInfo.TraderNameInfo>
							<UserProfileLink
								userId={sellerBrokerInfo?._key}
								isInactive={isInactiveUser(sellerBrokerInfo)}
							>
								{sellerBrokerInfo?.name}
								<UserStatus status={sellerBrokerInfo?.status} />
							</UserProfileLink>
						</StyledTradeInfo.TraderNameInfo>
					</StyledTradeInfo.InfoItemColumn>
				</TradeInfoWrapper>
			)}
			{buyerBrokerInfo && !areBrokersFromTheSameCompany && (
				<TradeInfoWrapper>
					<StyledTradeInfo.InfoItemColumn data-test="buyer-broker-company">
						<Type>{buyerBrokerLabel}</Type>
						<Info>
							<OverflowText>{buyerBrokerInfo?.company?.name}</OverflowText>
						</Info>
					</StyledTradeInfo.InfoItemColumn>
					<StyledTradeInfo.InfoItemColumn data-test="buyer-broker-country">
						<Type>{t('country')}</Type>
						<Info>
							<OverflowText>{buyerBrokerCountry?.name}</OverflowText>
						</Info>
					</StyledTradeInfo.InfoItemColumn>
					<StyledTradeInfo.InfoItemColumn data-test="buyer-broker-name">
						<Type>{t('trader')}</Type>
						<StyledTradeInfo.TraderNameInfo>
							<UserProfileLink
								userId={buyerBrokerInfo?._key}
								isInactive={isInactiveUser(buyerBrokerInfo)}
							>
								{buyerBrokerInfo?.name}
								<UserStatus status={buyerBrokerInfo?.status} />
							</UserProfileLink>
						</StyledTradeInfo.TraderNameInfo>
					</StyledTradeInfo.InfoItemColumn>
				</TradeInfoWrapper>
			)}
		</StyledTradeInfo.RelativeWrapper>
	);
};
