/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { AdditionalInformationSection } from './Content/Paper/AdditionalInformationSection/AdditionalInformationSection';
import { SpecificationsSection } from './Content/SpecificationsSection/SpecificationsSection';
import { ContractAndPricingSection } from './Content/Paper/ContractAndPricingSection/ContractAndPricingSection';
import { PaperKeyDetailsSection } from './Content/KeyDetailsSection/PaperKeyDetailsSection';
import { PaperOrderCreatorShipmentSection } from './Content/OrderCreatorShipmentSection/PaperOrderCreatorShipmentSection';
import { CounterpartiesSection } from './Content/CounterpartiesSection/CounterpartiesSection';
import { PaperInstruments } from 'src/constants/contract';
import * as Styled from './styled';

export const ViewPaperOrderDrawerContent = ({
	order,
	isScrolling,
	handleScroll,
	changes,
	isLastCounter,
}) => {
	return (
		<>
			<Styled.TopContent $isScrolling={isScrolling}>
				<PaperKeyDetailsSection
					order={order}
					changes={changes}
					isLastCounter={isLastCounter}
				/>
			</Styled.TopContent>
			<Styled.ScrollableContent onScroll={handleScroll}>
				<PaperOrderCreatorShipmentSection order={order} changes={changes} />
				<Styled.DrawerContentDetailsWrapper>
					<ContractAndPricingSection order={order} changes={changes} />
					{!!order.recipients_list?.length && (
						<>
							<Styled.Divider />
							<CounterpartiesSection order={order} />
						</>
					)}
					{order.instrument !== PaperInstruments.Spread && (
						<>
							<Styled.Divider />
							<SpecificationsSection order={order} />
							{(!order.is_counter_preview || order.additional_terms?.[0]) && (
								<>
									<Styled.Divider />
									<AdditionalInformationSection order={order} changes={changes} />
								</>
							)}
						</>
					)}
				</Styled.DrawerContentDetailsWrapper>
			</Styled.ScrollableContent>
		</>
	);
};
