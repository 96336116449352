/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useTranslation } from 'react-i18next';
import { OrderType, Role } from 'src/constants/contract';
import { useCurrentUser } from 'src/_helpers/useCurrentUser';

export const useCalendarSpreadLegUsers = order => {
	const { t } = useTranslation();
	const currentUser = useCurrentUser();

	const hiddenLabel = t('hidden');
	const notProvidedLabel = t('not_provided');
	const emptyLabel = '---';
	const orderPrincipal =
		order.order_owner_principal.hidden && !order.trade_id
			? hiddenLabel
			: order.order_owner_principal.company?.name || notProvidedLabel;

	const isSellOrder = order.order_type === OrderType.Sell;

	if (order.is_order_preview || order.is_order) {
		const [seller, buyer] = isSellOrder
			? [orderPrincipal, emptyLabel]
			: [emptyLabel, orderPrincipal];

		return {
			users: [seller, buyer],
		};
	}

	if (order.is_counter_preview) {
		const isOrderOwner = order.user_id === order.order_user_id;

		let seller;
		let buyer;

		if (isSellOrder) {
			if (isOrderOwner) {
				seller = orderPrincipal;
				buyer = order.counter_order_user_company.name;
			} else {
				seller = currentUser.company.name;
				buyer = orderPrincipal;
			}
		} else if (isOrderOwner) {
			seller = order.counter_order_user_company.name;
			buyer = orderPrincipal;
		} else {
			seller = orderPrincipal;
			buyer = currentUser.company.name;
		}

		return {
			users: [seller, buyer],
		};
	}

	const counterPrincipal =
		order.counter_order_role === Role.Broker
			? order.counter_order_owner_principal.company?.name
			: order.counter_order_user_company.name;

	const [seller, buyer] =
		order.order_type === OrderType.Sell
			? [orderPrincipal, counterPrincipal]
			: [counterPrincipal, orderPrincipal];

	return {
		users: [seller, buyer],
	};
};
