/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import {
	PriceTooBigKey,
	PriceTooSmallKey,
	PriceNotInRange,
	formatPriceError,
} from 'src/constants/priceValidation';

const isIncorrectPaperPriceField = (fieldName, errors) => {
	return (
		errors.contractPricing &&
		Array.isArray(errors.contractPricing) &&
		errors.contractPricing.length === 1 &&
		errors.contractPricing[0][fieldName] &&
		Object.keys(errors.contractPricing[0]).length === 1 &&
		[PriceTooBigKey, PriceTooSmallKey, PriceNotInRange].includes(
			errors.contractPricing[0][fieldName].type
		)
	);
};

export const getFormErrorMessage = (errors, t) => {
	const hasOnlyOneError = Object.keys(errors).length === 1;

	const defaultErrorMessage = t('validation_errors_occurred');

	if (!hasOnlyOneError) {
		return defaultErrorMessage;
	}

	if (
		errors.price &&
		[PriceTooBigKey, PriceTooSmallKey, PriceNotInRange].includes(errors.price.type)
	) {
		return formatPriceError(errors.price, t);
	}

	if (!!errors.customSpecs) {
		return t('specs_validation_error');
	}

	if (isIncorrectPaperPriceField('price', errors)) {
		return formatPriceError(errors.contractPricing[0].price, t);
	}

	if (isIncorrectPaperPriceField('firstLegPrice', errors)) {
		return formatPriceError(errors.contractPricing[0].firstLegPrice, t);
	}

	return defaultErrorMessage;
};
