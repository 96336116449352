/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useMemo, useEffect, useCallback } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import {
	QuoteType,
	tQuoteValueType,
	Currency,
	PriceUnit,
	DrawerPriceType,
	tDrawerPriceType,
	Role,
	tNameVisibility,
	NameVisibility,
} from 'src/constants/contract';
import { useWatchPaperCounterFields } from '../../../helpers/paper/useWatchPaperCounterFields';
import { toUTCUnix } from 'src/_helpers/date';
import { genFutureContractMonths } from 'src/containers/Order/PhysicalOrder/Groups/Pricing/FuturesContractDate';
import { useAllPaperPresets } from 'src/components/SearchHeader/useAllPaperPresets';
import { toPath } from 'src/_helpers';
import { getNumberOfMonths } from 'src/_helpers/date';
import { useOnce } from 'src/_helpers/useOnce';
import { getDefaultValidity } from 'src/components/CreateOrderDrawer/paper/helpers/getDefaultValidity';
import { useSelector } from 'react-redux';
import { getUserId } from 'src/_store/selectors';
import { useQuery } from 'react-query';
import { getMyContacts } from 'src/_api';
import { sortOrder } from 'src/_helpers/sort';
import { useDefaultValidities } from 'src/components/CreateOrderDrawer/paper/helpers/useDefaultValidities';

export const useContractAndPricingCounterFields = ({ path, lastCounter }) => {
	const { t } = useTranslation();
	const userId = useSelector(getUserId);
	const {
		setValue,
		control: { _defaultValues },
	} = useFormContext();

	const {
		currencyUnitValue,
		orderTypeValue,
		orderTypeChangedValue,
		priceValue,
		volumeValue,
		totalVolumeValue,
		priceTypeValue,
		runsValue,
		deliveryValue,
		validityChangedValue,
		forceFirmValue,
		principalIdValue,
		principalVisibilityValue,
		presetIDValue,
	} = useWatchPaperCounterFields(path);

	const paperPresets = useAllPaperPresets();

	const selectedProductPreset = useMemo(() => {
		return paperPresets.find(preset => preset._key === _defaultValues.presetID);
	}, [paperPresets, _defaultValues]);

	useEffect(() => {
		if (selectedProductPreset) {
			setValue('selectedProductPreset', selectedProductPreset);
		}
	}, [selectedProductPreset, setValue]);

	/* Order type */
	const orderTypeOptions = useMemo(
		() =>
			Object.values(QuoteType).map(value => ({
				text: tQuoteValueType(t, value),
				key: value,
				value,
			})),
		[t]
	);

	useOnce(() => {
		if (forceFirmValue) {
			setTimeout(() => {
				setValue(toPath(path, 'orderType'), QuoteType.Firm);
			}, 0);
		} else if (orderTypeValue === QuoteType.Firm && !orderTypeChangedValue) {
			setTimeout(() => {
				setValue(toPath(path, 'orderType'), QuoteType.Indicative);
			}, 0);
		}
	});

	const presetValidities = useDefaultValidities(presetIDValue);

	/**
	 * When order type changes and the validity value wasn't changed, we set 5 minutes for Firm and 24 hours for Indicative
	 */
	useEffect(() => {
		if (validityChangedValue) {
			return;
		}

		setValue('validity', getDefaultValidity(orderTypeValue, presetValidities));
	}, [orderTypeValue, setValue, validityChangedValue, presetValidities]);

	/* Price type */
	const priceTypeOptions = useMemo(() => {
		let priceTypes = [];

		// price type not defined meaning it's a wild card: Flat + all basis defined in futures contracts
		if (!selectedProductPreset?.price_type) {
			const futuresContractsPriceTypes =
				selectedProductPreset?.product?.futures_contracts?.map(
					fc => fc.futures_contract_id
				) || [];

			priceTypes = [DrawerPriceType.Flat, ...futuresContractsPriceTypes];
		} else if (selectedProductPreset?.price_type === DrawerPriceType.Flat) {
			priceTypes = [DrawerPriceType.Flat];
		} else {
			priceTypes = [DrawerPriceType[selectedProductPreset.futures_contract]];
		}

		return priceTypes.map(value => ({
			text: tDrawerPriceType(t, value),
			key: value,
			value,
		}));
	}, [selectedProductPreset, t]);

	/* Currency & Unit */
	const [currency, unit] = useMemo(() => {
		return currencyUnitValue
			? currencyUnitValue.split('/')
			: [Currency.USD, PriceUnit.MetricTon];
	}, [currencyUnitValue]);

	/* Futures months */
	const selectedFuturesContractMonths = useMemo(() => {
		if (!selectedProductPreset) {
			return [];
		}

		return (
			selectedProductPreset?.product?.futures_contracts?.find(
				fc => fc.futures_contract_id === priceTypeValue
			)?.future_months || []
		);
	}, [priceTypeValue, selectedProductPreset]);

	const futuresMonthOptions = useMemo(() => {
		return genFutureContractMonths(selectedFuturesContractMonths).map(value => ({
			key: value.valueOf(),
			text: moment(value).format('MMM YYYY'),
			value: toUTCUnix(value),
		}));
	}, [selectedFuturesContractMonths]);

	const getTotalVolume = useCallback(() => {
		if (selectedProductPreset) {
			const monthsMultiplier = getNumberOfMonths(
				deliveryValue?.startDate,
				deliveryValue?.endDate
			);

			if (runsValue !== undefined) {
				return selectedProductPreset.base_quantity * +runsValue * monthsMultiplier;
			} else if (!selectedProductPreset.volume) {
				return monthsMultiplier * volumeValue;
			}

			return selectedProductPreset.volume;
		}

		return null;
	}, [
		deliveryValue?.endDate,
		deliveryValue?.startDate,
		runsValue,
		selectedProductPreset,
		volumeValue,
	]);

	useEffect(() => {
		setValue(toPath(path, 'totalVolume'), getTotalVolume());
	}, [path, setValue, getTotalVolume]);

	useEffect(() => {
		if (volumeValue === '') {
			setValue(toPath(path, 'volume'), null, { shouldValidate: true });
		} else if (runsValue !== undefined && totalVolumeValue) {
			setValue(toPath(path, 'volume'), totalVolumeValue, { shouldValidate: true });
		}
	}, [path, setValue, runsValue, volumeValue, totalVolumeValue]);

	useEffect(() => {
		if (selectedProductPreset) {
			if (selectedProductPreset.runs === true) {
				setValue('runsRequired', true);
			} else {
				setValue('runsRequired', false);
			}
		}
	}, [selectedProductPreset, setValue]);

	const isOwner = lastCounter.order_user_id === userId;

	/** BROKER */
	const { data: allContacts = [] } = useQuery(
		[
			'my-contacts',
			{
				_limit: 1000,
				is_my_region: true,
				_sort: 'name',
				_order: sortOrder.asc,
			},
		],
		({ queryKey: [, params] }) => getMyContacts(params),
		{
			enabled: isOwner,
		}
	);

	const isBrokerOrder = lastCounter.role === Role.Broker;

	const shouldShowPrincipal = isBrokerOrder && lastCounter.order_user_id === userId;

	const isPrincipalChangeBlocked =
		lastCounter.order_owner_principal.user && !lastCounter.order_owner_principal.hidden;

	const isPrincipalVisibilityChangeBlocked = !(
		lastCounter.order_owner_principal.user && lastCounter.order_owner_principal.hidden
	);

	const principalNameVisibilityOptions = Object.values(NameVisibility).map(environment => ({
		value: environment,
		text: tNameVisibility(t, environment),
	}));

	useEffect(() => {
		if (isBrokerOrder && principalIdValue && !lastCounter.order_owner_principal.user) {
			setValue(toPath(path, 'principalVisibility'), NameVisibility.Visible);
		}
	}, [isBrokerOrder, principalIdValue, path, setValue, lastCounter.order_owner_principal.user]);

	useEffect(() => {
		if (!principalIdValue) {
			setValue(toPath(path, 'principalVisibility'), NameVisibility.Hidden);
			setValue(toPath(path, 'principalObject'), null);
		}
	}, [path, principalIdValue, setValue]);

	const counterpartyId = lastCounter.user_id;

	const contactsOptions = useMemo(() => {
		return allContacts
			.filter(contact => contact.contact_user_id !== counterpartyId)
			.map(contact => ({
				text: `${contact.name} - ${contact.company_name}`,
				key: contact.contact_user_id,
				value: contact.contact_user_id,
			}));
	}, [allContacts, counterpartyId]);

	useEffect(() => {
		if (!isOwner) {
			setValue(toPath(path, 'principalObject'), lastCounter.order_owner_principal);
		} else if (principalIdValue) {
			const principalUserObjectToSet = allContacts.find(
				c => c.contact_user_id === principalIdValue
			);

			if (principalUserObjectToSet) {
				setValue(toPath(path, 'principalObject'), {
					company: {
						name: principalUserObjectToSet.company_name,
						avatar_color: principalUserObjectToSet.company_avatar_color,
					},
					user: {
						_key: principalUserObjectToSet.contact_user_id,
						name: principalUserObjectToSet.name,
						avatar_color: principalUserObjectToSet.avatar_color,
					},
				});
			}
		}
	}, [lastCounter, path, setValue, isOwner, principalIdValue, allContacts]);

	return {
		orderType: {
			options: orderTypeOptions,
			hasChanged: _defaultValues.contractPricing[0].orderType !== orderTypeValue,
		},
		price: {
			hasChanged: _defaultValues.contractPricing[0].price !== priceValue,
		},
		volume: {
			// eslint-disable-next-line eqeqeq
			hasChanged: (_defaultValues.contractPricing[0].volume || null) != (volumeValue || null),
		},
		priceType: {
			counterable: false,
			options: priceTypeOptions,
		},
		futuresMonth: {
			counterable: false,
			options: futuresMonthOptions,
		},
		shipment: {
			counterable: false,
		},
		runs: {
			// eslint-disable-next-line eqeqeq
			hasChanged: (_defaultValues.contractPricing[0].runs || null) != (runsValue || null),
		},
		unit,
		currency,
		selectedProductPresetValue: selectedProductPreset,
		principal: {
			options: contactsOptions,
			ready: !isPrincipalChangeBlocked,
			visible: shouldShowPrincipal,
			hasChanged:
				// eslint-disable-next-line eqeqeq
				_defaultValues.contractPricing[0].order_owner_principal_id != principalIdValue,
			hasValue:
				!!_defaultValues.contractPricing[0].order_owner_principal_id || !!principalIdValue,
		},
		principalVisibility: {
			options: principalNameVisibilityOptions,
			ready: !isPrincipalVisibilityChangeBlocked,
			hasChanged:
				_defaultValues.contractPricing[0].principalVisibility !== principalVisibilityValue,
		},
	};
};
