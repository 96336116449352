/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

// MyOrders start

export const myOrdersPrincipalColumnWidths = {
	product: { default: 80 + 22, ll: 90 + 22, xl: 100 + 22 },
	origin: { default: 57, ll: 77, xl: 96 },
	name_visibility: { default: 67, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 106 },
	shipment: { default: 97, xl: 146 },
	volume: { default: 57, xl: 66 },
	price: { default: 67, xl: 76 },
	currency: { default: 57, xl: 66 },
	futures: { default: 57, xl: 66 },
	order_type: { default: 47, xl: 56 },
	is_indicative: { default: 97, xl: 106 },
	validity: { default: 87, xl: 96 },
	updated_at: { default: 77, ll: 77 + 16, xl: 86 + 16 },
};

export const myOrdersBrokerColumnWidths = {
	product: { default: 80 + 22, ll: 90 + 22, xl: 100 + 22 },
	origin: { default: 57, ll: 77, xl: 96 },
	principal: { default: 67, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 106 },
	shipment: { default: 97, xl: 146 },
	volume: { default: 57, xl: 66 },
	price: { default: 67, xl: 76 },
	currency: { default: 57, xl: 66 },
	futures: { default: 57, xl: 66 },
	order_type: { default: 47, xl: 56 },
	is_indicative: { default: 97, xl: 106 },
	validity: { default: 87, xl: 96 },
	updated_at: { default: 77, ll: 77 + 16, xl: 86 + 16 },
};

export const myOrdersPrincipalExpandedColumnWidths = {
	product: { default: 70 + 40, xl: 100 + 40 },
	origin: { default: 60, ll: 70, xl: 100 },
	name_visibility: { default: 60, xl: 80 },
	inco: { default: 30, xl: 40 },
	port: { default: 60, ll: 100, xl: 80 },
	shipment: { default: 60, ll: 100, xl: 120 },
	runs: { default: 30 },
	volume: { default: 50 },
	price: { default: 70 },
	first_leg_price: { default: 60 },
	currency: { default: 50 },
	futures: { default: 40, xl: 50 },
	order_type: { default: 40 },
	is_indicative: { default: 90, ll: 100 },
	validity: { default: 70, xl: 80 },
	updated_at: { default: 70, xl: 80 },
};

export const myOrdersBrokerExpandedColumnWidths = {
	product: { default: 70 + 40, xl: 100 + 40 },
	origin: { default: 60, xl: 80 },
	principal: { default: 60, ll: 70, xl: 80 },
	name_visibility: { default: 60 },
	inco: { default: 30, xl: 40 },
	port: { default: 60, xl: 80 },
	shipment: { default: 50, ll: 70, xl: 100 },
	runs: { default: 30 },
	volume: { default: 50 },
	price: { default: 70 },
	first_leg_price: { default: 60 },
	currency: { default: 50 },
	futures: { default: 40, xl: 50 },
	order_type: { default: 40 },
	is_indicative: { default: 90, ll: 100 },
	validity: { default: 60, xl: 80 },
	updated_at: { default: 60, xl: 80 },
};

export const myOrdersColumnWidths = {
	regular: {
		principal: myOrdersPrincipalColumnWidths,
		broker: myOrdersBrokerColumnWidths,
	},
	expanded: {
		principal: myOrdersPrincipalExpandedColumnWidths,
		broker: myOrdersBrokerExpandedColumnWidths,
	},
};

// MyOrders end
// MyNegotiations start

export const myNegotiationsCommonColumnWidths = {
	product: { default: 80 + 22, ll: 90 + 22, xl: 100 + 22 },
	origin: { default: 57, ll: 77, xl: 96 },
	company: { default: 67, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 106 },
	shipment: { default: 97, xl: 146 },
	volume: { default: 57, xl: 66 },
	price: { default: 67, xl: 76 },
	currency: { default: 57, xl: 66 },
	futures: { default: 57, xl: 66 },
	order_type: { default: 47, xl: 56 },
	is_indicative: { default: 97, xl: 106 },
	validity: { default: 77, xl: 96 },
	updated_at: { default: 87, ll: 87, xl: 86 },
};

export const myNegotiationsPrincipalExpandedColumnWidths = {
	product: { default: 100 + 40 },
	origin: { default: 100 },
	company: { default: 100, ll: 120, xl: 140 },
	trader: { xl: 140 },
	inco: { default: 40 },
	port: { default: 60, ll: 70, xl: 100 },
	shipment: { default: 60, ll: 80, xl: 120 },
	runs: { default: 30 },
	volume: { default: 50 },
	price: { default: 70 },
	currency: { default: 50 },
	futures: { default: 40, ll: 50 },
	order_type: { default: 40 },
	is_indicative: { default: 90, ll: 100 },
	validity: { default: 70, xl: 80 },
	updated_at: { default: 70, xl: 80 },
};

export const myNegotiationsBrokerExpandedColumnWidths = {
	product: { default: 70 + 40, ll: 80 + 40, xl: 100 + 40 },
	origin: { default: 60, ll: 70, xl: 100 },
	principal: { default: 70, ll: 110, xl: 100 },
	company: { default: 70, ll: 110, xl: 100 },
	trader: { xl: 120 },
	inco: { default: 30, xl: 40 },
	port: { default: 60, ll: 70, xl: 100 },
	shipment: { default: 60, ll: 80, xl: 120 },
	runs: { default: 30 },
	volume: { default: 50 },
	price: { default: 70 },
	currency: { default: 50 },
	futures: { default: 40, ll: 50 },
	order_type: { default: 40 },
	is_indicative: { default: 90, ll: 100 },
	validity: { default: 70, xl: 80 },
	updated_at: { default: 70, xl: 80 },
};

export const myNegotiationsColumnWidths = {
	regular: {
		principal: myNegotiationsCommonColumnWidths,
		broker: myNegotiationsCommonColumnWidths,
	},
	expanded: {
		principal: myNegotiationsPrincipalExpandedColumnWidths,
		broker: myNegotiationsBrokerExpandedColumnWidths,
	},
};

// MyTrades start

export const myTradesColumnWidth = {
	product: { default: 84, ll: 99, xl: 96 },
	origin: { default: 67, xl: 96 },
	counterparty: { default: 87, xl: 96 },
	created_at: { default: 87, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 126 },
	shipment: { default: 97, xl: 106 },
	volume: { default: 67, xl: 76 },
	position: { default: 57, xl: 66 },
	price: { default: 67, xl: 76 },
	currency: { default: 57, ll: 67, xl: 76 },
	futures: { default: 67, ll: 87, xl: 126 },
	market_price: { default: 67, xl: 76 },
	profit_and_loss: { default: 77, ll: 67, xl: 76 },
};

export const myTradesPrincipalExpandedColumnWidths = {
	product: { default: 90 + 40, ll: 100 + 40, xl: 120 + 40 },
	origin: { default: 90, ll: 100, xl: 120 },
	counterparty: { default: 90, ll: 100, xl: 140 },
	trader: { xl: 140 },
	created_at: { default: 70, xl: 80 },
	inco: { default: 40 },
	port: { default: 70, ll: 80, xl: 120 },
	shipment: { default: 80, ll: 100, xl: 120 },
	runs: { default: 30 },
	volume: { default: 50, xl: 80 },
	position: { default: 60, xl: 70 },
	price: { default: 70 },
	currency: { default: 50 },
	futures: { default: 50 },
	market_price: { default: 70, ll: 80 },
	profit_and_loss: { default: 70, ll: 70 + 18, xl: 80 },
};

export const myTradesBrokerExpandedColumnWidths = {
	product: { default: 70 + 40, ll: 80 + 40, xl: 100 + 40 },
	origin: { default: 60, ll: 70, xl: 100 },
	principal: { default: 70, ll: 80, xl: 120 },
	counterparty: { default: 70, ll: 80, xl: 120 },
	trader: { xl: 140 },
	created_at: { default: 70, xl: 80 },
	inco: { default: 40 },
	port: { default: 70, ll: 80, xl: 90 },
	shipment: { default: 80, ll: 100, xl: 120 },
	runs: { default: 30 },
	volume: { default: 50, xl: 60 },
	position: { default: 60, xl: 70 },
	price: { default: 70 },
	currency: { default: 50 },
	futures: { default: 50 },
	market_price: { default: 70, ll: 80 },
	profit_and_loss: { default: 70, ll: 70, xl: 80 },
};

export const myTradesColumnWidths = {
	regular: {
		principal: myTradesColumnWidth,
		broker: myTradesColumnWidth,
	},
	expanded: {
		principal: myTradesPrincipalExpandedColumnWidths,
		broker: myTradesBrokerExpandedColumnWidths,
	},
};

// MyTrades end

export const physicalOrderbookColumnWidths = {
	product: { default: 80 + 22, ll: 90 + 22, xl: 100 + 22 },
	origin: { default: 57, ll: 77, xl: 96 },
	company: { default: 67, xl: 96 },
	inco: { default: 47, xl: 56 },
	port: { default: 87, xl: 106 },
	shipment: { default: 97, xl: 146 },
	volume: { default: 57, xl: 66 },
	price: { default: 78 },
	currency: { default: 65, xl: 66 },
	futures: { default: 57, xl: 66 },
	order_type: { default: 47, xl: 56 },
	is_indicative: { default: 98, xl: 106 },
	created_at: { default: 77, xl: 96 },
	validity: { default: 93, xl: 88 },
};

export const physicalOrderbookExpandedColumnWidths = {
	product: { default: 100, xl: 120 },
	origin: { default: 80, ll: 100 },
	trader: { xl: 120 },
	company: { default: 90, ll: 100, xl: 120 },
	inco: { default: 50, xl: 60 },
	port: { default: 100, ll: 120, xl: 160 },
	shipment: { default: 100, ll: 120, xl: 160 },
	volume: { default: 60 },
	price: { default: 60 },
	currency: { default: 60 },
	futures: { default: 50, ll: 60 },
	order_type: { default: 60 },
	is_indicative: { default: 100 },
	created_at: { default: 80, xl: 100 },
	validity: { default: 80, xl: 100 },
};

export const paperOrderbookColumnWidths = {
	paper_contract_name: { default: 95 + 22 },
	shipment_and_spread: { default: 110, ll: 104, xl: 116 },
	futures: { default: 56, xl: 62 },
	last_traded_price: { default: 60, xl: 70 },
	update_date: { default: 63, xl: 76 },
	company: { default: 60, xl: 96 },
	volume: { default: 50, ll: 60, xl: 76 },
	bid_offer_count_type: { default: 85, ll: 95, xl: 106 },
	bid_offer: { default: 85, xl: 96 },
};

export const paperOrderbookExpandedColumnWidths = {
	paper_contract_name: { default: 118 }, // doesn't match the design. Width increase requested by Łukasz S.
	shipment_and_spread: { default: 95 },
	futures: { default: 40, xl: 50 },
	last_traded_price: { default: 50 },
	update_date: { default: 55, xl: 70 },
	closing_date: { default: 60, xl: 70 },
	trader: { xl: 100 },
	company: { default: 60, ll: 70, xl: 90 },
	volume: { default: 50, ll: 60 },
	bid_offer_count_type: { default: 90, ll: 100 },
	bid_offer: { default: 70 },
};

export const dashboardFiltersDelay = 500;

export const forexColumnWidths = {
	currency_pair: { default: 85, ll: 90, xl: 160 },
	last_price: { default: 70, ll: 90, xl: 160 },
	change: { ll: 90, xl: 160 },
	change_percent: { default: 100, ll: 90, xl: 160 },
};

export const Orderbook = 'orderbook';

export const UpdatePaperOrderTimeThreshold = 15 * 60;
