/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EyeIcon } from 'src/assets/icons/eye.svg';
import * as Styled from './styled';

export const OrderViews = ({ order, children }) => {
	const { t } = useTranslation();

	const viewsByUsersKey = !order.views
		? 'views_by_zero_users'
		: order.views === 1
		? 'one_view'
		: order.views_unique === 1
		? 'views_by_user'
		: 'views_by_users';

	return (
		<Styled.ViewCount data-test="view-count">
			<EyeIcon />
			{t(viewsByUsersKey, {
				count: order.views || 0,
				users: order.views_unique || 0,
			})}
			{children}
		</Styled.ViewCount>
	);
};
