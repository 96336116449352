/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { defaultCurrencyUnit } from 'src/constants/contract';

export const getPresetCurrencyAndUnitOrDefault = (preset, t) => {
	const [defaultCurrency, defaultUnit] = defaultCurrencyUnit.split('/');

	let unit = defaultUnit;
	let currency = defaultCurrency;

	if (preset && preset.product?.futures_contracts?.[0]) {
		const futures = preset.product.futures_contracts[0];
		currency = futures.currency;
		unit = futures.unit;
	}

	const label = `${currency}/${t(`price_unit_short_${unit}`)}`;

	return {
		unit,
		currency,
		label,
	};
};
