/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/

export const setNestedProperty = (obj, path, value) => {
	// When obj is not an object
	if (Object(obj) !== obj) {
		return obj;
	}

	// If not yet an array, get the keys from the string-path
	if (!Array.isArray(path)) {
		path = path.toString().match(/[^.[\]]+/g) || [];
	}

	path.slice(0, -1).reduce(
		(
			a,
			c,
			i // Iterate all of them except the last one
		) =>
			Object(a[c]) === a[c] // Does the key exist and is its value an object?
				? // Yes: then follow that path
				  a[c]
				: // No: create the key. Is the next key a potential array-index?
				  (a[c] =
						Math.abs(path[i + 1]) >> 0 === +path[i + 1]
							? [] // Yes: assign a new array object
							: {}), // No: assign a new plain object
		obj
	)[path[path.length - 1]] = value; // Finally assign the value to the last key

	return obj; // Return the top-level object to allow chaining
};

export const objectsEqual = (o1, o2) =>
	typeof o1 === 'object' && Object.keys(o1).length > 0
		? Object.keys(o1).length === Object.keys(o2).length &&
		  Object.keys(o1).every(p => objectsEqual(o1[p], o2[p]))
		: o1 === o2;

export const mapPathToNestedObject = (path, value) => {
	const keys = path.split('.');

	return keys.reduceRight((acc, key) => ({ [key]: acc }), value);
};
