/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import React from 'react';
import { useTranslation } from 'react-i18next';
import { OverflowText } from 'src/components/Tooltip/OverflowText';
import { TradeInfoWrapper, InfoTitle, Type, Info } from '../../../sections/common';
import * as Styled from '../styledTradeInfoComponents';
import NumberFormat from 'react-number-format';

export const UrlValue = ({ url, description }) => (
	<Styled.UrlWrapper>
		<a target="_blank" rel="noreferrer" href={url}>
			{description} <Styled.ExternalLinkIcon />
		</a>
	</Styled.UrlWrapper>
);

export const ContractSection = ({ contractInfo }) => {
	const { t } = useTranslation();

	return (
		<>
			<InfoTitle>{t('contract')}</InfoTitle>
			<TradeInfoWrapper>
				{(contractInfo || []).map(
					({ label, value, description, isNumberFormat, unit }, index) => {
						return (
							value && (
								<Styled.InfoItemColumn key={index}>
									<Type>{t(label)}</Type>
									{value.url ? (
										<UrlValue url={value.url} description={description} />
									) : (
										<OverflowText>
											<Info
												data-test={`trade-details-contract-info-${label}`}
											>
												{isNumberFormat ? (
													<NumberFormat
														thousandSeparator
														displayType="text"
														value={value}
														suffix={' ' + unit}
													/>
												) : (
													value
												)}
											</Info>
										</OverflowText>
									)}
								</Styled.InfoItemColumn>
							)
						);
					}
				)}
			</TradeInfoWrapper>
		</>
	);
};
