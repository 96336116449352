/**
 * Copyright (C) 2021, Vosbor Exchange BV
 * All rights reserved.
 **/
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUser } from 'src/_store/selectors';
import { createOrders, updateOrder } from 'src/_api/orders.api';
import {
	createPaperOrderSchema,
	mapDataToApi,
	defaultValues,
	mapPreviewDataToOrderView,
} from './model';
import { DrawerContextKeys, useDrawerContext } from '../../Drawer/DrawerContext';
import { OrderType, PaperInstruments } from 'src/constants/contract';
import { HeaderType } from 'src/components/Drawer/constants';
import { getFormErrorMessage } from 'src/_helpers/getFormErrorMessage';
import { usePaperProgressBar } from './usePaperProgressBar';
import { getRealIsDirty } from './getRealIsDirty';

export const useCreatePaperOrderForm = (formValues, selectedPreset) => {
	const { t } = useTranslation();
	const user = useSelector(getUser);
	const queryClient = useQueryClient();
	const navigate = useNavigate();

	const {
		addMyOrderId,
		[DrawerContextKeys.createDrawer]: {
			setOrderPreviewData,
			visibility: [isOpen, { close }],
			setTemporaryHeader,
			setIsFormDirty,
		},
	} = useDrawerContext();

	const baseResolver = yupResolver(createPaperOrderSchema, { abortEarly: false });

	const spreadPriceFormat = selectedPreset?.instruments?.find(
		i => i.type === PaperInstruments.Spread
	)?.spread_price_format;

	const resolver = async (data, ctx, options) => {
		return baseResolver(
			data,
			{
				runsRequired: data.runsRequired,
				environment: data.environment,
				instrument: data.instrument,
				spreadPriceFormat,
			},
			options
		);
	};

	const formMethods = useForm({
		resolver,
		defaultValues: formValues || defaultValues,
	});
	const { formState, reset } = formMethods;

	const isDirty = getRealIsDirty(formState);

	useEffect(() => {
		if (isDirty && isOpen) {
			setIsFormDirty(isDirty);
		} else {
			setIsFormDirty(false);
		}
	}, [setIsFormDirty, isDirty, isOpen]);

	const invalidateOrders = ids => {
		reset({}, { keepValues: true });
		ids.forEach(id => {
			addMyOrderId(id);
			queryClient.invalidateQueries(['order', id]);
		});

		queryClient.invalidateQueries(['my-orders']);
	};

	const { mutate: updateOrderMutation, isLoading: isSavingOrder } = useMutation(updateOrder, {
		onSuccess: ({ _key }, { environment }) => {
			if (_key) {
				setTemporaryHeader({
					type: HeaderType.Info,
					message: t('changes_saved'),
				});
				close();
				invalidateOrders([_key]);
				navigate(`/?orderEnvironment=${environment}&orderId=${_key}`);
			} else {
				setTemporaryHeader({
					type: HeaderType.Warning,
					message: t('order_creation_failed'),
				});
			}
		},
		onError: () => {
			setTemporaryHeader({
				type: HeaderType.Warning,
				message: t('order_creation_failed'),
			});
		},
	});

	const { mutate: createOrderMutation, isLoading: isCreatingOrder } = useMutation(createOrders, {
		onSuccess: async (orders, data) => {
			if (orders.error) {
				setTemporaryHeader({
					type: HeaderType.Warning,
					message: t('order_creation_failed'),
				});
				return;
			}

			if (Array.isArray(orders)) {
				const { contractPricing } = formMethods.getValues();

				if (orders.length === 1) {
					const messageLabel =
						data.orders[0].instrument === PaperInstruments.Spread
							? 'spread_created'
							: contractPricing[0].type === OrderType.Buy
							? 'bid_created'
							: 'offer_created';

					setTemporaryHeader({
						type: HeaderType.Info,
						message: t(messageLabel, { count: orders.length }),
					});
				} else {
					setTemporaryHeader({
						type: HeaderType.Info,
						message: t('n_orders_created', {
							count: orders.length,
						}),
					});
				}

				navigate(
					`/?orderEnvironment=${data.orders[0].environment}&orderId=${orders[0]._key}`
				);
			}

			close();
			const orderKeys = orders.map(order => order._key);
			invalidateOrders(orderKeys);
		},
		onError: () => {
			setTemporaryHeader({ type: HeaderType.Warning, message: t('order_creation_failed') });
		},
	});

	const onSubmit = data => {
		const body = mapDataToApi(data);

		if (data.isEditing) {
			updateOrderMutation({ id: data._key, environment: data.environment, ...body[0] });
		} else {
			setOrderPreviewData(mapPreviewDataToOrderView(body, data, user));
		}
	};

	const progress = usePaperProgressBar(formMethods);

	const onSubmitError = errors => {
		const errorMessage = getFormErrorMessage(errors, t);
		setTemporaryHeader({ type: HeaderType.Warning, message: errorMessage });
	};

	return {
		formMethods,
		isSubmitting: isSavingOrder || isCreatingOrder,
		progress,
		onSubmit: formMethods.handleSubmit(onSubmit, onSubmitError),
		mutate: createOrderMutation,
	};
};
